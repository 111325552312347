import axios from "axios";
import React, { Component, Fragment } from "react";
import Load from "../components/Load";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import InputMask from 'react-input-mask';
import ReactCodeInput from 'react-code-input'
import Countdown from 'react-countdown';
import { Spinner } from "react-bootstrap";

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import parse from 'html-react-parser';
class GirisYap extends Component {

    constructor() {
        super();
        this.state = {
            kademe: 1,
            telefon: "5",
            loading: false,
            telefonHata: false,
            kod: "",
            kodhata: false,
            stklar: [],
            stk: [],
            user: [],
            token: "",
            hata: false,
            baskanhata: false,

            tarih: Date.now(),
            dogrulamadurum: true,
            onayDurum: false,
            hatamesaj1:"",
            hatamesaj2:"",

            aydinlatma:false,
            metin:[],

            yedeplogin:false,
        }
    }

    componentDidMount() {

        
        let data = this.props.match.params;

        if(data?.id=="login_yedep") {
        this.setState({yedeplogin:true});
    }

        if (localStorage.getItem("user") && localStorage.getItem("stk")) {

            if(data?.id=="login_yedep") {
                var stk = JSON.parse(localStorage.getItem("stk"));

                
                window.open("https://yedep.sivildunyakocaeli.com?token="+stk?.yedepToken, '_blank');

                this.props.history.push("/");

            }else {
            this.props.history.push("/")

            }
        }


         axios.get("https://api.sivildunyakocaeli.com/mobil/sozlesmeler/getir/aydinlatma", {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {

                this.setState({
                    metin: response.data.data,
                })

            });


        //   this.kodkontrol();
    }

    girisyap = async () => {

        if (this.state.telefon != "") {

            if(this.state.onayDurum==true) {
            this.setState({
                loading: true,
                telefonHata: false,
            })

            const formData = new FormData();
            formData.append("telefon", this.state.telefon);

            await axios.post("https://api.sivildunyakocaeli.com/mobil/Auth/login/", formData, {
                headers: {
                    "Accept": "*/*",
                    "x-requested-with": "XMLHttpRequest",
                },
            })
                .then((response) => {

                    if (response.data.success == true) {
                        this.setState({
                            telefonHata: false,
                            loading: false,
                            dogrulamadurum: true,
                            kademe: 2,
                            tarih: Date.now(),
                        })
                    } else {
                        this.setState({

                            telefonHata: false,
                            loading: false,
                            hata: true
                        })
                    }

               //      console.log("response", response.data);

                });

            }else {
                this.setState({
                    kodhata:true,
            hatamesaj1:"Aydınlatma metnini onaylayınız",
            hatamesaj2:"Aydınlatma metnini okuyup onaylamanız gerekmektedir.",
                })
            }
        } else {

            this.setState({
                telefonHata: true,
            })
        }

    }

    giris = async () => {

        if (this.state.stk != []) {

            localStorage.setItem("user", JSON.stringify(this.state.user));
            localStorage.setItem("access_token", JSON.stringify(this.state.token));
            localStorage.setItem("stklar", JSON.stringify(this.state.stklar));
            localStorage.setItem("stk", JSON.stringify(this.state.stk));
            if(this.state.yedeplogin==true) {
                window.open("https://yedep.sivildunyakocaeli.com?token="+this.state.stk?.yedepToken, '_blank');
                
            window.location.href = "/";

            }else {
            window.location.href = "/";
            }
        }

    }

    aydinlatmametni = async () => {

        this.setState({
            aydinlatma:true,
        })

    }


    kodkontrol = async () => {

        this.setState({
            loading: true,
            kodhata: false,
        })

        const formData = new FormData();
        formData.append("telefon", this.state.telefon);
        formData.append("smsCode", this.state.kod);


        await axios.post("https://api.sivildunyakocaeli.com/mobil/Auth/sms_kodu_kontrol/", formData, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",
            },
        })
            .then((response) => {


                if (response.data.success == true) {

                    this.setState({
                        kodhata: false,
                        loading: false,
                    })

                    if (response.data.stk?.length > 0) {
                        if (response.data.stk?.length == 1) {
                            localStorage.setItem("user", JSON.stringify(response.data.user));
                            localStorage.setItem("access_token", JSON.stringify(response.data.access_token));
                            localStorage.setItem("stklar", JSON.stringify(response.data.stk));
                            localStorage.setItem("stk", JSON.stringify(response.data.stk[0]));
                            window.location.href = "/";
                        } else {
                  //           console.log("3", response.data);
                            this.setState({
                                kademe: 3,
                                stklar: response.data.stk,
                                stk: response.data.stk[0],
                                user: response.data.user,
                                token: response.data.access_token,
                            })
                        }
                    }
                    else {
                        this.setState({
                            baskanhata: true,
                            kademe: 1,
                            telefon: "",

                        })


                    }

                } else {

                    this.setState({
                        kodhata: true,
                        loading: false,
                        hatamesaj1:"Girdiğiniz doğrulama kodu hatalı",
                        hatamesaj2:"Lütfen telefonunuza gönderilen doğrulama kodunu giriniz.",
                    })

                }


            });





    }

    render() {

        const renderer = ({ hours, minutes, seconds, completed }) => {
            if (completed) {

                this.setState({ dogrulamadurum: false });
                // Render a completed state
                return (
                    <div style={{ marginTop: 15 }} >Doğrulama süreniz geçmiştir kodu tekrar göndermek için <b> tıklayınız. </b> </div>
                );
            } else {
                // Render a countdown
                return <div style={{ marginTop: 15 }} >Doğrulama için kalan süre : {minutes < 10 ? " 0" + minutes + "" : minutes + ""}:{seconds < 10 ? "0" + seconds : "" + seconds}</div>;
            }
        };

        return (
            <Fragment>
                <div className="main-wrap">


                <Dialog open={this.state.aydinlatma} onClose={() => this.setState({ aydinlatma: false })}
                        aria-labelledby="form-dialog-title" fullWidth
                        maxWidth="sm" >



                        <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }} style={{  }} >

                           
                            <Typography variant="h5" color="inherit">
                                {this.state.metin?.title}
                                <hr />
                            </Typography>

                            {parse(''+this.state.metin?.description)}
                          



                        </DialogContent>

                    </Dialog>


                    <Dialog open={this.state.kodhata} onClose={() => this.setState({ kodhata: false })}
                        aria-labelledby="form-dialog-title" fullWidth
                        maxWidth="xs" >



                        <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }} style={{ textAlign: "center" }} >

                            <img src='/assets/images/uyari.svg' style={{ height: 70, marginBottom: 15, marginTop: 5 }} />
                            <Typography variant="h5" color="inherit">
                                {this.state.hatamesaj1}
                                <hr />
                            </Typography>

                            <p style={{ fontSize: 13 }} >
                                {this.state.hatamesaj2}
                            </p>



                        </DialogContent>

                    </Dialog>


                    <Dialog open={this.state.baskanhata} onClose={() => this.setState({ baskanhata: false })}
                        aria-labelledby="form-dialog-title" fullWidth
                        maxWidth="xs" >


                        <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }} style={{ textAlign: "center" }} >


                            <img src='/assets/images/uyari.svg' style={{ height: 70, marginBottom: 15, marginTop: 5 }} />
                            <Typography variant="h5" color="inherit">
                                Başkan bir STK'ya kayıtlı değil.
                                <hr />
                            </Typography>

                            <p style={{ fontSize: 13 }} >
                                Lütfen <a href="tel:02623237114"><b>0262 323 71 14</b></a> nolu telefondan irtibata geçiniz.
                            </p>


                        </DialogContent>

                    </Dialog>


                    <Dialog open={this.state.hata} onClose={() => this.setState({ hata: false })}
                        aria-labelledby="form-dialog-title" fullWidth
                        maxWidth="xs" >



                        <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }} style={{ textAlign: "center" }} >

                            <img src='/assets/images/uyari.svg' style={{ height: 70, marginBottom: 15, marginTop: 5 }} />
                            <Typography variant="h5" color="inherit">
                                Girdiğiniz telefon numarasına ait bir STK başkanı sistemimizde bulunmamaktadır.
                                <hr />
                            </Typography>

                            <p style={{ fontSize: 14 }} >
                                Lütfen <a href="tel:02623237114"><b>0262 323 71 14</b></a> nolu telefondan irtibata geçiniz.
                            </p>

                        </DialogContent>

                    </Dialog>




                    <div className="row">
                        <div className="col-xl-6 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                            style={{ backgroundImage: `url("https://api.sivildunyakocaeli.com/uploads/girisBanner.jpg")` }}></div>
                        <div className="col-xl-6 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                            <div className="card shadow-none border-0 ms-auto me-auto login-card">

                                <div style={{ marginTop: 10, marginBottom: 45, marginLeft: 20 }} >
                                    <a href="/">
                                        <img src="/assets/images/stklogo.svg" style={{ height: 50 }} />
                                    </a>
                                </div>


                                {this.state.kademe == 1 &&
                                    <div className="card-body rounded-0 text-left">
                                        <h2 className="fw-700  display2-md-size mb-3" style={{ fontSize: 22 }} >Lütfen STK Olarak Giriş Yapınız</h2>
                                        <div style={{ marginBottom: 20, lineHeight: 1.5, marginTop: 20 }} >
                                            STK olarak giriş yapmak için lütfen <br /> başkan telefonunu yazınız.
                                        </div>
                                        <div className="form-group icon-input">

                                            <span>
                                                <img src='/assets/images/tr.png' style={{ height: 20, position: "absolute", bottom: 20, left: 10 }} />
                                            </span>


                                            <InputMask
                                                mask="0(999) 999 99 99"
                                                value={this.state.telefon}
                                                onChange={(e) => this.setState({ telefon: e.target.value })}

                                                maskChar=" "
                                            >
                                                {() => <input type="text"
                                                    style={{ borderColor: this.state.telefonHata == true ? "red" : "#ddd" }}


                                                    className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                    placeholder="Telefon Numarası" />
                                                }
                                            </InputMask>



                                        </div>

                                        <FormControlLabel
                                            control={
                                                <Checkbox color="blue" type="checkbox" checked={this.state.onayDurum} onChange={(e, value) => this.setState({ onayDurum: value })
                                                } />
                                            }
                                            label={
                                                <div style={{ fontSize: 13, marginBottom: 15, marginTop: 15, 
                                                color:"#111", fontWeight:100 }} >
                                                    İşleme devam ederek <b onClick={() => {
                                                        this.aydinlatmametni()
                                                    }}
                                                    >Aydınlatma Metni</b>’ni kabul etmiş sayılırsınız.
                                                </div>
                                            }
                                        />

                                        {(this.state.loading == false) ?
                                            <div className="col-sm-12 p-0 text-left">
                                                <div className="form-group mb-1"><button
                                                    style={{
                                                        backgroundColor: "#005F9B", height: 40, padding: 0, lineHeight: 0, marginTop: 20,
                                                    }}
                                                    onClick={() => { this.girisyap() }}
                                                    className="form-control text-center style2-input text-white 
                                         fw-600 border-0  ">Giriş Yap</button></div>
                                                <h6 className="text-grey-500 font-xsss fw-500  mb-0 lh-32"
                                                    style={{ textAlign: "center", marginTop: 50, color: "#777777" }}
                                                >STK başvurusu yapmak için<a href="/basvuru" style={{ textDecorationLine: "underline", color: "#777", marginLeft: 5 }}  >tıklayınız.</a></h6>
                                            </div> :
                                            <div style={{ width: "100%", textAlign: "center" }} >
                                                <Spinner animation="border" variant="primary" />
                                            </div>
                                        }

                                    </div>}


                                {this.state.kademe == 2 &&
                                    <div className="card-body rounded-0 text-left">
                                        <h2 className="fw-700  display2-md-size mb-3" style={{ fontSize: 22 }} >
                                            Doğrulama kodunu yazınız</h2>
                                        <div style={{ marginBottom: 20, lineHeight: 1.5, marginTop: 20 }} >
                                            Girdiğiniz telefon numarasına gelen doğrulama kodunu giriniz.
                                        </div>



                                        <div className="form-group icon-input mb-3">




                                            {this.state.dogrulamadurum == true ?
                                                <ReactCodeInput type='text' fields={4}
                                                    inputMode="numeric"
                                                    value={this.state.kod}
                                                    onChange={(value) => this.setState({ kod: value })}
                                                    style={{ width: "100%" }}
                                                    inputStyle={{
                                                        width: 45, marginRight: 10,  height: 45, fontSize: 20,
                                                        paddingLeft: 15,fontWeight:700
                                                    }}
                                                /> : null}


                                            {(this.state.dogrulamadurum == true) ?
                                                <Countdown
                                                    date={this.state.tarih + 119000}
                                                    renderer={renderer}
                                                /> :
                                                <div style={{ marginTop: 15 }} >Doğrulama süreniz geçmiştir kodu tekrar göndermek için
                                                    <b style={{ cursor: "pointer" }} onClick={() => {

                                                        this.girisyap();
                                                        this.setState({ dogrulamadurum: true, tarih: Date.now() })
                                                    }} > tıklayınız. </b> </div>
                                            }


                                        </div>


                                        {(this.state.dogrulamadurum == true) ?
                                        <>
                                        {this.state.loading==false ?
                                            <div className="col-sm-12 p-0 text-left">
                                                <div className="form-group mb-1"><button
                                                    style={{
                                                        backgroundColor: "#005F9B", height: 40, padding: 0, lineHeight: 0, marginTop: 20,
                                                    }}
                                                    onClick={() => { this.kodkontrol() }}
                                                    className="form-control text-center style2-input text-white 
                                         fw-600 border-0  ">Giriş Yap</button></div>
                                              
                                            </div> : null } </>:
                                            <div style={{ width: "100%", textAlign: "center" }} >
                                                <Spinner animation="border" variant="primary" />
                                            </div>
                                        }
                                        


                                    </div>}



                                {this.state.kademe == 3 &&
                                    <div className="card-body rounded-0 text-left" >
                                        
                                        <h2 className="fw-700  display2-md-size mb-3" style={{ fontSize: 22 }} >
                                            Giriş yapmak istediğiniz STK'yı seçiniz</h2>
                                        <div style={{ marginBottom: 20, lineHeight: 1.5, marginTop: 20 }} >
                                            Birden fazla STK'da başkan olduğunuz gözükmektedir.
                                        </div>
                                        
                                       
                                        <div className="form-group icon-input mb-3" style={{height:300,overflow:"auto"}} >

                                            {this.state.stklar.map((item) => {
                                                return (

                                                    <div onClick={() => this.setState({ stk: item })} className="card-body d-flex  overflow-hidden"
                                                        style={{
                                                            borderWidth: 1, borderColor: "#ddd", borderStyle: "solid", marginBottom: 20, cursor: "pointer"
                                                            , borderRadius: 10, backgroundColor: this.state.stk?.id == item.id ? "#f4f4f4" : "#fff"
                                                        }}
                                                    >
                                                        <div style={{}} className={`me-2 rounded-xxl `}>
                                                            <img style={{ height: 55, width: 55,borderRadius:400 }} src={"https://api.sivildunyakocaeli.com/" + item.profileImage} />
                                                        </div>
                                                        <h4 className="fw-700 text-grey-900 font-xssss mt-2">
                                                            {item.name}
                                                            <span className="d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500">
                                                                {item.faaliyetAlani}
                                                            </span> </h4>
                                                    </div>

                                                )
                                            })}



                                        </div>

                                        {(this.state.loading == false ) ?
                                            <div className="col-sm-12 p-0 text-left">
                                                <div className="form-group mb-1"><button
                                                    style={{
                                                        backgroundColor: "#005F9B", height: 40, padding: 0, lineHeight: 0, marginTop: 20,
                                                    }}
                                                    onClick={() => { this.giris() }}
                                                    className="form-control text-center style2-input text-white 
                                         fw-600 border-0  ">Giriş Yap</button></div>
                                              
                                            </div> :
                                            <div style={{ width: "100%", textAlign: "center" }} >
                                                <Spinner animation="border" variant="primary" />
                                            </div>
                                        }




                                    </div>}



                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }
}

export default GirisYap;