import React, { Component, Fragment } from "react";
import Header from '../components/Header';

import Appfooter from '../components/Appfooter';

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick

import axios from "axios";
import reactDom from "react-dom";


class StkTakvim extends Component {

    constructor() {
        super();
        this.state = {
            
            loading: false,
            
            start:"",
            end:"",
            tumetkinlikler:[],

            etkinlikler: [],
        }
    }

    componentDidMount() {

        this.etkinlikler();
        this.tumetkinlikler();


    }



      tumetkinlikler = async () => {

        this.setState({
            loading: true,
        })


      const formData = new FormData();
      let newDate = new Date()
      formData.append("baslangic", "2019-01-01" );
      formData.append("bitis", "2030-01-01");

        await axios.post("https://api.sivildunyakocaeli.com/mobil/etkinlikler", formData, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",
            },
        })
            .then((response) => {


        var etkinlikler = [];

        if(response.data?.data) {
                response.data.data?.forEach(item => {
                
                    var result = new Date(item.activityEndDate);
                    result.setDate(result.getDate() + 1);
             

                    etkinlikler.push(
                      {id:item.id,start:item.activityDate,end:result.getFullYear()+"-"+((result.getMonth()+1)<10 ? "0"+(result.getMonth()+1) : (result.getMonth()+1) )+"-"+result.getDate(),
                        backgroundColor:"#1478fb",
                      className:"full-event",title:item.title, }
            
                    )
            
                  });
                }

                this.setState({
                    tumetkinlikler:etkinlikler,
                    loading: false
                })

            });

    }



    etkinlikler = async () => {

        this.setState({
            loading: true,
        })


      const formData = new FormData();
      let newDate = new Date()
      formData.append("baslangic", newDate.getFullYear()+"-"+(newDate.getMonth()+1)+"-"+newDate.getDate() );
      formData.append("bitis", newDate.getFullYear()+"-"+(newDate.getMonth()+1)+"-"+newDate.getDate());

        await axios.post("https://api.sivildunyakocaeli.com/mobil/etkinlikler", formData, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",
            },
        })
            .then((response) => {

                this.setState({
                    etkinlikler: response.data.data,
                    loading: false
                })

            });

    }


    etkinliklerfiltrele = async () => {

        this.setState({
            loading: true,
        })


      const formData = new FormData();
      formData.append("baslangic", this.state.start);
      formData.append("bitis", this.state.end);

     
        await axios.post("https://api.sivildunyakocaeli.com/mobil/etkinlikler", formData, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",
            },
        })
            .then((response) => {

                this.setState({
                    etkinlikler: response.data.data,
                    loading: false
                })

            });

    }


    eventClick = (selectInfo) => {

       //  console.log(selectInfo.event.id);

        var data = this.state.tumetkinlikler?.filter((item) =>  item.id==selectInfo.event.id ? true : false  );

        
        if( data.length>0) {
        this.setState({
            start:data[0].start,
            end:data[0].end,
        },() => {
            this.etkinliklerfiltrele();
        })
    }

      

    }

    handleDateClick = (selectInfo) => { 
        
        var result = new Date(selectInfo.endStr);
  result.setDate(result.getDate() - 1);

        this.setState({
            start:selectInfo.startStr,
            end:result.getFullYear()+"-"+(result.getMonth()+1)+"-"+result.getDate(),
        },() => {
            this.etkinliklerfiltrele();
        })
    
   //      console.log("info",selectInfo);

    }




    render() {

        
const renkler = ["bg-warning","bg-danger","bg-success"]

        return (
            <Fragment>
                <Header  match={this.props?.match} />
                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0" style={{ maxWidth: 1200 }} >
                            
                <div>

<div className="row" style={{marginTop:-15}} >
    
<div className="col-md-8"
 style={{backgroundColor:"white",borderRadius:10,marginTop:15}}  >
     <div className="card shadow-xss  d-block d-flex border-0 p-4 mb-3 mobilzoom " style={{margin:15}} >
                <FullCalendar
        plugins={[ dayGridPlugin , interactionPlugin ]}
        initialView="dayGridMonth"


        events={this.state.tumetkinlikler}

    //    initialDate="2022-03-11"

        buttonText={
            {
              prevYear: 'Geçen Yıl',
              nextYear: 'Yeni Yıl',
              year: 'year',
              today: 'Bugün',
              month: 'Ay',
              week: 'Hafta',
              day: 'Gün',
              list: 'Ajanda',
            }
          }

          allDayText=""
          noEventsText="Gösterilecek Veri Bulunamadı"
          moreLinkText="Daha fazla"
          weekText="Hf"
          timeZone= 'local' 
          locale="tr"

          editable={false}
          selectable={true}
          unselectAuto={false}

          nowIndicator={true}
          firstDay={1}
          lazyFetching = {true}
          dragScroll={false}

       //   dateClick={this.handleDateClick}

        //eventClick={this.eventClick}

   longPressDelay="0"
          select={this.handleDateClick}
          


      /></div>
      </div>

      
<div className="col-md-4" style={{padding:15}} >

    

    <div className="card shadow-xss  d-block d-flex border-0 p-4 mb-3"
 style={{backgroundColor:"white",borderRadius:10}}  >

<h2 className="fw-700 mb-0 mt-0 font-xss text-grey-900 d-flex align-items-center" style={{ paddingTop: 10 }}  >
                                                    Etkinlikler
                                                </h2>
                         
<hr />

{(this.state.etkinlikler.length<=0) ?
<div style={{width:"100%",textAlign:"center",marginTop:20}} >
                <img src='/assets/images/etkinlikYok.svg' style={{height:70}} />

                <p style={{color:"#aaa",marginTop:10,fontWeight:500}} >Etkinlik Bulunamadı</p>

                </div> : null }


{this.state.etkinlikler && this.state.etkinlikler.map((item,index) => {
    return (

<a key={index} href={"/etkinlikdetay/"+item.url}>
                <div   className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
                    <div style={{width:65,height:65}} className={`bg-success me-2 p-3 rounded-xxl ${renkler[index%3]}`}>
                        <h4 className="fw-700  ls-3 lh-1 text-white mb-0" style={{fontSize:24,marginTop:-3}} >
                        <span className="ls-1 d-block font-xsss text-white fw-600">{item?.ayf}</span>{item?.gunf}</h4></div>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-2">{item.title}
                    <span className="d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500">
                    {item.description.length > 75 ?
    `${item.description.substring(0, 75)}...` : item.description
  }
  </span> </h4>
                </div>
                </a>
                
    )
} )}

       
       

 </div>

</div>

      
</div>
                </div>

                        </div>
                    </div>
                </div>


                <Appfooter />

            </Fragment>
        );
    }
}

export default StkTakvim;