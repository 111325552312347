import React, { Component, Fragment } from "react";
import Header from '../components/Header';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import { Button } from '@material-ui/core';

import Slider from "react-slick";

import 'react-image-lightbox/style.css';
import axios from "axios";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";



class HikayeDetay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            isOpen: false,
            id:0,
            hikaye:[],
            resimler:[],
            seciliresim:"",


            resimopen:false,

        };
    }

    componentDidMount() {
        let data = this.props.match.params;
        this.setState({id:data.hikayeid})
        this.hikayedetay(data.hikayeid);

    }

    hikayedetay = async (id) => {

        await axios.get("https://api.sivildunyakocaeli.com/mobil/hikayeler/detayurl/"+id, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
             //    console.log("hikaye",response.data.data);
                if(response.data.success==true) {
                this.setState({
                    hikaye: response.data.data,
                    resimler:response.data.data?.resimler,
                })
            }else {
               window.location.href="/";
            }

            }).catch((e) => {
             //    console.log("e",e);
            });

    }

    render() {
        const { tab1, isOpen } = this.state;
        const hotelsettings = {
            arrows: false,
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 2,
            centerMode: false,
            variableWidth: false,
            responsive: [{
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                }
            }]
        };
        return (
            <Fragment>
                <Header />

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-xl-12 col-xxl-12 col-lg-12">
                                    {(this.state.resimler.length>1) ?
                                    <Slider {...hotelsettings}>
                                        {this.state.resimler.map((value, index) => (
                                            <div  
                                            onClick={() => this.setState({ resimopen: true,seciliresim:index })}
                                             key={index} className="pe-2">
                                                <img  src={"https://api.sivildunyakocaeli.com/"+value.url} alt="avater"
                                                style={{cursor:"pointer",height:400,width:"100%",objectFit:"cover"}}  className="rounded-3 img-fluid" />
                                            </div>
                                        ))}
                                    </Slider> :
                                    <>
                                    {this.state.resimler.map((value, index) => (

                                       <div 
                                       onClick={() => this.setState({ resimopen: true,seciliresim:index })}
                                        className="pe-2" style={{textAlign:"center",width:"100%"}} >
                                       <img  src={"https://api.sivildunyakocaeli.com/"+value.url} style={{cursor:"pointer"
                                       ,maxHeight:500,objectFit:"cover"}}
                                        alt="avater" className="rounded-3 img-fluid mobilmax" />
                                   </div>
                                    ))}
    </> }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12 col-xxl-12 col-lg-12">

                                    <div className="card d-block mt-3 border-0 shadow-xss bg-white p-lg-5 p-4">
                                        
                                        
                                       
                                       <Button 
 className=" z-index-1 
                        rounded-3 " style={{backgroundColor:"#fff",fontSize:12,marginLeft:20,borderWidth:1,borderStyle:"solid"
                        ,borderColor:"#1478FB",color:"#1478FB"
                        ,fontWeight:600,height:40,lineHeight:0,display:"inline",justifyContent:"center",marginRight:0,marginLeft:0,
                        alignItems:"center",padding:13,paddingRight:25,paddingLeft:25
                        }} >
                          {this.state.hikaye?.time}
                          </Button>

                                        <br />
                                      
                                        <br />
                                        <h2 className="fw-700 font-lg mt-3 mb-2">
                                        {this.state.hikaye?.title}
                                        </h2>
                                       
                                       
                                        <p className="font-xsss fw-500 text-grey-500 lh-30 pe-5 mt-3 me-5">
                                        
                                        
                                        {
                                            this.state.hikaye?.description?.split('\n').map(function (item) {
                                                return (
                                                    <div>{item}</div>
                
                                                )
                                            })
                                        }
                                        
                                        </p>
                                     
                                     

                                        <div className="clearfix"></div>


                                    </div>








                                </div>


                            </div>
                        </div>
                    </div>
                </div>


                
{this.state.resimopen && (
<Lightbox

mainSrc={'https://api.sivildunyakocaeli.com/'+this.state?.resimler[this.state.seciliresim]?.url}
nextSrc={'https://api.sivildunyakocaeli.com/'+this.state?.resimler[(this.state.seciliresim + 1) % this.state?.resimler.length]?.url}
prevSrc={'https://api.sivildunyakocaeli.com/'+this.state?.resimler[(this.state.seciliresim + this.state?.resimler.length - 1) % this.state?.resimler.length]?.url}

onMovePrevRequest={() =>
  this.setState({
    seciliresim: (this.state.seciliresim + this.state?.resimler.length - 1) % this.state?.resimler.length,
  })
} 
onMoveNextRequest={() =>
  this.setState({
    seciliresim: (this.state.seciliresim + 1) % this.state?.resimler.length,
  })
} 

onCloseRequest={() => this.setState({ resimopen: false })}
                            
                                    />   )}


                <Popupchat />
                <Appfooter />

            </Fragment>
        );
    }
}

export default HikayeDetay;