import React, { Component, Fragment } from "react";

import Header from '../components/Header';

import Appfooter from '../components/Appfooter';


import Events from '../components/Events';
import Createpost from '../components/Createpost';

import Storyslider from '../components/Storyslider';
import Postview from '../components/Postview';
import Load from '../components/Load';
import axios from "axios";
import { Spinner } from "react-bootstrap";
import TanitimPopup from "../components/TanitimPopup";

class Anasayfa extends Component {

    constructor() {
        super();
        this.state = {
            page: 1,
            haberler: [],
            hikayeler: [],
            etkinlikler: [],
            loading: false,
            showPopup:false,

            stklar: [],
            stk: [],
            token: "",
            onayHaberler: [],
        }
    }

    componentDidMount() {

        


        if (localStorage.getItem("user") != null && localStorage.getItem("user") != "") {
            this.setState({
                stk: JSON.parse(localStorage.getItem("stk")),
                stklar: JSON.parse(localStorage.getItem("stklar")),
                token: JSON.parse(localStorage.getItem("access_token")),


            }, () => {

                this.onaybekleyenhaberlerim();
            })
        }

        this.haberler();
        this.hikayeler();
        this.etkinlikler();
        this.checkPopupVisibility();

    }


  
    checkPopupVisibility = () => {
        const lastPopupCloseTime = localStorage.getItem('lastPopupCloseTime');
        const currentTime = new Date().getTime();

        if (!lastPopupCloseTime || currentTime - parseInt(lastPopupCloseTime) > 900000) { // 15 dakika = 900000 milisaniye
            this.setState({ showPopup: true });
        }
    }

    closePopup = () => {
        this.setState({ showPopup: false });
        const currentTime = new Date().getTime();
        localStorage.setItem('lastPopupCloseTime', currentTime.toString());
    }

     getOS() {
        var uA = navigator.userAgent || navigator.vendor || window.opera;
        if ((/iPad|iPhone|iPod/.test(uA) && !window.MSStream) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';
      
        var i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
        for (i = 0; i < os.length; i++) if (new RegExp(os[i],'i').test(uA)) return os[i];
      }


    yenile() {

        this.onaybekleyenhaberlerim();

    }



    etkinlikler = async () => {

        await axios.get("https://api.sivildunyakocaeli.com/mobil/etkinlikler/yaklasan", {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
                this.setState({
                    etkinlikler: response.data.data,
                })

            });

    }

    haberdevam = async () => {

        this.setState({ loading: true })

        await axios.get("https://api.sivildunyakocaeli.com/mobil/haberler/" + this.state.page, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
          //       console.log(response.data.data);
                this.setState({
                    haberler: [...this.state.haberler, ...response.data.data],
                    loading: false
                })

            });

    }


    onaybekleyenhaberlerim = async () => {

        if (this.state.stk != [] && this.state.stk != "") {

            const formData = new FormData();
            formData.append("stkId", this.state.stk.id);
            formData.append("page", 1);

            await axios.post("https://api.sivildunyakocaeli.com/mobil/haberler/onaybekleyenler", formData, {
                headers: {
                    "Accept": "*/*",
                    "Authorization": "Bearer " + this.state.token,
                    "x-requested-with": "XMLHttpRequest",

                },
            })
                .then((response) => {
                    if (response.data.success == true) {
               //          console.log("bekliyor", response.data);
                        this.setState({
                            onayHaberler: response.data.data,
                            loading: false
                        })
                    }

                });

        }


    }

    haberler = async () => {

        this.setState({ loading: true })

        await axios.get("https://api.sivildunyakocaeli.com/mobil/haberler/" + this.state.page, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
         //        console.log(response.data.data);
                this.setState({
                    haberler: response.data.data,
                    loading: false
                })

            });

    }


    hikayeler = async () => {

        await axios.get("https://api.sivildunyakocaeli.com/mobil/hikayeler/" + this.state.page, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
         //        console.log("hikayeler", response.data.data);
                this.setState({
                    hikayeler: response.data.data,
                })

            });

    }

    render() {
        return (
            <Fragment>
                <Header match={this.props?.match} />


                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row feed-body">
                                <div className="col-xl-8 col-xxl-9 col-lg-8" style={{marginTop:10}} >
                                    <Storyslider hikayeler={this.state.hikayeler} />
                                    {(this.state.stk != null && this.state.stk != "") ?
                                        <Createpost history={this.props.history} yenile={() => this.yenile()} /> : null
                                    }


{ (this.state.onayHaberler?.length<=0 && this.state.haberler?.length<=0) ?

<div style={{ width: "100%", textAlign: "center",marginTop:20 }} >
                                                <img src='/assets/images/haberYok.svg' style={{ height: 120 }} />

                                                <p style={{ color: "#aaa", marginTop: 10, fontWeight: 500 , fontSize:22 }} >
                                                    Haber Bulunamadı</p>

                                            </div> : null }



                                    {this.state.onayHaberler.map((item) => {
                                        return (
                                            <Postview
                                            veriyenile={() => { this.onaybekleyenhaberlerim(); }}
                                                bekleyen={true}
                                                key={item.id}
                                                id={item.id}
                                                resimler={item.resimler}
                                                stk={item.stk}
                                                time={item.time}
                                                des={item.description}
                                                url={item.url}
                                            />
                                        )
                                    })}


                                    {this.state.haberler.map((item) => {
                                        return (
                                            <Postview

                                            veriyenile={() => { this.haberler(); }}
                                                bekleyen={false}
                                                key={item.id}
                                                id={item.id}
                                                resimler={item.resimler}
                                                stk={item.stk}
                                                time={item.time}
                                                des={item.description}
                                                url={item.url}
                                            />
                                        )
                                    })}


                                    {this.state.haberler.length >= 10 &&
                                        <>
                                            {this.state.loading == false ?

                                                <div style={{ width: "100%", textAlign: "-webkit-center" }} >
                                                    <div
                                                        onClick={() => this.setState({ page: this.state.page + 1 }, () => { this.haberdevam() })}
                                                        style={{
                                                            cursor: "pointer", color: "#404040",textAlign:"center",
                                                            textDecorationLine:"underline",
                                                             padding: 5, width: 100,
                                                        }} >daha fazla</div>
                                                </div>

                                                :
                                                <div style={{width:"100%",textAlign:"center"}} >
<Spinner animation="border" variant="primary" />
    </div>
                                                }
                                        </>
                                    }
                                </div>
                                <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0" style={{marginTop:10}} >


                                    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3" style={{padding:25}} >

                                        <a href={
                                            (this.getOS() == "Mac" || this.getOS() == "iOS") ? 
                                            "https://apps.apple.com/tr/app/sivil-d%C3%BCnya-kocaeli/id1491007133?l=tr"
                                             : 
                                             "https://play.google.com/store/apps/details?id=com.sivildunyakocaeli&hl=en_US&gl=US"
                                        } target="_blank" >
                                        <img src="/assets/images/stkgorsel.png" style={{width:"100%"}}  />
                                        </a>


                                    </div>



                                    <Events etkinlikler={this.state.etkinlikler} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Appfooter />

                <TanitimPopup show={this.state.showPopup} onClose={this.closePopup} />

            </Fragment>
        );
    }
}

export default Anasayfa;