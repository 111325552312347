import React, { Component, Fragment } from "react";

import {  Grid, TextField,Button} from '@material-ui/core';

import Dropzone from 'react-dropzone';

import {  MuiPickersUtilsProvider, } from '@material-ui/pickers';
import { tr } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import axios from "axios";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

class EtkinlikEkle extends Component {


    constructor() {
        super();
        this.onDrop = (files) => {
          this.setState({files},() => {this.resimDuzenle()})
        };
        this.state = {
            token:"",
           stk:[],
          files: [],
          filesBase64: [],
          stkTur: 1,
          stklar: [],
          selectStk: 0,
    
          baslik: "",
          aciklama: "",
          lokasyon: "",
          tarih: format(new Date(),'yyyy-MM-dd'),
          saat: "",
    
          bittarih: format(new Date(),'yyyy-MM-dd'),
          bitsaat:"",
    
      
          kontrol: false,
          loading: false,
    
        };
      }



  resimDuzenle() {

    var galery = [];
    this.state.files.forEach(file => {

      let idCardBase64 = '';
      this.getBase64(file, (result) => {
           idCardBase64 = result;
           galery=[...galery,result]

           this.setState({
            filesBase64:galery
           })
           
      });
      
    });

  }

  componentDidMount() {

    this.setState({
        stk: JSON.parse(localStorage.getItem("stk")),
        token: JSON.parse(localStorage.getItem("access_token")),
    })

  }



  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
      //   console.log('Error: ', error);
    };
}

  kayitolustur() {
    this.setState({
      kontrol: true,
      loading: true,
    })
    
    if (this.state.baslik != "" && this.state.aciklama != "" && this.state.lokasyon != "" && 
    this.state.tarih != "" && this.state.saat != "" ) {

      const formData = new FormData();
      formData.append("baslik", this.state.baslik);
      formData.append("aciklama", this.state.aciklama);
      formData.append("lokasyon", this.state.lokasyon);
      formData.append("bastarih", this.state.tarih);
      formData.append("bassaat", this.state.saat);
      formData.append("bittarih", this.state.bittarih);
      formData.append("bitsaat", this.state.bitsaat);

      formData.append("katilim", 0);

      formData.append("stkId", this.state.stk?.id);
      formData.append("filesBase64", JSON.stringify(this.state.filesBase64));

      axios.post('https://api.sivildunyakocaeli.com/mobil/etkinlikler/yeniEtkinlik', formData, {
        headers: {
          "Accept": "*/*",
          "Authorization": "Bearer " + this.state.token,
          "x-requested-with": "XMLHttpRequest",
        },
      }).then((response) => {

        this.setState({

          loading: false,
        })

        if (response.data.success == true) {
            
         

            this.props?.onaylandi();
            

        } else {
         
        }


      });



    } else {
      this.setState({

        loading: false,
      })
      
    }

    

  }



    render() {
        return (
            <Grid container columns={{ xs: 12, sm: 12, md: 12, }} style={{backgroundColor:"#fff",padding:25,borderRadius:5}} >




<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >

<TextField

  type="text"
  error={(this.state.kontrol == true && this.state.baslik == "") ? true : false}
  helperText={(this.state.kontrol == true && this.state.baslik == "") ? "Bu alan boş geçilemez" : ""}

  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Etkinlik Başlığı</div>}
  value={this.state.baslik}
  onChange={(e) => this.setState({ baslik: e.target.value })}
  style={{ width: "100%" }}
  InputProps={{}}
  variant="outlined"
/>


</Grid>


<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >

<TextField

  multiline
  rows={5}
  type="text"
  error={(this.state.kontrol == true && this.state.aciklama == "") ? true : false}
  helperText={(this.state.kontrol == true && this.state.aciklama == "") ? "Bu alan boş geçilemez" : ""}

  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Etkinlik İçeriği</div>}
  value={this.state.aciklama}
  onChange={(e) => this.setState({ aciklama: e.target.value })}
  style={{ width: "100%" }}
  InputProps={{}}
  variant="outlined"
/>


</Grid>

<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >

<TextField

  type="text"
  error={(this.state.kontrol == true && this.state.lokasyon == "") ? true : false}
  helperText={(this.state.kontrol == true && this.state.lokasyon == "") ? "Bu alan boş geçilemez" : ""}

  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Etkinlik Lokasyonu</div>}
  value={this.state.lokasyon}
  onChange={(e) => this.setState({ lokasyon: e.target.value })}
  style={{ width: "100%" }}
  InputProps={{}}
  variant="outlined"
/>


</Grid>


<Grid item xs={12} sm={6} md={6} style={{ padding: 10, textAlign: "initial" }}  >
  
                <MuiPickersUtilsProvider locale={tr} utils={DateFnsUtils}>
              
                <TextField
        id="date"
        type="date"
        defaultValue={format(new Date(),'yyyy-MM-dd')}
        error={(this.state.kontrol == true && this.state.tarih == "") ? true : false}
        helperText={(this.state.kontrol == true && this.state.tarih == "") ? "Bu alan boş geçilemez" : ""}

        label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Etkinlik Başlangıç Tarihi</div>}
        value={this.state.tarih}
        onChange={(e) => this.setState({ tarih: e.target.value })}
        style={{ width: "100%" }}
        InputProps={{}}
        variant="outlined"
      />
      
      </MuiPickersUtilsProvider>
      
        </Grid>


        <Grid item xs={12} sm={6} md={6} style={{ padding: 10, textAlign: "initial" }}  >
                
                <MuiPickersUtilsProvider locale={tr} utils={DateFnsUtils}>
              
                <TextField
        id="time"
        type="time"
        defaultValue="07:30"
        
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}

        error={(this.state.kontrol == true && this.state.saat == "") ? true : false}
        helperText={(this.state.kontrol == true && this.state.saat == "") ? "Bu alan boş geçilemez" : ""}
        label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Etkinlik Başlangıç Saati</div>}
        value={this.state.saat}
        onChange={(e) => this.setState({ saat: e.target.value })}
        style={{ width: "100%" }}
        InputProps={{}}
        variant="outlined"
      />
      
      </MuiPickersUtilsProvider>
        </Grid>


        <Grid item xs={12} sm={6} md={6} style={{ padding: 10, textAlign: "initial" }}  >
  
                <MuiPickersUtilsProvider locale={tr} utils={DateFnsUtils}>
              
                <TextField
        id="date"
        type="date"
        defaultValue={format(new Date(),'yyyy-MM-dd')}
        error={(this.state.kontrol == true && this.state.bittarih == "") ? true : false}
        helperText={(this.state.kontrol == true && this.state.bittarih == "") ? "Bu alan boş geçilemez" : ""}
        label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Etkinlik Bitiş Tarihi</div>}
        value={this.state.bittarih}
        onChange={(e) => this.setState({ bittarih: e.target.value })}
        style={{ width: "100%" }}
        InputProps={{}}
        variant="outlined"
      />
      
      </MuiPickersUtilsProvider>
      
        </Grid>


        <Grid item xs={12} sm={6} md={6} style={{ padding: 10, textAlign: "initial" }}  >
                
                <MuiPickersUtilsProvider locale={tr} utils={DateFnsUtils}>
              
                <TextField
        id="time"
        type="time"
        defaultValue="07:30"
        
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}

        error={(this.state.kontrol == true && this.state.bitsaat == "") ? true : false}
        helperText={(this.state.kontrol == true && this.state.bitsaat == "") ? "Bu alan boş geçilemez" : ""}
        label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Etkinlik Bitiş Saati</div>}
        value={this.state.bitsaat}
        onChange={(e) => this.setState({ bitsaat: e.target.value })}
        style={{ width: "100%" }}
        InputProps={{}}
        variant="outlined"
      />
      
      </MuiPickersUtilsProvider>
        </Grid>



                <Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >
                <Dropzone onDrop={this.onDrop} accept={[".png",".jpg",".jpeg"]} >
        {({getRootProps, getInputProps}) => (
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})} 
            style={{ borderColor: this.state.files.length > 0 ? "green" : 
            (this.state.files?.length==0 && this.state.kontrol==true) ? "red" : "" }} >
               
              <input {...getInputProps()} />
              {(this.state.files.length==0) ?
              <p style={{color:"#aaa"}} >Eklemek istediğiniz resimleri sürükleyip bırakabilirsiniz.</p>
              :

              <p style={{color:"green"}} >{this.state.files.length} adet resim seçimi yaptınız.</p>
  }
            </div>
          </section>
        )}
      </Dropzone>
      </Grid>

<div style={{width:"100%",textAlign:"right"}} >
      {this.state.loading == false ?
              <>

<Button onClick={() => this.kayitolustur()}  className=" z-index-1 
                        rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12
                        ,fontWeight:600,height:40,lineHeight:0,display:"inline",justifyContent:"center",
                        alignItems:"center",padding:15
                        }} >
                          Oluştur</Button>
                          

                </> :
               <Spinner style={{marginRight:10}}  animation="border" variant="primary" />
              }
</div>


              </Grid>
        )
    }
}


export default EtkinlikEkle;