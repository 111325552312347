import axios from 'axios';
import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import { Popup } from 'semantic-ui-react'

import { SocialIcon } from 'react-social-icons';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";



class Postview extends Component {
    state = {
        isOpen: false,
        resimopen: false,
        seciliresim: 0,
        takdir: false,

        token: "",
        stk: [],
    };


    componentDidMount() {

        if (localStorage.getItem("stk") != null && localStorage.getItem("stk") != "") {
            this.setState({
                stk: JSON.parse(localStorage.getItem("stk")),
                token: JSON.parse(localStorage.getItem("access_token")),
            })
        }

    }



    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
    toggleActive = async () => {

        this.setState({ takdir: !this.state.takdir });

        const formData = new FormData();
        formData.append("haberId", this.props.id);

        await axios.post("https://api.sivildunyakocaeli.com/mobil/haberler/takdiret", formData, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {

            //     console.log("takdir", response.data);



            });

    }


    haberiSil = async (id) => {

        const formData = new FormData();
        formData.append("haberId", this.props?.id);
        formData.append("stkId", this.state?.stk?.id);

        await axios.post("https://api.sivildunyakocaeli.com/mobil/haberler/habersil", formData, {
            headers: {
                "Accept": "*/*",
                "Authorization": "Bearer " + this.state.token,
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {

          //       console.log("res", response.data);

                this?.props?.veriyenile();



            });

    }

    render() {

        const { user, time, des, avater, postimage, postvideo, id, detay } = this.props;

        const menuClass = `${this.state.isOpen ? " show" : ""}`;


        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                {this.state?.stk?.id == this.props?.stk?.id ?
                    <div style={{ position: "absolute", right: 10, top: 10 }} >

                        <Popup
                            content={
                                <>
                                    <div style={{
                                        backgroundColor: "#fff", minWidth: 75,
                                        borderWidth: 1, borderStyle: "solid", borderColor: "#ddd", borderRadius: 5, padding: 8
                                        , justifyContent: "center", display: "flex"
                                    }} >
                                        <div onClick={() => this.haberiSil(id)} style={{ fontSize: 12, color: "#404040", cursor: "pointer" }} >Haberi Sil</div>
                                    </div>
                                </>
                            }
                            offset={[0, 0]}
                            on='click'
                            hoverable
                            position="bottom center"
                            basic

                            popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
                            trigger={
                                <div style={{ cursor: "pointer" }} ><i class="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div>
                            }
                        />

                    </div> : null}



                <a href={"/stk/" + this.props?.stk?.url} className="card-body p-0 d-flex">
                    <figure className="avatar me-3"><img src={"https://api.sivildunyakocaeli.com/" + this.props?.stk?.profileImage}
                        alt="" className="shadow-sm rounded-circle " style={{ width: 45, height: 45, objectFit: "cover" }} /></figure>
                    <h4 className={"fw-700 text-grey-900 font-xssss mt-1 "
                        + (this.props.bekleyen == true ? " mobilnamecss2" : " mobilnamecss")} style={{ marginRight: 60 }} >
                        {this.props?.stk?.name?.length > 75 ?
                            `${this.props?.stk?.name.substring(0, 75)}...` : this.props?.stk?.name
                        }
                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                            <i className="feather-globe" style={{ marginRight: 5 }} ></i>
                            {time}
                            {this.props.bekleyen == true &&
                                <div style={{ color: "red", fontSize: 11 }} >
                                    <i className="feather-alert-triangle" style={{ marginRight: 5, fontSize: 11 }} ></i>
                                    Onay Bekliyor</div>}

                        </span></h4>

                </a>


                <div className="card-body p-0 me-lg-5">

                    <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2">
                        {detay == true ?

                            des?.split('\n').map(function (item) {
                                return (
                                    <div>{item}</div>

                                )
                            })

                            :
                            <>
                                {des?.length > 250 ?

                                    <>
                                        {des?.substring(0, 250)?.split('\n').map(function (item, index) {
                                            if (index == des?.substring(0, 250).split('\n').length - 1) {
                                                return (
                                                    <div>{item}...</div>

                                                )
                                            } else {
                                                return (
                                                    <div>{item}</div>

                                                )
                                            }
                                        })}

                                    </> :
                                    des?.split('\n').map(function (item) {
                                        return (
                                            <div>{item}</div>

                                        )
                                    })


                                }
                            </>}

                        {(detay == true || des?.length <= 250 || this.props?.bekleyen == true) ? null :
                            <a href={"/haberdetay/" + this.props.url} className="fw-600 text-primary">Detaya git</a>
                        }
                    </p>

                </div>

                {this.props?.resimler?.length == 1 &&
                    <div className="card-body d-block p-0 mb-3">
                        {this.props?.resimler.map((item, index) => {
                            return (
                                <div key={item.id} className="row ps-2 pe-2">
                                    <div className="col-sm-12 p-1" style={{ cursor: "pointer" }}
                                        onClick={() => this.setState({ resimopen: true, seciliresim: index })} >
                                        <img src={`https://api.sivildunyakocaeli.com/${item.url}`}
                                            className="mobil1resim rounded-3 w-100" style={{ maxHeight: 400, objectFit: "cover" }}
                                            alt="post" /></div>
                                </div>
                            )
                        })}
                    </div>
                }

                {this.props?.resimler?.length == 2 &&
                    <div className="row ps-2 pe-2">
                        {this.props?.resimler.map((item, index) => {
                            return (
                                <div key={item.id} className="col-xs-6 col-sm-6 p-1" style={{ cursor: "pointer" }}
                                    onClick={() => this.setState({ resimopen: true, seciliresim: index })} >
                                    <img style={{ height: 300, objectFit: "cover" }} src={`https://api.sivildunyakocaeli.com/${item.url}`}
                                        className="mobil2resim rounded-3 w-100" alt="image" /></div>
                            )
                        })}

                    </div>
                }


                {this.props?.resimler?.length == 3 &&
                    <div className="row ps-2 pe-2">
                        {this.props?.resimler.map((item, index) => {
                            return (
                                <div key={item.id} className="col-xs-4 col-sm-4 p-1" style={{ cursor: "pointer" }}
                                    onClick={() => this.setState({ resimopen: true, seciliresim: index })} >
                                    <img style={{ height: 200, objectFit: "cover" }} src={`https://api.sivildunyakocaeli.com/${item.url}`}
                                        className="mobil3resim rounded-3 w-100" alt="image" /></div>
                            )
                        })}
                    </div>
                }

                {(this.props.detay == true && this.props?.resimler?.length > 3) ?

                    <div className="row ps-2 pe-2">
                        {this.props?.resimler && this.props?.resimler.map((item, index) => {
                            return (
                                <div key={item.id} className="col-xs-4 col-sm-4 p-1" style={{ cursor: "pointer" }}
                                    onClick={() => this.setState({ resimopen: true, seciliresim: index })} >
                                    <img style={{ height: 200, objectFit: "cover" }} src={`https://api.sivildunyakocaeli.com/${item.url}`}
                                        className="mobil3resim rounded-3 w-100" alt="image" /></div>
                            )
                        })}
                    </div>

                    :


                    <>
                        {this.props?.resimler?.length > 3 &&
                            <div className="row ps-2 pe-2">
                                <div className="col-xs-4 col-sm-4 p-1" style={{ cursor: "pointer" }}
                                    onClick={() => this.setState({ resimopen: true, seciliresim: 0 })}
                                >
                                    <img style={{ height: 200, objectFit: "cover" }} src={`https://api.sivildunyakocaeli.com/${this.props?.resimler[0].url}`}
                                        className="mobil3resim rounded-3 w-100" alt="image" /></div>
                                <div className="col-xs-4 col-sm-4 p-1" style={{ cursor: "pointer" }}
                                    onClick={() => this.setState({ resimopen: true, seciliresim: 1 })} >
                                    <img style={{ height: 200, objectFit: "cover" }} src={`https://api.sivildunyakocaeli.com/${this.props?.resimler[1].url}`}
                                        className="mobil3resim rounded-3 w-100" alt="image" /></div>
                                <div className="col-xs-4 col-sm-4 p-1"><a href={"/haberdetay/" + this.props.url}
                                    data-lightbox="roadtrip" className="position-relative d-block">
                                    <img style={{ height: 200, objectFit: "cover" }}
                                        src={`https://api.sivildunyakocaeli.com/${this.props?.resimler[2].url}`} className="mobil3resim rounded-3 w-100" alt="image" /><span
                                            className="img-count font-sm text-white ls-3 fw-600"><b>+{this.props?.resimler.length - 3}</b></span></a>
                                </div>
                            </div>
                        }
                    </>}



                {this.state.resimopen && (
                    <Lightbox

                        mainSrc={'https://api.sivildunyakocaeli.com/' + this.props?.resimler[this.state.seciliresim].url}
                        nextSrc={'https://api.sivildunyakocaeli.com/' + this.props?.resimler[(this.state.seciliresim + 1) % this.props?.resimler.length].url}
                        prevSrc={'https://api.sivildunyakocaeli.com/' + this.props?.resimler[(this.state.seciliresim + this.props?.resimler.length - 1) % this.props?.resimler.length].url}

                        onMovePrevRequest={() =>
                            this.setState({
                                seciliresim: (this.state.seciliresim + this.props?.resimler.length - 1) % this.props?.resimler.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                seciliresim: (this.state.seciliresim + 1) % this.props?.resimler.length,
                            })
                        }

                        onCloseRequest={() => this.setState({ resimopen: false })}

                    />)}


                {!(this.props.bekleyen == true) &&
                    <div className="card-body d-flex p-0" style={{ marginTop: 10 }} >
                        {this.state.takdir == true ?
                            <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark
                     lh-26 font-xssss me-2"  >
                                <img src="/assets/images/takdirdolu.svg" style={{ height: 30, marginRight: 5 }} />
                                Beğenildi</div>
                            :
                            <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark
                     lh-26 font-xssss me-2" onClick={() => this.toggleActive()}  >
                                <img src="/assets/images/takdirbos.svg" style={{ height: 30, marginRight: 5 }} />
                                Beğen</div>}





                        <Popup
                            content={
                                <>
                                    <div style={{
                                        backgroundColor: "#fff", width: 200,
                                        borderWidth: 1, borderStyle: "solid", borderColor: "#ddd", borderRadius: 15, padding: 10
                                        , justifyContent: "center", display: "flex"
                                    }} >

                                        <span>
                                            <FacebookShareButton title={des?.substring(0, 25)} url={"https://sivildunyakocaeli.com/haberdetay/" + this.props?.url}
                                                children={
                                                    <SocialIcon style={{ width: 35, height: 35, margin: 5 }} network="facebook" />
                                                } />
                                        </span>

                                        <span>
                                            <LinkedinShareButton title={des?.substring(0, 25)} url={"https://sivildunyakocaeli.com/haberdetay/" + this.props?.url}
                                                children={
                                                    <SocialIcon style={{ width: 35, height: 35, margin: 5 }} network="linkedin" />
                                                } />
                                        </span>

                                        <span>
                                            <TwitterShareButton title={des?.substring(0, 25)} url={"https://sivildunyakocaeli.com/haberdetay/" + this.props?.url}
                                                children={
                                                    <SocialIcon style={{ width: 35, height: 35, margin: 5 }} network="twitter" />
                                                } />
                                        </span>


                                        <span>
                                            <WhatsappShareButton title={des?.substring(0, 25)} url={"https://sivildunyakocaeli.com/haberdetay/" + this.props?.url}
                                                children={
                                                    <SocialIcon style={{ width: 35, height: 35, margin: 5 }} network="whatsapp" />
                                                } />
                                        </span>


                                    </div>
                                </>
                            }
                            offset={[0, 0]}
                            on='hover'
                            hoverable
                            position="bottom center"
                            basic

                            popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
                            trigger={
                                <div className={`pointer ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss 
                            `} data-bs-toggle="dropdown" aria-expanded="false"
                                ><img src="/assets/images/paylas.svg" style={{ height: 30, marginRight: 5 }} />
                                    <span className="d-none-xs" style={{ fontSize: 12 }} >Paylaş</span></div>
                            }
                        />


                    </div>}


            </div>
        );
    }
}

export default Postview;