import axios from "axios";
import React, { Component, createRef, Fragment } from "react";
import { Spinner } from "react-bootstrap";
import Load from "../components/Load";
import { Button, Card, CardContent, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';

import InputMask from 'react-input-mask';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from "react-router-dom";


class Basvuru extends Component {

    constructor() {
        super();

        this.state = {
            
    kutukNo: "",
    kurumAdi: "",
    kisaAdi: "",
    email: "",
    ilce: "",
    adres: "",
    stkTur: 1,
    baskanAdi: "",
    baskanSoyadi: "",
    baskanTelefon: "",
    baskanEmail: "",
    baskanAdres: "",
    stkInputs: [],

    kutukHata: false,
    kutukHataMesaj: "",

    kontrol: false,
    loading: false,

        }
    }


  componentDidMount() {

    if(localStorage.getItem("user") && localStorage.getItem("stk")  ) {
        this.props.history.push("/")
    }

    this.stkTurInputs();

  }

  stkTurInputs() {

    if(this.state.stkTur!=1) {

      this.setState({
      kutukNo:"KBB-"+parseInt(999999999 + (Math.random() * (999999999999) ) )
      })

    }

    axios.get('https://api.sivildunyakocaeli.com/stklar/stkTurInputs/' + this.state.stkTur).then((item) => {

      this.setState({
        stkInputs: item.data.data
      })

    });

  }

  kayitolustur() {

    this.setState({
      kontrol: true,
      loading: true,
    })

    if (this.state.kutukNo != "" && this.state.kurumAdi != "" && this.state.email != "" && this.state.baskanAdi != ""
      && this.state.baskanSoyadi != "" && this.state.baskanTelefon != "") {

      const formData = new FormData();
      formData.append("kutukNo", this.state.kutukNo);
      formData.append("kurumAdi", this.state.kurumAdi);
      formData.append("kisaAdi", this.state.kisaAdi);
      formData.append("email", this.state.email);
      formData.append("ilce", this.state.ilce);
      formData.append("adres", this.state.adres);
      formData.append("stkTur", this.state.stkTur);
      formData.append("baskanAdi", this.state.baskanAdi);
      formData.append("baskanSoyadi", this.state.baskanSoyadi);
      formData.append("baskanTelefon", this.state.baskanTelefon);
      formData.append("baskanEmail", this.state.baskanEmail);
      formData.append("baskanAdres", this.state.baskanAdres);
      formData.append("stkInputs", JSON.stringify(this.state.stkInputs));

      axios.post('https://api.sivildunyakocaeli.com/mobil/stklar/yeniStk', formData, {
        headers: {
          "Accept": "*/*",
          "x-requested-with": "XMLHttpRequest",
        },
      }).then((response) => {

        this.setState({

          loading: false,
        })

        if (response.data.success == true) {

            window.location.href="/onay";

        } else {
          if (response.data.data == "kutukNo") {

            document.getElementById("scrool").scrollTo(0, 0);
            
            this.setState({
              kutukHata: true,
              kutukHataMesaj: response.data.message,
            })
          }
        }


      });



    } else {
      this.setState({

        loading: false,
      })
      document.getElementById("scrool").scrollTo(0, 0);
    }

    

  }

  inputGuncelle(id, value) {

    const inputlar = this.state.stkInputs;

    inputlar.forEach((item, index) => {

      if (inputlar[index].id == id) {
        inputlar[index].value = value;

      }
    });

    this.setState({
      stkInputs: inputlar
    })

  }

    render() {
        return (
            <Fragment>
                <div className="main-wrap">
                   
                   
                    <div className="row">
                        <div className="col-xl-6 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                            style={{ backgroundImage: `url("https://api.sivildunyakocaeli.com/uploads/basvuru2.jpg")` }}></div>
                        <div className="col-xl-6 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                            <div className="card shadow-none border-0 ms-auto" 
                             id="scrool"
                            style={{ paddingRight: 75 , paddingLeft:75 , overflowY:"auto" , 
                            height:"100vh"
                             }}>
                                
                         
                         
                                <div className="card-body rounded-0 text-left" style={{paddingTop:80}} >
                                    
                                 
                                       
                              
                                    <Grid container columns={{ xs: 12, sm: 12, md: 12}} 
                                     
                                    >

<Grid item xs={12} sm={12} md={12} style={{ padding: 10, textAlign: "initial" }}  >
  <Typography variant="h6" className="font-medium" style={{ marginLeft: 10 }} >
    1. Stk Bilgileri
  </Typography>
</Grid>


<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >

<Autocomplete
  id="combo-box-demo"
  options={[{ id: 1, name: "Dernek" },
  { id: 2, name: "Vakıf" },
  { id: 3, name: "Meslek Odası" },
  { id: 4, name: "Sendika" },
  { id: 5, name: "Platform" },
  { id: 6, name: "Öğrenci Kulübü" },
  { id: 7, name: "İl Temsilciliği" },
  { id: 8, name: "Kamu Kurumu" },
  ]}
  defaultValue={{ id: 1, name: "Dernek" }}
  getOptionLabel={(option) => option.name}
  renderInput={(params) => <TextField {...params} 
 

  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Stk Türü *</div>}
  variant="outlined"
  
  />}
  onChange={(event, value) => {
    this.setState({ stkTur: value.id,kutukNo:"", }, () => { this.stkTurInputs(); })
  }}
/>




</Grid>

{(this.state.stkTur==1) &&
<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >



<InputMask
    mask="99-999-999"
    value={this.state.kutukNo}
    onChange={(e) => this.setState({ kutukNo: e.target.value })}
    disabled={false}
    maskChar=" "
  >
    {() => <TextField

      className="mb-16"
      
    label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Kütük No</div>}
      style={{ width: "100%" }}
      type="text"
      error={(this.state.kontrol == true && this.state.kutukNo == "") ? true : this.state.kutukHata == true ? true : false}
      helperText={(this.state.kontrol == true && this.state.kutukNo == "") ? "Bu alan boş geçilemez" : this.state.kutukHata == true ? this.state.kutukHataMesaj : ""}
  
      variant="outlined"
      InputProps={{}}
    />}
  </InputMask>




</Grid> }

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

  <TextField

    className="mb-16"
    
    label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Kurum Adı</div>}
    style={{ width: "100%" }}
    error={(this.state.kontrol == true && this.state.kurumAdi == "") ? true : false}
    helperText={(this.state.kontrol == true && this.state.kurumAdi == "") ? "Bu alan boş geçilemez" : ""}
    value={this.state.kurumAdi}
    onChange={(e) => this.setState({ kurumAdi: e.target.value })}
    type="text"
    variant="outlined"
    InputProps={{}}
  />


</Grid>

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

  <TextField

    className="mb-16"
    label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Kısa Adı</div>}
    style={{ width: "100%" }}
    value={this.state.kisaAdi}
    onChange={(e) => this.setState({ kisaAdi: e.target.value })}
    type="text"
    error={""}
    helperText={""}
    variant="outlined"
    InputProps={{}}
  />

</Grid>

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

  <TextField

    className="mb-16"
    label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >E-mail Adresi</div>}
    style={{ width: "100%" }}
    value={this.state.email}
    onChange={(e) => this.setState({ email: e.target.value })}
    type="email"
    error={(this.state.kontrol == true && this.state.email == "") ? true : false}
    helperText={(this.state.kontrol == true && this.state.email == "") ? "Bu alan boş geçilemez" : ""}
    variant="outlined"
    InputProps={{}}
  />


</Grid>

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

<Autocomplete
  id="combo-box-demo"
  options={[{ id: "BAŞİSKELE", name: "BAŞİSKELE" },
  { id: "ÇAYIROVA", name: "ÇAYIROVA" },
  { id: "DARICA", name: "DARICA" },
  { id: "DERİNCE", name: "DERİNCE" },
  { id: "DİLOVASI", name: "DİLOVASI" },
  { id: "GEBZE", name: "GEBZE" },
  { id: "GÖLCÜK", name: "GÖLCÜK" },
  { id: "İZMİT", name: "İZMİT" },
  { id: "KANDIRA", name: "KANDIRA" },
  { id: "KARAMÜRSEL", name: "KARAMÜRSEL" },
  { id: "KARTEPE", name: "KARTEPE" },
  { id: "KÖRFEZ", name: "KÖRFEZ" },
  ]}
  defaultValue={{ id: "BAŞİSKELE", name: "BAŞİSKELE" }}
  getOptionLabel={(option) => option.name}
  renderInput={(params) => <TextField {...params} 

  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >İlçe</div>}
  variant="outlined" />}
  onChange={(event, value) => {
    if(value!=null && value!="")  this.setState({ ilce: value.id }); else {}
  }}
/>


</Grid>


<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >

  <TextField

    multiline
    
    label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Adres</div>}
    placeholder="STK adresini giriniz."
    style={{ width: "100%" }}
    value={this.state.adres}
    onChange={(e) => this.setState({ adres: e.target.value })}
    rows={5}
    type="text"
    error={""}
    helperText={""}
    variant="outlined"
    InputProps={{}}
  />


</Grid>



<Grid item xs={12} sm={12} md={12} style={{ padding: 10, textAlign: "initial" }}  >
  <Typography variant="h6" className="font-medium" style={{ marginLeft: 10 }} >
    2. Başkan Bilgileri
  </Typography></Grid>

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

  <TextField

    className="mb-16"
    type="text"
    error={(this.state.kontrol == true && this.state.baskanAdi == "") ? true : false}
    helperText={(this.state.kontrol == true && this.state.baskanAdi == "") ? "Bu alan boş geçilemez" : ""}

    label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Başkan Adı</div>}
    value={this.state.baskanAdi}
    onChange={(e) => this.setState({ baskanAdi: e.target.value })}
    style={{ width: "100%" }}
    InputProps={{}}
    variant="outlined"
  />



</Grid>

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

  <TextField

    className="mb-16"
    
    label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Başkan Soyadı</div>}
    value={this.state.baskanSoyadi}
    onChange={(e) => this.setState({ baskanSoyadi: e.target.value })}
    style={{ width: "100%" }}
    type="text"
    error={(this.state.kontrol == true && this.state.baskanSoyadi == "") ? true : false}
    helperText={(this.state.kontrol == true && this.state.baskanSoyadi == "") ? "Bu alan boş geçilemez" : ""}
    variant="outlined"
    InputProps={{}}

  />


</Grid>

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

  <InputMask
    mask="0(999) 999 99 99"
    value={this.state.baskanTelefon}
    onChange={(e) => this.setState({ baskanTelefon: e.target.value })}
    disabled={false}
    maskChar=" "
  >
    {() => <TextField

      className="mb-16"
      
      label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Başkan Telefon</div>}
      style={{ width: "100%" }}
      type="text"
      error={(this.state.kontrol == true && this.state.baskanTelefon == "") ? true : false}
      helperText={(this.state.kontrol == true && this.state.baskanTelefon == "") ? "Bu alan boş geçilemez" : ""}
      variant="outlined"
      InputProps={{}}
    />}
  </InputMask>


</Grid>

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

  <TextField

    className="mb-16"
    
    label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Başkan E-mail Adresi</div>}
    value={this.state.baskanEmail}
    onChange={(e) => this.setState({ baskanEmail: e.target.value })}
    style={{ width: "100%" }}
    type="email"
    error={""}
    helperText={""}
    variant="outlined"
    InputProps={{}}
  />


</Grid>

<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >

  <TextField

    multiline
    
    label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Başkan Adresi</div>}
    value={this.state.baskanAdres}
    onChange={(e) => this.setState({ baskanAdres: e.target.value })}
    placeholder="Başkanın adresini giriniz."
    style={{ width: "100%" }}
    rows={5}
    type="text"
    error={""}
    helperText={""}
    variant="outlined"
    InputProps={{}}
  />


</Grid>
{(this.state.stkInputs.length > 0) &&
  <>
    <Grid item xs={12} sm={12} md={12} style={{ padding: 10, textAlign: "initial" }}  >
      <Typography variant="h6" className="font-medium" style={{ marginLeft: 10 }} >
        3. Stk Detay Bilgileri
      </Typography></Grid>

    {this.state.stkInputs && this.state.stkInputs.map((input) => {
      return (
        <Grid key={input.id}
          item xs={12} sm={input.col} md={input.col}
          style={{ padding: 10 }}  >


          {(input.inputType == 1 || input.inputType == 2 || input.inputType == 4) &&
            <TextField
              className="mb-16"
              
              label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >{input.title}</div>}
              style={{ width: "100%" }}
              type={input.inputType == 3 ? "email" : "text"}
              //     error={true}
              //    helperText={"deneme"}

              multiline={input.inputType == 2 ? true : false}
              rows={input.inputType == 2 ? 5 : 1}

              value={input.value}
              onChange={(e) => this.inputGuncelle(input.id, e.target.value)}
              variant="outlined"
              InputProps={{}}
            />}

          {(input.inputType == 3) &&


<Autocomplete
  id="combo-box-demo"
  options={input.options}
  getOptionLabel={(option) => option.value}
  renderInput={(params) => <TextField {...params} 


  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >{input.title}</div>}
  variant="outlined" />}
  onChange={(event, value) => {
    this.inputGuncelle(input.id, value.id)
  }}
/>
          }


          {(input.inputType == 11) &&
            <InputMask
              mask="0(999) 999 99 99"
              value={input.value}
              onChange={(e) => this.inputGuncelle(input.id, e.target.value)}
              disabled={false}
              maskChar=" "
            >
              {() => <TextField

                className="mb-16"
                
                label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >{input.title}</div>}
                style={{ width: "100%" }}
                type="text"
                error={""}
                helperText={""}
                variant="outlined"
                InputProps={{}}
              />}
            </InputMask>}



        </Grid>


      )
    })
    }

  </>
}

</Grid>

<div style={{width:"100%",textAlign:"right",marginTop:20,marginBottom:20}} >
{this.state.loading == false ?
<>




                <Button component={Link}
to="/girisyap" className=" z-index-1 
                        rounded-3 " style={{backgroundColor:"#fff",fontSize:12,marginLeft:20,borderWidth:1,borderStyle:"solid"
                        ,borderColor:"#1478FB",color:"#1478FB"
                        ,fontWeight:600,height:40,lineHeight:0,display:"inline",justifyContent:"center",marginRight:0,
                        alignItems:"center",padding:13,paddingRight:25,paddingLeft:25
                        }} >
                          Geri Dön</Button>


<Button onClick={() => this.kayitolustur()}  className=" z-index-1 
                        rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12,marginLeft:20
                        ,fontWeight:600,height:40,lineHeight:0,display:"inline",justifyContent:"center",marginRight:10,
                        alignItems:"center",padding:13,width:120,
                        }} >
                          Oluştur</Button>

  </>
 :

<Spinner style={{marginRight:10}}  animation="border" variant="primary" />

}
             
</div>                
                             

                                </div> 








                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Basvuru;