import axios from 'axios';
import React,{Component} from 'react';
import Slider from "react-slick";



class Storyslider extends Component {

    constructor() {
        super();
       
    }





    render() {
        const storysettings = {
            arrows: false,
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            centerMode: false,
            variableWidth: true
        };
        return (
            <Slider {...storysettings}>


                {this.props.hikayeler.map((value , index) => (
                <a href={ value.id!=26 ?  "/hikayedetay/"+value.url : "hibe-duyurular"} key={index}>
                    <div className="card w125 h200 d-block border-0 shadow-xss rounded-xxxl 
                    bg-gradiant-bottom overflow-hidden cursor-pointer mb-3 mt-0 me-3" 
                    style={{backgroundImage: `url("https://api.sivildunyakocaeli.com/${value.coverImage}")`,
                    backgroundPosition: "center",backgroundSize: "cover",backgroundRepeat:"no-repeat"
    }}>
                        <div className="card-body d-block p-3 w-100 position-absolute bottom-0 text-center" >
                         
                           <div className="clearfix mt-1"></div>
                            <h4 className="fw-600 position-relative z-index-1 ls-1 font-xssss text-white mt-2 mb-1"
                            style={{textAlign:"left"}}
                            >
                            {value.title.length > 18 ?
    `${value.title.substring(0, 18)}...` : value.title
  }
  </h4>
                        </div>
                    </div>
                </a>
                ))}
            </Slider>
        );
    }
}

export default Storyslider;