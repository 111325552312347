import axios from "axios";
import React, { Component } from "react";
import { Button, Card, CardContent, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';


import InputMask from 'react-input-mask';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { tr } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { Spinner } from "react-bootstrap";


class ProfilGuncelle extends Component {

  constructor() {
    super();
   
    this.state = {

      stk:[],
      token:"",

      eskiIcerik:[],

      kutukNo: "",
      kurumAdi: "",
      kisaAdi: "",
      email: "",
      ilce: "",
      adres: "",
      stkTur: 1,
      baskanAdi: "",
      baskanSoyadi: "",
      baskanTelefon: "",
      baskanEmail: "",
      baskanAdres: "",
      stkInputs: [],
      baskanMesaj:"",
  
      kutukHata: false,
      kutukHataMesaj: "",
      bilgiDurum:0,
  
      kontrol: false,
      loading: false,

      ilgiAlanlari:[],
      seciliAlanlar:[],


    };
  }

  

  componentDidMount() {

    this.setState({
      stk: JSON.parse(localStorage.getItem("stk")),
      token: JSON.parse(localStorage.getItem("access_token")),
    }, () => {
      
      this.ilgiAlanlari();

      this.setState({
        stkId: this.state.stk.id
      }, () => {
        this.stkDetay();
      })

    })

  }



  stkDetay() {
    axios.get('https://api.sivildunyakocaeli.com/stklar/detay/' + this.state.stkId).then((item) => {

    // console.log("item",item);

      this.setState({
        kutukNo: item.data.data.kutukNo,
        kurumAdi: item.data.data.name,
        kisaAdi: item.data.data.sortName,
        email: item.data.data.email,
        ilce: item.data.data.ilce,
        seciliAlanlar: item.data.data.ilgiAlanlari,
        adres: item.data.data.adres,
        stkTur: item.data.data.stkType,
        baskanAdi: item.data.data.baskanAd,
        baskanSoyadi: item.data.data.baskanSoyad,
        baskanTelefon: item.data.data.baskanTelefon,
        bilgiDurum: item.data.data.bilgiDurum,
        baskanMesaj: item.data.data.baskanMesaj,
        baskanEmail: item.data.data.baskanEmail,
        baskanAdres: item.data.data.baskanAdres,
        eskiIcerik:item.data.data,
      }, () => {
        this.stkTurInputs();
      })


    });
  }



  ilgiAlanlari() {

    axios.get('https://api.sivildunyakocaeli.com/mobil/stklar/ilgiAlanlari').then((item) => {



      this.setState({
        ilgiAlanlari: item.data.data
      })

    });

  }


  stkTurInputs() {

    axios.get('https://api.sivildunyakocaeli.com/stklar/stkTurInputsUpdate/' + this.state.stkTur+"/"+this.state.stkId).then((item) => {



      this.setState({
        stkInputs: item.data.data
      })

    });

  }

  kayitguncelle() {

    this.setState({
      kontrol: true,
      loading: true,
    })

    if (this.state.kutukNo != "" && this.state.kurumAdi != "" && this.state.email != "" && this.state.baskanAdi != ""
      && this.state.baskanSoyadi != "" && this.state.baskanTelefon != "") {

      const formData = new FormData();
      formData.append("id", this.state.stkId);
      formData.append("kurumAdi", this.state.kurumAdi);
      formData.append("kisaAdi", this.state.kisaAdi);
      formData.append("email", this.state.email);
      formData.append("ilce", this.state.ilce);
      formData.append("adres", this.state.adres);
      formData.append("stkTur", this.state.stkTur);
      formData.append("baskanAdi", this.state.baskanAdi);
      formData.append("baskanSoyadi", this.state.baskanSoyadi);
      formData.append("baskanTelefon", this.state.baskanTelefon);
      formData.append("baskanMesaj", this.state.baskanMesaj);
      formData.append("bilgiDurum", this.state.bilgiDurum);
      formData.append("baskanEmail", this.state.baskanEmail);
      formData.append("baskanAdres", this.state.baskanAdres);
      formData.append("stkInputs", JSON.stringify(this.state.stkInputs));
      formData.append("ilgiAlanlari", JSON.stringify(this.state.seciliAlanlar));

      

      axios.post('https://api.sivildunyakocaeli.com/stklar/guncelleStk', formData, {
        headers: {
          "Accept": "*/*",
          "Authorization": "Bearer " + this.state.token,
          "x-requested-with": "XMLHttpRequest",
        },
      }).then((response) => {

        this.props.onaylandi();

        this.setState({
          loading:false
        })
        this.stkDetay();
       
    //     console.log("yeni", response.data);

      });



    } else {
      this.setState({

        loading: false,
      })
      window.scrollTo(0, 0);
    }

    // console.log(this.state.stkInputs);

  }

  inputGuncelle(id, value) {


    const inputlar = this.state.stkInputs;

    inputlar.forEach((item, index) => {

      if (inputlar[index].id == id) {
        inputlar[index].value = value;

      }
    });

    this.setState({
      stkInputs: inputlar
    })

  

  }


    render() {
        return (
       
       
          <div style={{}} >
                <div style={{backgroundColor:"#fff",borderRadius:5,padding:25}} >
          
                <Grid container columns={{ xs: 12, sm: 12, md: 12, }}>

<Grid item xs={12} sm={12} md={12} style={{ padding: 10, textAlign: "initial" }}  >
  <Typography variant="h6" className="font-medium" style={{ marginLeft: 10 }} >
    1. Stk Bilgileri
  </Typography>
</Grid>


<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

  <TextField
disabled
    className="mb-16"
    label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Kurum Adı</div>}
    style={{ width: "100%" }}
    error={(this.state.kontrol == true && this.state.kurumAdi == "") ? true : false}
    helperText={(this.state.kontrol == true && this.state.kurumAdi == "") ? "Bu alan boş geçilemez" : ""}
    value={this.state.kurumAdi}
    onChange={(e) => this.setState({ kurumAdi: e.target.value })}
    type="text"
    variant="outlined"
    InputProps={{}}
  />


</Grid>

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

  <TextField

    className="mb-16"
    
  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Kısa Adı</div>}
    style={{ width: "100%" }}
    value={this.state.kisaAdi}
    onChange={(e) => this.setState({ kisaAdi: e.target.value })}
    type="text"
    error={""}
    helperText={""}
    variant="outlined"
    InputProps={{}}
  />

</Grid>

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

  <TextField

    className="mb-16"
    
    label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >E-mail Adresi</div>}
    style={{ width: "100%" }}
    value={this.state.email}
    onChange={(e) => this.setState({ email: e.target.value })}
    type="email"
    error={(this.state.kontrol == true && this.state.email == "") ? true : false}
    helperText={(this.state.kontrol == true && this.state.email == "") ? "Bu alan boş geçilemez" : ""}
    variant="outlined"
    InputProps={{}}
  />


</Grid>

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >



  <Autocomplete
  id="combo-box-demo"
  options={[{ id: "BAŞİSKELE", name: "BAŞİSKELE" },
  { id: "ÇAYIROVA", name: "ÇAYIROVA" },
  { id: "DARICA", name: "DARICA" },
  { id: "DERİNCE", name: "DERİNCE" },
  { id: "DİLOVASI", name: "DİLOVASI" },
  { id: "GEBZE", name: "GEBZE" },
  { id: "GÖLCÜK", name: "GÖLCÜK" },
  { id: "İZMİT", name: "İZMİT" },
  { id: "KANDIRA", name: "KANDIRA" },
  { id: "KARAMÜRSEL", name: "KARAMÜRSEL" },
  { id: "KARTEPE", name: "KARTEPE" },
  { id: "KÖRFEZ", name: "KÖRFEZ" },
  ]}
disabled
  value={{id:this.state.ilce,name:this.state.ilce}}
  defaultValue={{ id: "BAŞİSKELE", name: "BAŞİSKELE" }}
  getOptionLabel={(option) => option.name}
  renderInput={(params) => <TextField {...params} 
  
  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >İlçe</div>} 
  variant="outlined" />}
 /* onChange={(event, value) => {
    if(value!=null && value!="") this.setState({ ilce: value.id }); else {}
  }} */
/>

</Grid>


<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >

  <TextField

disabled
    multiline
    
  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Adres</div>}

    placeholder="STK adresini giriniz."
    style={{ width: "100%" }}
    value={this.state.adres}
  //  onChange={(e) => this.setState({ adres: e.target.value })}
    rows={5}
    type="text"
    error={""}
    helperText={""}
    variant="outlined"
    InputProps={{}}
  />


</Grid>


<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >


<Autocomplete
multiple
id="tags-filled"
options={this.state.ilgiAlanlari}
getOptionLabel={(option) => option.title}
defaultValue={this.state.seciliAlanlar}
value={this.state.seciliAlanlar}
onChange={(e, seciliAlanlar) => { this.setState({ seciliAlanlar }) }}
filterSelectedOptions
renderInput={(params) => (
<TextField
  {...params}
  variant="outlined"

  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >İlgi Alanları</div>}
  placeholder="İlgi Alanları"
  
/>
)}
/>

</Grid>



<Grid item xs={12} sm={12} md={12} style={{ padding: 10, textAlign: "initial" }}  >
  <Typography variant="h6" className="font-medium" style={{ marginLeft: 10 }} >
    2. Başkan Bilgileri
  </Typography></Grid>

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

  <TextField
disabled
    className="mb-16"
    type="text"
    error={(this.state.kontrol == true && this.state.baskanAdi == "") ? true : false}
    helperText={(this.state.kontrol == true && this.state.baskanAdi == "") ? "Bu alan boş geçilemez" : ""}
    

  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Başkan Adı</div>}

    value={this.state.baskanAdi}
   // onChange={(e) => this.setState({ baskanAdi: e.target.value })}
    style={{ width: "100%" }}
    InputProps={{}}
    variant="outlined"
  />



</Grid>

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

  <TextField

disabled
    className="mb-16"
    

  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Başkan Soyadı</div>}
    value={this.state.baskanSoyadi}
  //  onChange={(e) => this.setState({ baskanSoyadi: e.target.value })}
    style={{ width: "100%" }}
    type="text"
    error={(this.state.kontrol == true && this.state.baskanSoyadi == "") ? true : false}
    helperText={(this.state.kontrol == true && this.state.baskanSoyadi == "") ? "Bu alan boş geçilemez" : ""}
    variant="outlined"
    InputProps={{}}

  />


</Grid>

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

  <InputMask
    mask="0(999) 999 99 99"
    value={this.state.baskanTelefon}
  //  onChange={(e) => this.setState({ baskanTelefon: e.target.value })}
  disabled
    maskChar=" "
  >
    {() => <TextField
disabled
      className="mb-16"

  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Başkan Telefon</div>}

      style={{ width: "100%" }}
      type="text"
      error={(this.state.kontrol == true && this.state.baskanTelefon == "") ? true : false}
      helperText={(this.state.kontrol == true && this.state.baskanTelefon == "") ? "Bu alan boş geçilemez" : ""}
      variant="outlined"
      InputProps={{}}
    />}
  </InputMask>


</Grid>

<Grid item xs={12} sm={6} md={6} style={{ padding: 10 }}  >

  <TextField

disabled
    className="mb-16"

  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Başkan E-mail Adresi</div>}
    value={this.state.baskanEmail}
  //  onChange={(e) => this.setState({ baskanEmail: e.target.value })}
    style={{ width: "100%" }}
    type="email"
    error={""}
    helperText={""}
    variant="outlined"
    InputProps={{}}
  />


</Grid>

<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >

  <TextField
disabled
    multiline

  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Başkan Adresi</div>}

    value={this.state.baskanAdres}
//    onChange={(e) => this.setState({ baskanAdres: e.target.value })}
    placeholder="Başkanın adresini giriniz."
    style={{ width: "100%" }}
    rows={5}
    type="text"
    error={""}
    helperText={""}
    variant="outlined"
    InputProps={{}}
  />


</Grid>


<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >

  <TextField


    multiline
    
    label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Başkan Mesajı</div>}
    placeholder="Başkan Mesajını giriniz."
    style={{ width: "100%" }}
    value={this.state.baskanMesaj}
    onChange={(e) => this.setState({ baskanMesaj: e.target.value })}
    rows={5}
    type="text"
    error={""}
    helperText={""}
    variant="outlined"
    InputProps={{}}
  />


</Grid>


<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >



  <Autocomplete
  id="combo-box-demo"
  options={[
    { id: 0, name: "Başkan Bilgileri Gizli" },
  { id: 1, name: "Başkan Bilgileri Aktif" },
  ]}

  value={{id:this.state.bilgiDurum,name:this.state.bilgiDurum==0 ? "Başkan Bilgileri Gizli" : "Başkan Bilgileri Aktif"}}
  defaultValue={{id:this.state.bilgiDurum,name:this.state.bilgiDurum==0 ? "Başkan Bilgileri Gizli" : "Başkan Bilgileri Aktif"}}
  getOptionLabel={(option) => option.name}
  renderInput={(params) => <TextField {...params} 
 
  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Başkan Bilgileri</div>}
  variant="outlined" />}
  onChange={(event, value) => {
    if(value!=null && value!="") this.setState({ bilgiDurum: value.id }); else {}
  }} 
/>

</Grid>



{(this.state.stkInputs.length > 0) &&
  <>
    <Grid item xs={12} sm={12} md={12} style={{ padding: 10, textAlign: "initial" }}  >
      <Typography variant="h6" className="font-medium" style={{ marginLeft: 10 }} >
        3. Stk Detay Bilgileri
      </Typography></Grid>

    {this.state.stkInputs && this.state.stkInputs.map((input) => {
      return (
        <Grid key={input.id}
          item xs={12} sm={input.col} md={input.col}
          style={{ padding: 10 }}  >


          {(input.inputType == 1 || input.inputType == 2 || input.inputType == 4) &&
            <TextField
            disabled
              className="mb-16"
              

  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >{input.title}</div>}
              style={{ width: "100%" }}
              type={input.inputType == 3 ? "email" : "text"}
              //     error={true}
              //    helperText={"deneme"}

              multiline={input.inputType == 2 ? true : false}
              rows={input.inputType == 2 ? 5 : 1}

              value={input.value}
         //     onChange={(e) => this.inputGuncelle(input.id, e.target.value)}
              variant="outlined"
              InputProps={{}}
            />}

          {(input.inputType == 3) &&

<Autocomplete
  id="combo-box-demo"
  options={input.options}
  getOptionLabel={(option) => option.value}
  disabled
  value={input.options.filter((e) => e.id==input.value )[0]}
  renderInput={(params) => <TextField {...params} 


  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >{input.title}</div>}
  variant="outlined" />}
 /* onChange={(event, value) => {
    
      if((value!=null && value!="")) this.inputGuncelle(input.id, value.id);  else {}
  }}*/
/>

            

          }

          {(input.inputType == 11) &&
            <InputMask
              mask="0(999) 999 99 99"
              value={input.value}
              onChange={(e) => this.inputGuncelle(input.id, e.target.value)}
              disabled={false}
              maskChar=" "
            >
              {() => <TextField

                className="mb-16"
                

  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >{input.title}</div>}
                style={{ width: "100%" }}
                type="text"
                error={""}
                helperText={""}
                variant="outlined"
                InputProps={{}}
              />}
            </InputMask>}



        </Grid>


      )
    })
    }

  </>
}

</Grid>

{this.state.loading == false ?
<div style={{width:"100%",textAlign:"right"}} >
<Button onClick={() => this.kayitguncelle()}  className=" z-index-1 
                        rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12
                        ,fontWeight:600,height:40,lineHeight:0,display:"inline",justifyContent:"center",
                        alignItems:"center",padding:15
                        }} >
                          Güncelle
                            </Button>
</div> :

<Spinner style={{marginRight:10}}  animation="border" variant="primary" />
}

</div>
          
          </div>


        )
    }
}


export default ProfilGuncelle;