import React, { Component, Fragment } from "react";


import Profiledetail from '../../components/Profiledetail';

import Events from '../../components/Events';
import Postview from '../../components/Postview';
import Load from '../../components/Load';
import axios from "axios";
import Createpost from "../../components/Createpost";
import { Spinner } from "react-bootstrap";

class Haberler extends Component {

    state = {
        id:0,
        aktifMenu: 1,
        page:1,
        haberler:[],
        loading:false,
        token:"",
    }


    componentDidMount () {

        this.setState({
            id:this.props?.stkid,
            token:this.props?.token
        },() => {

        this.stkhaberler(this.props?.stkid);
        this.onaybekleyenhaberlerim(this.props?.stkid);
        })

    }
    

    onaybekleyenhaberlerim = async (id) => {

        const formData = new FormData();
      formData.append("stkId", id);
      formData.append("page", 1);

        await axios.post("https://api.sivildunyakocaeli.com/mobil/haberler/onaybekleyenler" , formData, {
            headers: {
                "Accept": "*/*",
                "Authorization": "Bearer " + this.state.token,
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
                if(response.data.success==true) {
                this.setState({
                    onayHaberler: response.data.data,
                })
            }

            });   

    }


    stkhaberler = async (id) => {

        this.setState({
            loading:true,
        })
        

        await axios.get("https://api.sivildunyakocaeli.com/mobil/haberler/stk_haberler/"+id+"/"+this.state.page, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
            //     console.log("haberler",response.data.data);
                this.setState({
                    haberler: response.data.data,
                    loading:false,
                })

            });

    }


    haberdevam = async (id) => {

        this.setState({
            loading:true,
        })
        

        await axios.get("https://api.sivildunyakocaeli.com/mobil/haberler/stk_haberler/"+this.state.id+"/"+this.state.page, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
                this.setState({
                    haberler: [...this.state.haberler,...response.data.data],
                    loading:false,
                })

            });

    }

    yenile() {
        
        this.onaybekleyenhaberlerim(this.state.id);

    }

    

    render() {
        return (
            <>
            
                <div className="col-xl-12 col-xxl-12 col-lg-12">

                <>

                <Createpost history={this.props.history} yenile={() => this.yenile()}   />
          

                { (this.state.onayHaberler?.length<=0 && this.state.haberler?.length<=0) ?
                        <div style={{ width: "100%", textAlign: "center", marginTop: 20 }} >
                            <img src='/assets/images/haberYok.svg' style={{ height: 90 }} />

                            <p style={{ color: "#aaa", marginTop: 10, fontWeight: 500, fontSize: 17 }} >
                                Haber Bulunamadı</p>

                        </div> : null }

                        

                {this.state?.onayHaberler && this.state?.onayHaberler.map((item) => {
                                        return (
                                            <Postview
                                            veriyenile={() => { this.onaybekleyenhaberlerim(this.state.id); }}
                                            bekleyen={true}
                                                key={item.id}
                                                id={item.id}
                                                resimler={item.resimler}
                                                stk={item.stk}
                                                time={item.time}
                                                des={item.description}
                                                url={item.url}
                                            />
                                        )
                                    })}


             
            {this.state?.haberler && this.state?.haberler.map((item) => {
                                        return (
                                            <Postview
                                            veriyenile={() => { this.stkhaberler(this.state?.id); }}
                                                key={item.id}
                                                id={item.id}
                                                resimler={item.resimler}
                                                stk={item.stk}
                                                time={item.time}
                                                des={item.description}
                                                url={item.url}
                                            />
                                        )
                                    })}
                                    
                                    {this.state.haberler.length>=10 &&
                                    <>
                                    {this.state.loading==false ? 
                                    
                                    <div style={{ width: "100%", textAlign: "-webkit-center" }} >
                                    <div
                                        onClick={() => this.setState({ page: this.state.page + 1 }, () => { this.haberdevam() })}
                                        style={{
                                            cursor: "pointer", color: "#404040",textAlign:"center",
                                            textDecorationLine:"underline",
                                             padding: 5, width: 100
                                        }} >daha fazla</div>
                                </div>

                                    

                                    :
                                    <div style={{width:"100%",textAlign:"center"}} >
                                    <Spinner animation="border" variant="primary" />
                                        </div>
                                     }
                                      </>
                                    }


            </>
            
                </div></>

        )
    }
}


export default Haberler;