import axios from 'axios';
import React,{Component} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';




class Profilephoto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            isOpen: false,
            seciliresim:"",
            loading:false,
            resimler:[],
            id:0,
        };
    }

    stk_resimler = async (id) => {

        this.setState({
            loading:true,
        })
        
        await axios.get("https://api.sivildunyakocaeli.com/mobil/haberler/stk_resimler/"+id, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
           //      console.log("resimler",response.data);
                this.setState({
                    resimler: response.data.data,
                    loading:false,
                })

            });

    }

    componentDidMount() {

        
        this.setState({
            id:this?.props?.stkid
        },() => {
            this.stk_resimler(this.state.id);
        })



    }

    

    render() {
        const { tab1, isOpen } = this.state;
        var i = 0;
        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-flex align-items-center  p-4">
                    <h4 className="fw-700 mb-0 font-xssss text-grey-900">Galeri</h4>
                    
                </div>
                <div className="card-body d-block pt-0 pb-2">

                { ( this.state.resimler?.length<=0) ?
                        <div style={{ width: "100%", textAlign: "center", marginTop: 20 }} >
                            <img src='/assets/images/talebinizYok.svg' style={{ height: 70 }} />

                            <p style={{ color: "#aaa", marginTop: 10, fontWeight: 500, fontSize: 15 }} >
                                Resim Bulunamadı</p>

                        </div> : null }

                    <div className="row ps-3 pe-3">
                    {  this.state?.resimler && this.state?.resimler.map((item , index) =>  { 
                            i++; if(i>8)return;
                            return (
                            <div className="col-6 mb-1 p-1" key={index}>
                               
                                <div onClick={() => this.setState({ isOpen: true,seciliresim:index })}>
                                    <a href="#portfolio-details">
                                        <img src={"https://api.sivildunyakocaeli.com/"+item?.url} alt="Portfolio"
                                        style={{height:100,objectFit:"cover"}}
                                         className="img-fluid rounded-3 w-100"/>
                                    </a>
                        
                                </div>
                            
                            </div>
                            

                        )})}
 
 

{isOpen && (
<Lightbox

mainSrc={'https://api.sivildunyakocaeli.com/'+this.state?.resimler[this.state.seciliresim].url}
nextSrc={'https://api.sivildunyakocaeli.com/'+this.state?.resimler[(this.state.seciliresim + 1) % this.state?.resimler.length].url}
prevSrc={'https://api.sivildunyakocaeli.com/'+this.state?.resimler[(this.state.seciliresim + this.state?.resimler.length - 1) % this.state?.resimler.length].url}

onMovePrevRequest={() =>
  this.setState({
    seciliresim: (this.state.seciliresim + this.state?.resimler.length - 1) % this.state?.resimler.length,
  })
} 
onMoveNextRequest={() =>
  this.setState({
    seciliresim: (this.state.seciliresim + 1) % this.state?.resimler.length,
  })
} 

onCloseRequest={() => this.setState({ isOpen: false })}
                            
                                    />   )}


                    </div>
                </div>
               
               
            </div>
        );
    }
}

export default Profilephoto;