import axios from "axios";
import { format } from "date-fns";
import React, { Component, Fragment } from "react";
import iziToast from 'izitoast';
import RezervasyonEkle from "./RezervasyonEkle";

import { Button, Grid } from '@material-ui/core';
import { Link } from "react-router-dom";


class Rezervasyonlar extends Component {

    state = {
        id: 0,
        page: 1,
        rezervasyonlar: [],
        loading: false,
        token: "",
        stk: [],

        durum:false,
    }

    onaylandi() {
        
        iziToast.show({
            theme:"light",
            color:"green",
            title: 'Tebrikler...',
            message: 'Rezervasyonunuz Onay İçin Gönderildi.'
        });

        this.setState({
            durum:false,
        })


        this.rezervasyonlar(this.state?.id);


    }

    componentDidMount() {

        if(this.props?.direktSayfa==true) {
            this.setState({
                durum:true
            })
        }

        this.setState({
            id: this.props?.stkid,
            token: this.props?.token,
            stk: this.props?.stk,
        }, () => {

            this.rezervasyonlar(this.props?.stkid);
        })

    }

    rezervasyonlar = async (id) => {

        this.setState({
            loading: true,
        })

        const formData = new FormData();
        formData.append("stkId", id);
        formData.append("page", 1);
        formData.append("max", 1000);
        await axios.post("https://api.sivildunyakocaeli.com/mobil/stklar/rezervasyonlarim/", formData, {
            headers: {
                "Accept": "*/*",
                "Authorization": "Bearer " + this.state.token,
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
               //  console.log("rezervasyonlar", response.data.data);
                this.setState({
                    rezervasyonlar: response.data.data,
                    loading: false,
                })

            });

    }

    render() {
        return (
            <div className="col-xl-12 col-xxl-12 col-lg-12">

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/izitoast/1.4.0/css/iziToast.css"/>

                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                    <div className="card-body d-flex align-items-center p-0">
                        <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900">Salon Rezervasyonu</h2>
                        <div className="search-form-2 ms-auto">

                        </div>
                  
                        {this.state.durum==false ?
                        <Button onClick={() => this.setState({durum:true})}  className=" z-index-1 
                        rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12
                        ,fontWeight:600,height:40,lineHeight:0,display:"flex",justifyContent:"center",
                        alignItems:"center",padding:15
                        }} >
                            Yeni Rezervasyon</Button>
                        
                            : 
                            <Button onClick={() => this.setState({durum:false})}  className=" z-index-1 
                        rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12
                        ,fontWeight:600,height:40,lineHeight:0,display:"flex",justifyContent:"center",
                        alignItems:"center",padding:15
                        }} >
                            Kapat</Button>
                             }
                    
                    </div>
                </div>


{this.state.durum == false  ?
                <div className="row ps-2 pe-1">


{this.state.rezervasyonlar.length <=0 ?

<div style={{ width: "100%", textAlign: "center", marginTop: 20 }} >
            <img src='/assets/images/talebinizYok.svg' style={{ height: 90 }} />

            <p style={{ color: "#aaa", marginTop: 10, fontWeight: 500, fontSize: 17 }} >
                Rezervasyon Bulunamadı</p>

        </div> : null }


                {this.state.rezervasyonlar.map((item) => {
    return (
                    <div key={item.id} className="col-md-4 col-sm-6 pe-2 ps-2" style={{textAlign:"left"}} >
                        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3" style={{height:400}} >
                            <div className="card-body d-block w-100 p-4" style={{textAlign:"left"}} >
                                
                                <div className="clearfix"></div>
                                <h4 className="fw-700 font-xss mt-3 mb-0">{item?.oda?.name} ({item?.oda?.count} Kişilik) </h4>
                              
                                <hr />
                                <ul className="d-flex align-items-center justify-content-left mt-1" style={{marginLeft:-10,marginBottom:10}} >
                                    <li className="m-2"><h4 className="fw-700" style={{fontSize:18}} >
                                    <i className="feather-clock" style={{marginRight:5,fontSize:17}} ></i>
                                    {format(new Date(item?.date),'dd.MM.yyyy')} </h4></li>
                                    <li className="m-2"><h4 className="fw-700" style={{marginRight:5,fontSize:17}} >
                                    <i className="feather-user" style={{marginRight:5,fontSize:17}} ></i>
                                    {item.katilimci} Kişi
                                    </h4></li>


                                </ul>


<div  >

<Grid container columns={{ xs: 6, sm: 12, md: 4, }} >
                                {item.saatler.map((saat) => {
    return (
        <Grid item xs={6} sm={6} md={4}  style={{marginBottom:10}}  >
                                    <div style={{padding:8,backgroundColor:"#bbb",color:"#fff",fontSize:11,
                                    marginRight:5,borderRadius:5}} 
                                     key={saat.id} >{saat.name}</div >
                                     </Grid>
    )})}
    </Grid>
    </div>
    
                                <hr style={{marginTop:25}} />
                                <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span
                                    className="text-grey-900 font-xssss">Randevu Hamili : </span> {item?.hamil?.hamilName}</h5>

{item.status == 0 ?
                                
                                <div style={{width:100,borderRadius:10,height:30,lineHeight:0,display:"inline-block",padding:10,
                                marginTop:20,paddingTop:15,textAlign:"center",
                                justifyContent:"center",alignItems:"center"
                             }}
                                className="mt-4 btn-warning 
                               font-xsssss fw-700 ls-lg text-white"
                               
                               >Onay Bekliyor</div>


                           :
                           
                           <div style={{width:100,borderRadius:10,height:30,lineHeight:0,display:"inline-block",padding:10,
                           marginTop:20,paddingTop:15,textAlign:"center",
                           justifyContent:"center",alignItems:"center"
                        }}
                           className="mt-4 btn-success 
                          font-xsssss fw-700 ls-lg text-white"
                          
                          >Onaylandı</div>
                     
}
                           
                            </div>
                        </div>
                    </div>

            
)
})}


                </div>: 
                
                
                <RezervasyonEkle onaylandi={() => this.onaylandi() } />
             
                
                }

            



            </div>

        )
    }
}


export default Rezervasyonlar;