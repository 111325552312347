import React, { Component, Fragment } from "react";
import Header from '../../components/Header';

import Appfooter from '../../components/Appfooter';


import Load from '../../components/Load';
import Kesfet from "./Kesfet";
import Haberler from "./Haberler";
import Etkinlikler from "./Etkinlikler";
import axios from "axios";
import { Spinner } from "react-bootstrap";

class StkProfil extends Component {

    constructor() {
        super();
        this.state = {
            id:0,
            aktifMenu:1,
            page: 1,
            haberler: [],
            etkinlikler:[],
            stk:[],
            loading:false,
            rand:parseInt(0 + (Math.random() * (6) ) ),

            banners:["https://api.sivildunyakocaeli.com/uploads/banner/kocaeli1.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli2.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli3.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli4.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli5.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli6.jpg",],
        }
    }

    componentDidMount() {
        
        let data = this.props.match.params;
        this.setState({id:data.stkid},() => {
            
        this.stkdetay(data.stkid);
        })
    }

    
    stkdetay = async (id) => {

        this.setState({
            loading:true
        })

        await axios.get("https://api.sivildunyakocaeli.com/mobil/stklar/detayurl/"+id, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
             //    console.log("stk",response.data.data);
                this.setState({
                    etkinlikler: response.data.data?.etkinlikler,
                    haberler: response.data.data?.haberler,
                    stk: response.data.data,
                    loading:false,
                })

            });

    }

    render() {
        return (
            <Fragment>
                <Header />


                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            {this.state.loading==false ?
                            <div className="row">
                                <div className="col-xl-12 mb-3">

                                    <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                                        <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3"
                                        style={{position:"relative"}} ><img 
                                        style={{width:"100%",objectFit:"cover",maxHeight:275}}
                                        src={
                                            this.state?.stk.coverImage!= "" ? "https://api.sivildunyakocaeli.com/"+this.state?.stk?.coverImage : this.state.banners[this.state.rand]
                                        } alt="avater" />
                                        <div style={{position:"absolute",right:10,bottom:10,backgroundColor:"#88888899",color:"#ddd",
                                    fontSize:11,padding:5,borderRadius:5
                                    }} >
                                        Kapak görselleri otomatik olarak çıkmaktadır.
                                        </div>
                                        </div>
                                        <div className="card-body p-0 position-relative">
                                            <figure className="avatar position-absolute w100 z-index-1" style={{ top: '-40px', left: '30px' }}>
                                                <img src={"https://api.sivildunyakocaeli.com/"+this.state?.stk?.profileImage} alt="avater" className="float-right p-1 bg-white rounded-circle w-100" /></figure>
                                            <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">{this.state?.stk.name} 
                                            <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">{this.state?.stk.baskanEmail}</span></h4>
                                           
                                        

                                        </div>

                                        <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                                            <ul className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4" id="pills-tab" role="tablist">
                                                <li style={{ cursor: "pointer" }} onClick={() => this.setState({ aktifMenu: 1 })} className={this.state.aktifMenu == 1 ? "active list-inline-item me-5" : "list-inline-item me-5"}>
                                                    <a className={"fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block " + (this.state.aktifMenu == 1 ? "active" : "")} >Keşfet</a></li>
                                                <li style={{ cursor: "pointer" }} onClick={() => this.setState({ aktifMenu: 2 })} className={this.state.aktifMenu == 2 ? "active list-inline-item me-5" : "list-inline-item me-5"}>
                                                    <a className={"fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block " + (this.state.aktifMenu == 2 ? "active" : "")} >Haberler</a></li>
                                                <li style={{ cursor: "pointer" }} onClick={() => this.setState({ aktifMenu: 3 })} className={this.state.aktifMenu == 3 ? "active list-inline-item me-5" : "list-inline-item me-5"}>
                                                    <a className={"fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block " + (this.state.aktifMenu == 3 ? "active" : "")} >Etkinlikler</a></li>
                                                   
                                                   
                                            </ul>
                                        </div>
                                    </div>

                                </div>
{this.state.loading==false ?
<>
                                {this.state.aktifMenu == 1 &&
                                    <Kesfet 
                                    stkid={this.state.stk?.id}
                                    etkinlikler={this.state.etkinlikler}
                                    stk={this.state.stk}
                                     />
                                }

                                {this.state.aktifMenu == 2 &&
                                    <Haberler
                                    stkid={this.state.stk?.id}
                                    stk={this.state.stk}
                                     />
                                }

{this.state.aktifMenu == 3 &&
                                    <Etkinlikler
                                    stkid={this.state.stk?.id}
                                    stk={this.state.stk}
                                    etkinlikler={this.state.etkinlikler}
                                      />
                                }


                                
                                </> : 
                                <div style={{width:"100%",textAlign:"center"}} >
                                <Spinner animation="border" variant="primary" />
                                    </div>
                                     }


                            </div> :
                            <div className="card w-100 border-0  bg-white shadow-xss rounded-xxl" style={{padding:15}} >
                            <div style={{width:"100%",textAlign:"center"}} >
                            <Spinner animation="border" variant="primary" />
                            </div>
                            </div>
    }
                        </div>
                    </div>
                </div>


                <Appfooter />

            </Fragment>
        );
    }
}

export default StkProfil;