import React, { Component, Fragment } from "react";


import Profiledetail from '../../components/Profiledetail';
import Createpost from '../../components/Createpost';
import Events from '../../components/Events';
import Postview from '../../components/Postview';
import Load from '../../components/Load';


const eventList = [
    {
        imageUrl: 'hotel.png',
        title: 'Malatya tanıtım günlerinde buluşuyoruz ',
        location: 'Uluslararası kongre merkezi',
        date: '22',
        month: 'May',
    },
   
]
class Etkinlikler extends Component {

    state = {
        aktifMenu: 1,
    }

    render() {
        return (
            <div className="col-xl-12 col-xxl-12 col-lg-12">
                                      
<div className="row" style={{padding:10}} >

                                      { (this.props?.etkinlikler?.length<=0) ?
                        <div style={{ width: "100%", textAlign: "center", marginTop: 20 }} >
                            <img src='/assets/images/etkinlikYok.svg' style={{ height: 90 }} />

                            <p style={{ color: "#aaa", marginTop: 10, fontWeight: 500, fontSize: 17 }} >
                                Etkinlik Bulunamadı</p>

                        </div> : null }


                                      {this.props?.etkinlikler && this.props?.etkinlikler.map((value , index) => (
                                          
                                <div  className="col-lg-6 col-md-6 pe-2 ps-2">
                                    <a key={index} href={"/etkinlikdetay/"+value.url}>
                                    <div className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                                        <div className="card-image w-100">
                                            <img 
                                            style={{height:300,objectFit:"cover"}}
                                            src={"https://api.sivildunyakocaeli.com/"+value.coverImage}    alt="event" className="w-100 rounded-3" />
                                        </div>
                                        <div className="card-body d-flex ps-0 pe-0 pb-0">
                                            <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg"><h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0"><span className="ls-3 d-block font-xsss text-grey-500 fw-500">{value.ay}</span>{value.gun}</h4></div>
                                            <h2 className="fw-700 lh-3 font-xss">{value.title}
                                                <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500"> <i className="ti-location-pin me-1"></i>{value.location} </span>
                                            </h2>
                                        </div>
                                        
                                    </div></a>
                                </div>
                                ))}
                                      </div>
                                    </div>

        )
    }
}


export default Etkinlikler;