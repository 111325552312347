import React,{Component} from 'react';




const renkler = ["bg-warning","bg-danger","bg-success"]

class Events extends Component {
    render() {
        return (
         
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-flex align-items-center p-4">
                    <h4 className="fw-700 mb-0 font-xssss text-grey-900">STK Takvimi</h4>
                    <a href="/stk-takvim" className="fw-600 ms-auto font-xssss text-primary">Tümü</a>
                </div>
                {this.props?.etkinlikler && this.props.etkinlikler.map((value , index) => (
                      <a key={index} href={"/etkinlikdetay/"+value.url}>
                <div   className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
                    <div style={{width:65,height:65,marginTop:8,padding:15,paddingTop:16,textAlign:"center"}} className={`bg-success me-2  rounded-xxl ${renkler[index%3]}`}>
                        <h4 className="fw-700  ls-3 lh-1 text-white mb-0" style={{fontSize:24,marginTop:-3}} >
                        <span className="ls-1 d-block font-xsss text-white fw-600">{value.ayf}</span>{value.gunf}</h4></div>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-2">{value.title} <span className="d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500">
                    {value.description.length > 75 ?
    `${value.description.substring(0, 75)}...` : value.description
  }
  </span> </h4>
                </div>
                </a>
                ))}

                {this.props?.etkinlikler.length<=0 &&
<div style={{width:"100%",textAlign:"center"}} >
                <img src='/assets/images/etkinlikYok.svg' style={{height:70}} />

                <p style={{color:"#aaa",marginTop:10,fontWeight:500}} >Etkinlik Bulunamadı</p>

                </div>

    }

                
            </div>
        );
    }
}

export default Events;