import React, { Component, Fragment } from "react";
import Header from '../../components/Header';
import Rightchat from '../../components/Rightchat';
import Appfooter from '../../components/Appfooter';
import Popupchat from '../../components/Popupchat';

import Profiledetail from '../../components/Profiledetail';
import ProfilecardThree from '../../components/ProfilecardThree';
import Createpost from '../../components/Createpost';
import Events from '../../components/Events';
import Postview from '../../components/Postview';
import Load from '../../components/Load';
import Kesfet from "./Kesfet";
import Haberler from "./Haberler";
import Etkinlikler from "./Etkinlikler";
import Talepler from "./Talepler";
import Rezervasyonlar from "./Rezervasyonlar";
import axios from "axios";

import Dropzone from 'react-dropzone';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";


class StkProfil extends Component {

    constructor() {
        super();
        this.onDrop = (files) => {
          this.setState({files},() => {this.resimDuzenle()})
        };
        this.state = {
            id:0,
            aktifMenu:1,
            page: 1,
            haberler: [],
            etkinlikler:[],
            stk:[],
            loading:false,

            resimekle:false,

            token:"",
            stklocal:[],
            user:[],
            rand:parseInt(0 + (Math.random() * (6) ) ),

            direktSayfa:false,

            files:[],
            filesBase64:[],

            resimLoad:false,
            kontrol:false,


            banners:["https://api.sivildunyakocaeli.com/uploads/banner/kocaeli1.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli2.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli3.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli4.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli5.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli6.jpg",],

        }
    }




  resimDuzenle() {

    var galery = [];
    this.state.files.forEach(file => {

      let idCardBase64 = '';
      this.getBase64(file, (result) => {
           idCardBase64 = result;
           galery=[...galery,result]

           this.setState({
            filesBase64:galery
           })
           
      });
      
    });

  }


  resmikaydet = async () =>  {

    if(this.state.filesBase64.length>0) {
        this.setState({
            resimLoad:true,
        })

            const formData = new FormData();
          formData.append("stkId", this.state.stk?.id);
          formData.append("resim", this.state.filesBase64[0]);
    
            await axios.post("https://api.sivildunyakocaeli.com/mobil/stklar/resimguncelle" , formData, {
                headers: {
                    "Accept": "*/*",
                    "Authorization": "Bearer " + this.state.token,
                    "x-requested-with": "XMLHttpRequest",
    
                },
            })
                .then((response) => {

                    this.setState({
                        resimLoad:false,
                        resimekle:false,
                        filesBase64:[],
                        files:[],
                        
                    },() => {
                        this.stkdetay(this.state.id);

                        const formData = new FormData();
                        formData.append("stkId", this.state.stk.id);
                
                
                         axios.post("https://api.sivildunyakocaeli.com/mobil/stklar/stkCek", formData, {
                            headers: {
                                "Accept": "*/*",
                                "x-requested-with": "XMLHttpRequest",
                            },
                        })
                            .then((response) => {
        
                                this.setState({
                                    stk:response.data.data
                                },() => {
        
                                    localStorage.setItem("stk", JSON.stringify(this.state.stk));
                                    
    window.location.reload(false);
                                })
            
                            });
                        
                    })
             //        console.log("res123",response.data);

               
    
                });   
    
        


    }else {

        this.setState({
            resimLoad:false,
            kontrol:true,
        })

    }

  }


  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
      //   console.log('Error: ', error);
    };
}

    componentDidMount() {

        
        
        let data = this.props.match.params;
        if(data?.islemId=="rezervasyonlar") {
            this.setState({
       aktifMenu:5,
       direktSayfa:false        
            })
        }

        if(data?.islemId=="talepler") {
            this.setState({
       aktifMenu:4,
       direktSayfa:false        
            })
        }

        if(data?.islemId=="rezervasyon-ekle") {
            this.setState({
       aktifMenu:5,
       direktSayfa:true        
            })
        }

        if(data?.islemId=="talep-ekle") {
            this.setState({
       aktifMenu:4,
       direktSayfa:true        
            })
        }

        if(data?.islemId=="etkinlik-ekle") {
            this.setState({
       aktifMenu:3,
       direktSayfa:true        
            })
        }



        if (localStorage.getItem("user")!=null && localStorage.getItem("user")!=""  ) {
            this.setState({
                stklocal: JSON.parse(localStorage.getItem("stk")),
                user: JSON.parse(localStorage.getItem("user")),
                token: JSON.parse(localStorage.getItem("access_token")),
            },() => {

                this.setState({
                    id:this.state.stklocal.id
                })

                this.stkdetay(this.state.stklocal.id);

            })
        } else {
            window.location.href="/";
        }
    
    }

    
    stkdetay = async (id) => {

        this.setState({
            loading:true
        })

        await axios.get("https://api.sivildunyakocaeli.com/mobil/stklar/detay/"+id, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
              //   console.log("stk",response.data.data);
                this.setState({
                    etkinlikler: response.data.data?.etkinlikler,
                    haberler: response.data.data?.haberler,
                    stk: response.data.data,
                    loading:false,
                })

            });

    }

    render() {
        return (
            <Fragment>
                <Header />


                <Dialog open={this.state.resimekle} onClose={() => this.setState({resimekle:false})} 
                aria-labelledby="form-dialog-title" fullWidth   
                maxWidth="xs" >
                


                <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }} style={{textAlign:"center"}} >

               

                        <p style={{fontSize:17}} >
                        Lütfen Yeni Profil Resminizi Seçiniz
                        </p>

                        <Dropzone  multiple={false} onDrop={this.onDrop} accept={[".png",".jpg",".jpeg"]} >
        {({getRootProps, getInputProps}) => (
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})} 
            style={{ borderColor: this.state.files.length > 0 ? "green" : 
            (this.state.filesBase64.length<=0 && this.state.kontrol==false) ? "" : "red" }} >
               
              <input {...getInputProps()} />
              {(this.state.files.length==0) ?
              <p style={{color:"#aaa"}} >Eklemek istediğiniz resimleri sürükleyip bırakabilirsiniz.</p>
              :

              <p style={{color:"green"}} >{this.state.files.length} adet resim seçimi yaptınız.</p>
  }
            </div>
          </section>
        )}
      </Dropzone>
      {(this.state.resimLoad==false) ?
     <button onClick={() => this.resmikaydet() } className="btn btn-primary" style={{marginTop:20,marginBottom:20}}  > Kaydet
         </button> : <Spinner style={{marginRight:10,margin:20}}  animation="border" variant="primary" />  }
                        
                    </DialogContent>

                    </Dialog>


                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            {this.state.loading==false ?
                            <div className="row">
                                <div className="col-xl-12 mb-3">

                                    <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                                        <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3" 
                                        style={{position:"relative"}}
                                          ><img 
                                        style={{width:"100%",objectFit:"cover",maxHeight:275,}}
                                        src={
                                            this.state?.stk.coverImage!= "" ? "https://api.sivildunyakocaeli.com/"+this.state?.stk?.coverImage : this.state.banners[this.state.rand]
                                        } alt="avater" />
                                        
                                        <div style={{position:"absolute",right:10,bottom:10,backgroundColor:"#88888899",color:"#ddd",
                                    fontSize:11,padding:5,borderRadius:5
                                    }} >
                                        Kapak görselleri otomatik olarak çıkmaktadır.
                                        </div>
                                       
                                        

                                        </div>
                                        <div className="card-body p-0 position-relative" >
                                            <figure className="avatar position-absolute w100 z-index-1" style={{ top: '-60px', left: '30px',
                                            borderWidth:2,borderColor:"#aaa",borderStyle:"solid",borderRadius:400 }}>
                                                <img src={"https://api.sivildunyakocaeli.com/"+this.state?.stk?.profileImage} alt="avater"  
                                                style={{objectFit:"cover",height:100,width:100}}
                                                className="float-right bg-white rounded-circle w-100" />

                                                 <i  onClick={() => this.setState({resimekle:true})  }  style={{borderWidth:2,borderStyle:"solid",borderColor:"#aaa",cursor:"pointer",
                                                 position:"absolute",right:-5,bottom:-5,fontSize:18,width:40,height:40,borderRadius:400,
                                                 display:"flex",justifyContent:"center",alignItems:"center"
                                                }}
                                        className="feather-edit  bg-greylight  theme-dark-bg text-grey-500 "></i>
                                        </figure>
                                            <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15 marginyok" style={{marginRight:100}} >{this.state?.stk.name} 
                                            <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">{this.state?.stk.baskanEmail}</span></h4>
                                           
                                          
                                            <div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2">
                                                <Button component={Link} to="/profil-duzenle" className=" z-index-1 
                                                rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12
                                                ,fontWeight:600,height:40,lineHeight:0,display:"flex",justifyContent:"center",
                                                alignItems:"center",padding:15
                                                }} >
                                                    Profil Düzenle</Button>

                                            </div>

                                        </div>

                                        <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                                            <ul className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4" id="pills-tab" role="tablist">
                                                <li style={{ cursor: "pointer" }} onClick={() => this.setState({ aktifMenu: 1 })} className={this.state.aktifMenu == 1 ? "active list-inline-item me-5" : "list-inline-item me-5"}>
                                                    <a className={"fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block " + (this.state.aktifMenu == 1 ? "active" : "")} >Keşfet</a></li>
                                                <li style={{ cursor: "pointer" }} onClick={() => this.setState({ aktifMenu: 2 })} className={this.state.aktifMenu == 2 ? "active list-inline-item me-5" : "list-inline-item me-5"}>
                                                    <a className={"fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block " + (this.state.aktifMenu == 2 ? "active" : "")} >Haberler</a></li>
                                                <li style={{ cursor: "pointer" }} onClick={() => this.setState({ aktifMenu: 3 })} className={this.state.aktifMenu == 3 ? "active list-inline-item me-5" : "list-inline-item me-5"}>
                                                    <a className={"fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block " + (this.state.aktifMenu == 3 ? "active" : "")} >Etkinlikler</a></li>
                                                   
                                                     <li style={{ cursor: "pointer" }} onClick={() => this.setState({ aktifMenu: 4 })} className={this.state.aktifMenu == 4 ? "active list-inline-item me-5" : "list-inline-item me-5"}>
                                                    <a className={"fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block " + (this.state.aktifMenu == 4 ? "active" : "")} >Talepler</a></li>
                                               <li style={{ cursor: "pointer" }} onClick={() => this.setState({ aktifMenu: 5 })} className={this.state.aktifMenu == 5 ? "active list-inline-item me-5" : "list-inline-item me-5"}>
                                                    <a className={"fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block " + (this.state.aktifMenu == 5 ? "active" : "")} >Salon Rezervasyon</a></li>
                                                    
                                            </ul>
                                        </div>
                                    </div>

                                </div>
{this.state.loading==false ?
<>
                                {this.state.aktifMenu == 1 &&
                                    <Kesfet 
                                    stkid={this.state.id}
                                    etkinlikler={this.state.etkinlikler}
                                    stk={this.state.stk}
                                    token={this.state.token}
                                     />
                                }

                                {this.state.aktifMenu == 2 &&
                                    <Haberler
                                    stkid={this.state.id}
                                    stk={this.state.stk}
                                    token={this.state.token}
                                     />
                                }

{this.state.aktifMenu == 3 &&
                                    <Etkinlikler
                                    stkid={this.state.id}
                                    stk={this.state.stk}
                                    etkinlikler={this.state.etkinlikler}
                                    token={this.state.token}
                                    direktSayfa={this.state.direktSayfa}
                                      />
                                }

{this.state.aktifMenu == 4 &&
                                    <Talepler
                                    stkid={this.state.id}
                                    stk={this.state.stk}
                                    token={this.state.token}
                                    direktSayfa={this.state.direktSayfa}
                                     />
                                }

{this.state.aktifMenu == 5 &&
                                    <Rezervasyonlar
                                    stkid={this.state.id}
                                    stk={this.state.stk}
                                    token={this.state.token}

                                    direktSayfa={this.state.direktSayfa}
                                     />
                                }
                                
                                
                                </> : 
                                
                                <div style={{width:"100%",textAlign:"center"}} >
<Spinner animation="border" variant="primary" />
    </div>

                                 }


                            </div> : 
                            <div className="card w-100 border-0  bg-white shadow-xss rounded-xxl" style={{padding:15}} >
                            <div style={{width:"100%",textAlign:"center"}} >
                            <Spinner animation="border" variant="primary" />
                            </div>
                            </div>
                             }
                        </div>
                    </div>
                </div>


                <Appfooter />

            </Fragment>
        );
    }
}

export default StkProfil;