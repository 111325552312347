import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import duyuru from '../assets/images/duyuru.gif';

import basinkiti from '../assets/basinkiti.zip';

import { Popup } from 'semantic-ui-react'

import { Search, } from 'semantic-ui-react'
import { Spinner } from 'react-bootstrap';
import axios from 'axios';

import { Button } from '@material-ui/core';

class Header extends Component {
    state = {
        isOpen: false,
        isActive: false,
        isNoti: false,
        stk: [],
        stklar: [],
        searchloader: false,
        url: "/",
        token:"",
        cihazTuru:'',
        bannerGoster: true,

    };

    componentDidMount() {

        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          this.setState({ cihazTuru: 'iOS' }); 

        } else if (/android/i.test(userAgent)) {
          this.setState({ cihazTuru: 'Android' }); 
        }

        let data = this.props.match;

        this.setState({
            url: data?.path,
        })



        if (localStorage.getItem("user") != null && localStorage.getItem("user") != "") {
          
         //    console.log(JSON.parse(localStorage.getItem("stk")));

            this.setState({
                stk: JSON.parse(localStorage.getItem("stk")),
                token: JSON.parse(localStorage.getItem("access_token")),
                stklar: JSON.parse(localStorage.getItem("stklar"))
            },() => {

                const formData = new FormData();
                formData.append("stkId", this.state?.stk?.id);
        
        
                 axios.post("https://api.sivildunyakocaeli.com/mobil/stklar/stkCek", formData, {
                    headers: {
                        "Accept": "*/*",
                        "x-requested-with": "XMLHttpRequest",
                    },
                })
                    .then((response) => {

                        this.setState({
                     //       stk:response.data.data
                        },() => {

                     //       localStorage.setItem("stk", JSON.stringify(this.state.stk));
                        })
    
                    });

            })



          



        }


    }

    cikisyap() {

        localStorage.removeItem("user");
        localStorage.removeItem("access_token");
        localStorage.removeItem("stklar");
        localStorage.removeItem("stk");
        window.location.href = "/";

    }


    handleSearchChange = (e, { value }) => {


        this.setState({ isLoading: true, searchText: value, results: [] });

        this.setState({ kontrol: true })
        let formData = new FormData();
        formData.append('arama', value);
        axios.post("https://api.sivildunyakocaeli.com/mobil/haberler/genelarama", formData, {
            headers: {
            },
        })
            .then((response) => {


                var data = [...response.data.data.haberler, ...response.data.data.etkinlikler, ...response.data.data.stklar];


              //   console.log("res22", data);
                this.setState({
                    searchloader: false,
                    results: data.splice(0, 5)
                })
            })



    };

    searchRender(value) {

        return <a href={value.type == "Haber" ? "/haberdetay/" + value.sid : value.type == "STK" ? "/stk/" + value.sid : "/etkinlikdetay/" + value.sid}
            style={{}} >
            <div className="SearchItem"
                style={{ width: "100%", display: "inline-flex", alignItems: "center", margin: 0, padding: 5, borderRadius: 5 }} >

                <div style={{ marginLeft: 15 }} >
                    {value.baslik}
                    <br />

                    <div style={{ fontSize: 11, color: "#999" }} >{value.type}</div>
                </div>
            </div></a>

    }

    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
    toggleActive = () => this.setState({ isActive: !this.state.isActive });
    toggleisNoti = () => this.setState({ isNoti: !this.state.isNoti });

    render() {
        const navClass = `${this.state.isOpen ? " nav-active" : ""}`;
        const buttonClass = `${this.state.isOpen ? " active" : ""}`;
        const searchClass = `${this.state.isActive ? " show" : ""}`;
        const notiClass = `${this.state.isNoti ? " show" : ""}`;

        const appStoreLink = 'https://apps.apple.com/us/app/sivil-d%C3%BCnya-kocaeli/id1491007133';
        const playStoreLink = 'https://play.google.com/store/apps/details?id=com.sivildunyakocaeli&hl=tr';
        const link = `${this.state.cihazTuru === 'iOS' ? appStoreLink : playStoreLink}`;
       
      
        return (
            <>
            <div style={{paddingTop:(this.state.bannerGoster && this.state.cihazTuru) ? 120 : 0 }} >
            <div className="nav-header bg-white shadow-xs border-0"
            style={{display: this.state.bannerGoster &&  this.state.cihazTuru ? "inline" : "" }}  >


{this.state.bannerGoster && this.state.cihazTuru && (
                 <div style={{padding: '10px', backgroundColor: '#f4f4f4', borderRadius: 10, 
                 textAlign: 'center', marginBottom: '20px', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
           
          
          <a href={link} target='_blank'>
            <div style={{marginLeft:10,textAlign:"left"}} >
              <p style={{textAlign:"left",margin: 0, fontWeight: 'bold', fontSize: '14px'}}>Şimdi İndirin!</p>
              <p style={{textAlign:"left",fontSize:12,lineHeight:1,marginBottom:10,marginRight:100}}>Daha iyi bir deneyim için mobil uygulamamızı kullanabilirsiniz.</p>
              
              <a href={link} target='_blank'>
              <button style={{backgroundColor: '#035f9b',color: 'white',border:0,fontSize:11,
               padding: '4px 10px', borderRadius: 5}} >Uygulamada aç</button>
                </a>
</div>
</a>


<a href={link} target='_blank'>
          <img src='/assets/images/iconindir.png'  
          style={{width: '120px',position:"absolute",bottom:0,right:30}} />
          </a>


<button onClick={() => this.setState({bannerGoster: false}) }
     style={{position: 'absolute', right: '15px', fontSize:25, top: '5px', border: 'none', background: 'transparent',  cursor: 'pointer'}}>&times;</button>

        </div>
      )}


                <div className="nav-top">
                    <Link to="/" style={{ marginLeft: 15 }} ><img style={{ height: 40 }} src={"/assets/images/stklogo.svg"} /> </Link>


                    <Link className="mob-menu ms-auto me-2 chat-active-btn">
                    </Link>

                    <button onClick={this.toggleOpen} className={`nav-menu me-0 ms-2 ${buttonClass}`}></button>
                </div>

                <>
                <div class="p-2 pointer text-center ms-auto menu-icon" style={{marginRight:"auto"}} >
                    <div style={{ width: 300,marginLeft:75 }} className="mobilGizle searchbr" >
                        <Search

                            icon={<i style={{ position: "absolute", left: 14, top: 15, fontSize: 16, color: "#aaa" }} className="feather-search "></i>}

                            fluid
                            className='searchStk'
                            //    input={{ icon: 'search', iconPosition: 'left' }}
                            loading={this.state.searchloader}
                            value={this.state.searchText}
                            results={this.state.results}
                            placeholder='STK veya etkinlik arayın...'
                            minCharacters={3}


                            resultRenderer={(value) => this.searchRender(value)}
                            onSearchChange={this.handleSearchChange}

                            noResultsMessage={
                                <div style={{ padding: 10, textAlign: "center", fontWeight: 400 }} >
                                    {this.state.searchloader ?
                                        <Spinner animation="border" variant="primary" /> : "Aradığınız kriterlerde sonuç bulunamadı "}</div>
                            }


                        /></div> </div></>

                {0 ?
                 <>
                    <NavLink activeClassName="active" to="/"
                        className="p-2 text-center ms-3 menu-icon center-menu-icon">
                        <i className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>

                    <NavLink activeClassName="active" to="/defaultstorie" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-zap font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
                    <NavLink activeClassName="active" to="/defaultvideo" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-video font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
                    <NavLink activeClassName="active" to="/defaultgroup" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-user font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
                    <NavLink activeClassName="active" to="/shop2" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-shopping-bag font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
                </> : null}
                {(this.state.stk != null && this.state.stk != "") ? 
                <>


                   
<a href='/profil/talepler'  className='mobilgizle'
style={{
    backgroundColor: "#1478FB", fontSize: 12
    , fontWeight: 600, height: 40, lineHeight: 0, display: "flex", justifyContent: "center",
    alignItems: "center", padding: 15,color:"#fff",borderRadius:10,marginRight:10
}}
 >
    <img src='/assets/images/talep.svg' style={{ height: 20,marginRight:5 }} />
Talepler</a>
<a href='/profil/rezervasyonlar' className='mobilgizle'
style={{
    backgroundColor: "#1478FB", fontSize: 12
    , fontWeight: 600, height: 40, lineHeight: 0, display: "flex", justifyContent: "center",
    alignItems: "center", padding: 15,color:"#fff",borderRadius:10,marginRight:10
}}
>
<img src='/assets/images/rezervasyon.svg' style={{ height: 20,marginRight:5 }} />
    Salon Rezervasyon</a>

                        <div>

                       
                        <a target={"_blank"} 
                        className="mobilgizle"
                        href={"https://yedep.sivildunyakocaeli.com?token="+this.state?.stk?.yedepToken}
                         name="submit" style={{ borderWidth:1,borderStyle:"solid",borderColor: "#B10D09",
                          fontSize: 12
                            , fontWeight: 600, height: 40, lineHeight: 0, display: "flex", justifyContent: "center",
                            alignItems: "center", padding: 15,color:"#363131",borderRadius:10}} 
                             value="Yedep Portala Git" >
                                 <img src="/assets/images/yedep.svg" style={{height:24,marginRight:10}} />
                                 YEDEP Portalı</a>
                    


                        </div>


                    <Popup
                        content={
                            <>
                                <div style={{
                                    backgroundColor: "#fff", width: 200,
                                    borderWidth: 1, borderStyle: "solid", borderColor: "#ddd", borderRadius: 5
                                }} >

                                    <a href="/profil" className='menuItem' style={{
                                        backgroundColor: "#fff", width: "100%", height: 60,
                                        justifyContent: "flex-start", alignItems: "center", display: "flex", fontWeight: 400, fontSize: 14,
                                        paddingLeft: 25
                                    }} >
                                        <i className="feather-user font-sm text-black-400" style={{ marginRight: 10 }} ></i>
                                        Profil'e Git
                                        <hr />
                                    </a>

                                    {this.state.stklar.length > 1 &&
                                        <a href="/stkdegistir" className='menuItem' style={{
                                            backgroundColor: "#fff", width: "100%", height: 60,
                                            justifyContent: "flex-start", alignItems: "center", display: "flex", fontWeight: 400, fontSize: 14,
                                            paddingLeft: 25
                                        }} >
                                            <i className="feather-users font-sm text-black-400" style={{ marginRight: 10 }} ></i>
                                            STK Değiştir
                                            <hr />
                                        </a>}

                                    <div onClick={() => this.cikisyap()} className='menuItem' style={{
                                        backgroundColor: "#fff", width: "100%", height: 60,
                                        justifyContent: "flex-start", alignItems: "center", display: "flex", fontWeight: 400, fontSize: 14,
                                        paddingLeft: 25
                                    }} >
                                        <i className="feather-log-out font-sm text-black-400" style={{ marginRight: 10 }} ></i>
                                        Çıkış Yap
                                        <hr />
                                    </div>

                                </div>
                            </>
                        }
                        offset={[0, 0]}
                        on='hover'
                        hoverable
                        position="bottom center"
                        basic

                        popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
                        trigger={
                            <div className="p-0 ms-3 menu-icon" style={{ cursor: "pointer" }}  >
                                <img src={"https://api.sivildunyakocaeli.com/" + this.state.stk?.profileImage}
                                    alt="user" className="w40 mt--1" style={{ marginRight: 10, borderRadius: 400 }} />

                                {this.state.stk?.name?.length > 25 ?
                                    `${this.state.stk?.name.substring(0, 25)}...` : this.state.stk?.name
                                }
                            </div>
                        }
                    />



                </>
                    :



                    <Button component={Link} to="/girisyap" className=" z-index-1 ms-auto mobilgizle
rounded-3 text-white" style={{
                            backgroundColor: "#1478FB", fontSize: 12
                            , fontWeight: 600, height: 40, lineHeight: 0, display: "flex", justifyContent: "center",
                            alignItems: "center", padding: 15
                        }} >
                        STK Girişi</Button>



                }
                <nav className={`navigation scroll-bar ${navClass}`}>
                    <div className="container ps-0 pe-0" style={{ marginTop: 0 }} >
                        <div className="nav-content">
                            <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
                           
                                <div className="nav-caption fw-600 font-xssss text-grey-500"><span>Menü </span></div>
                                <ul className="mb-1 top-content">
                                    
                                    <li className="logo d-none d-xl-block d-lg-block"></li>
                                    <li><Link to="/" className="nav-content-bttn open-font ">
                                        <img style={{ height: 45, marginRight: 10 }} src="/assets/images/kesfet.svg" />
                                        <span style={{ color: this.state?.url == "/" ? "#1478fb" : "#888" }} >Keşfet</span></Link></li>
                                    <li><Link to="/stk-takvim" className="nav-content-bttn open-font">
                                        <img style={{ height: 45, marginRight: 10 }} src="/assets/images/stktakvim.svg" />
                                        <span style={{ color: this.state?.url == "/stk-takvim" ? "#1478fb" : "#888" }} >STK Takvimi</span></Link></li>
                                    <li><Link to="/kurumsal" className="nav-content-bttn open-font">
                                        <img style={{ height: 45, marginRight: 10 }} src="/assets/images/kurumsal.svg" />
                                        <span style={{ color: this.state?.url == "/kurumsal" ? "#1478fb" : "#888" }} >Kurumsal</span></Link></li>
                                    <li><Link to="/stk-arama" className="nav-content-bttn open-font">
                                        <img style={{ height: 45, marginRight: 10 }} src="/assets/images/stkaramamotoru.svg" />
                                        <span style={{ color: this.state?.url == "/stk-arama" ? "#1478fb" : "#888" }} >STK Arama Robotu</span></Link></li>
                                    <li><Link to="/resmi-islemler" className="nav-content-bttn open-font">
                                        <img style={{ height: 45, marginRight: 10 }} src="/assets/images/resmiislemler.svg" />
                                        <span style={{ color: this.state?.url == "/resmi-islemler" ? "#1478fb" : "#888" }} >Resmi İşlemler</span></Link></li>
                                    <li><Link to="/hibe-duyurular" className="nav-content-bttn open-font">
                                        <i ><img style={{ height: 40 }} src={duyuru} /> </i>
                                        <span style={{ color: "#fd9602",lineHeight:1.2 }} >2024 Yılı YEDEP <br/> Hibe Duyurusu</span></Link></li>
                                </ul>
                            </div>




                            <div className="nav-wrap  rounded-xxl pt-3 pb-1">

                                <ul className="mb-1">
                                    <li className="logo d-none d-xl-block d-lg-block"></li>
                                    <li style={{ height: 35 }} ><a href="/sozlesmeler/kvkk" className="nav-content-bttn open-font h-auto pt-2 pb-2">
                                        <img style={{ height: 20, marginRight: 10 }} src="/assets/images/kvkk.svg" /><span style={{ fontSize: 13 }} >KVKK</span></a></li>
                                    <li style={{ height: 35 }} ><a href="/sozlesmeler/kullanici" className="nav-content-bttn open-font h-auto pt-2 pb-2">
                                        <img style={{ height: 20, marginRight: 10 }} src="/assets/images/sozlesme.svg" />
                                        <span style={{ fontSize: 13 }} >Kullanıcı Sözleşmesi</span></a></li>
                                        <li style={{ height: 35 }} ><a href="/sozlesmeler/cerez" className="nav-content-bttn open-font h-auto pt-2 pb-2">
                                        <img style={{ height: 20, marginRight: 10 }} src="/assets/images/cerezpolitika.svg" />
                                        <span style={{ fontSize: 13 }} >Çerez Politikası</span></a></li>

                                        <li style={{ height: 35 }} ><a target="_blank"  download={"basinkiti.zip"} href={basinkiti} className="nav-content-bttn open-font h-auto pt-2 pb-2">
                                        <img style={{ height: 20, marginRight: 10 }} src="/assets/images/basin.svg" />
                                        <span style={{ fontSize: 13 }} >Basın Kiti</span></a></li>

                                        {(this.state.stk != null && this.state.stk != "") ? 
                                        
                                        <>
                                        {this.state.stklar.length > 1 &&

<li style={{ height: 35 }} ><a href="/stkdegistir" className="nav-content-bttn webgizle open-font h-auto pt-2 pb-2">
<i className="feather-users font-sm text-black-400" style={{ marginRight: 10,height:20,color:"#888" }} ></i>
<span style={{ fontSize: 13 }} >STK Değiştir</span></a></li>

                                       }

<li style={{ height: 35 }} ><a onClick={() => this.cikisyap()} className="nav-content-bttn webgizle open-font h-auto pt-2 pb-2" style={{cursor:"pointer"}} >
<i className="feather-log-out font-sm text-black-400" style={{ marginRight: 10,height:20,color:"#888" }} ></i>
<span style={{ fontSize: 13 }} >Çıkış Yap</span></a></li>

                                        </>
                                        
                                        : null }

                                    {0 ?
                                        <li><a href="/defaultmessage" className="nav-content-bttn open-font h-auto pt-2 pb-2">
                                            <img style={{ height: 25, marginRight: 10 }} src="/assets/images/yardim.svg" />
                                            <span>Yardım</span></a></li> : null}
                                </ul>

                                {(this.state.stk != null && this.state.stk != "") ? 
                                <>

                              

                                <Button component={Link} to="/profil" className=" z-index-1 ms-auto webgizle
rounded-3 text-white" style={{marginTop:20,
                            backgroundColor: "#1478FB", fontSize: 12
                            , fontWeight: 600, height: 40, lineHeight: 0, display: "flex", justifyContent: "center",
                            alignItems: "center", padding: 15
                        }} >
                        Profile Git</Button>


                                                
                        <a href='/profil/talepler'  className='webgizle'
style={{
    backgroundColor: "#1478FB", fontSize: 12,marginTop:10
    , fontWeight: 600, height: 40, lineHeight: 0, display: "flex", justifyContent: "center",
    alignItems: "center", padding: 15,color:"#fff",borderRadius:5
}}
 >
    <img src='/assets/images/talep.svg' style={{ height: 20,marginRight:5 }} />
Talepler</a>

<a href='/profil/rezervasyonlar' className='webgizle'
style={{
    backgroundColor: "#1478FB", fontSize: 12,marginTop:10
    , fontWeight: 600, height: 40, lineHeight: 0, display: "flex", justifyContent: "center",
    alignItems: "center", padding: 15,color:"#fff",borderRadius:5
}}
>
<img src='/assets/images/rezervasyon.svg' style={{ height: 20,marginRight:5 }} />

Salon Rezervasyon
</a>


                        <a target={"_blank"} 
                        className="webgizle"
                        href={"https://yedep.sivildunyakocaeli.com?token="+this.state?.stk?.yedepToken}
                         name="submit" style={{ borderWidth:1,borderStyle:"solid",borderColor: "#B10D09",marginTop:10,
                          fontSize: 12
                            , fontWeight: 600, height: 40, lineHeight: 0, display: "flex", justifyContent: "center",
                            alignItems: "center", padding: 15,color:"#363131",borderRadius:10}} 
                             value="Yedep Portala Git" >
                                 <img src="/assets/images/yedep.svg" style={{height:24,marginRight:10}} />
                                 YEDEP Portalı</a>


    
                        </>
                        :
                                <Button component={Link} to="/girisyap" className=" z-index-1 ms-auto webgizle
rounded-3 text-white" style={{marginTop:20,
                            backgroundColor: "#1478FB", fontSize: 12
                            , fontWeight: 600, height: 40, lineHeight: 0, display: "flex", justifyContent: "center",
                            alignItems: "center", padding: 15
                        }} >
                        STK Girişi</Button> }


                            </div>
                        </div>
                    </div>
                </nav>

                <div className={`app-header-search ${searchClass}`}>
                    <form className="search-form">
                        <div className="form-group searchbox mb-0 border-0 p-1">
                            <input type="text" className="form-control border-0" placeholder="Arama Yap..." />
                            <i className="input-icon">
                                <ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline"></ion-icon>
                            </i>
                            <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                                <i className="ti-close font-xs" onClick={this.toggleActive}></i>
                            </span>
                        </div>
                    </form>
                </div>

            </div>
            </div>
            </>
        );
    }
}

export default Header;