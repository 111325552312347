import React, { Component, Fragment } from "react";
import Header from '../components/Header';

import Appfooter from '../components/Appfooter';

import axios from "axios";

import parse from 'html-react-parser';
import Events from "../components/Events";
import { Spinner } from "react-bootstrap";

class Resmiislemler extends Component {

    constructor() {
        super();
        this.state = {
            id:0,
            aktifMenu:1,
            resmiislemler: [],
            loading:false,
            metin:[],
        }
    }

    componentDidMount() {
        
        this.resmiislemler();
        this.etkinlikler();
    
    
    }

    
    resmiislemler = async () => {

        this.setState({
            loading:true
        })

        await axios.get("https://api.sivildunyakocaeli.com/mobil/resmirehber/", {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {

                if(response.data.data.length>0) {
                this.metinsec(response.data.data[0].id)
                }

                this.setState({
                    resmiislemler: response.data.data,
                    loading:false,
                })

            });

    }

    etkinlikler = async () => {

        await axios.get("https://api.sivildunyakocaeli.com/mobil/etkinlikler/yaklasan", {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
                this.setState({
                    etkinlikler: response.data.data,
                })

            });

    }


    metinsec = async (id) => {

        this.setState({
            loading:true
        })

        await axios.get("https://api.sivildunyakocaeli.com/mobil/resmirehber/detay/"+id, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
                
                this.setState({
                    metin: response.data.data,
                }, () => {
                    this.setState({
                    loading:false,})
                })

            });

    }

    render() {
        return (
            <Fragment>
                <Header match={this.props?.match} />


                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
          
          
                                  
                            
            
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-block p-4">
                    {this.state.loading==false ?
                    <>
                    {this.state.metin?.coverImage &&
                <img src={"https://api.sivildunyakocaeli.com/"+this.state.metin?.coverImage} alt="avater" 
                className="float-right p-1 bg-white mobilresmiresim" 
                style={{width:"100%",height:400,objectFit:"cover",marginBottom:20,borderRadius:15}} />}
            
            {this.state.metin?.title &&
                    <h2 className="fw-700 font-lg "  >
                                        {this.state.metin?.title}
                                        </h2>}
                                       
                                        {this.state.metin?.description &&
                                        <p className="font-xsss fw-500 text-grey-500 lh-30  mt-3">
                                        {parse(''+this.state.metin?.description)}
                                        </p>}
</> : 
<div style={{width:"100%",textAlign:"center"}} >
<Spinner animation="border" variant="primary" />
    </div>
    }
                </div>
                </div>

              
            
                </div>

                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                   
                   <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                   <div className="card-body d-block p-4">
                       <h4 className="fw-700 mb-3 font-xsss text-grey-900">Resmi İşlemler Rehberi</h4>

                       <hr />
   
                       <ul className="mb-1 top-content">
                                   <li className="logo d-none d-xl-block d-lg-block"></li>
                                   {this.state.resmiislemler.map((item) => {
                                       return (
   
                                           <li onClick={() => this.metinsec(item.id) }  style={{marginBottom:5,cursor:"pointer"}} >
                                               <div style={{lineHeight:1.3,fontWeight:this.state.metin?.id==item.id ? 600 : 400 ,color:this.state.metin?.id==item.id ? "#005f9b" : "#888"}} >
                                                   <span>{item.title}</span></div></li>
                                       )
                                   })}
                                   </ul>
                      
                   </div>
               </div>
   
   
           
                      
   
                   </div>
                
                              


                            </div>
                        </div>
                    </div>
                </div>


                <Appfooter />

            </Fragment>
        );
    }
}

export default Resmiislemler;