import React, { Component, Fragment } from "react";


import Profiledetail from '../../components/Profiledetail';
import Profilephoto from '../../components/Profilephoto';

import Events from '../../components/Events';
import Postview from '../../components/Postview';
import Load from '../../components/Load';

import axios from "axios";
import { Spinner } from "react-bootstrap";

class Kesfet extends Component {

    state = {
        id:0,
        aktifMenu: 1,
        page:1,
        haberler:[],
        loading:false,
    }


    componentDidMount () {

        var stklocal = JSON.parse(localStorage.getItem("stk"));
        if(stklocal) {
        if(this.props?.stkid == stklocal.id) {
            window.location.href="/profil";
        }
    }

        this.setState({
            id:this.props?.stkid
        })
        this.stkhaberler(this.props?.stkid);

    }
    
    stkhaberler = async (id) => {

        this.setState({
            loading:true,
        })
        

        await axios.get("https://api.sivildunyakocaeli.com/mobil/haberler/stk_haberler/"+id+"/"+this.state.page, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
                 console.log("haberler",response.data.data);
                this.setState({
                    haberler: response.data.data,
                    loading:false,
                })

            });

    }


    haberdevam = async (id) => {

        this.setState({
            loading:true,
        })
        

        await axios.get("https://api.sivildunyakocaeli.com/mobil/haberler/stk_haberler/"+this.state.id+"/"+this.state.page, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
                this.setState({
                    haberler: [...this.state.haberler,...response.data.data],
                    loading:false,
                })

            });

    }

    

    render() {
        return (
            <>
                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                   
                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-block p-4">
                    <h4 className="fw-700 mb-3 font-xsss text-grey-900">Başkanın Mesajı</h4>
                    <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">
                        {this.props?.stk.baskanMesaj}
                    </p>
                </div>
                <div className="card-body border-top-xs d-flex">
                    <i className="feather-phone text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-0">Telefon
                                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                                { this.props?.stk?.bilgiDurum == 1 ? 
                                   <a href={"tel:"+this?.props?.stk?.telefon}>{this.props?.stk?.telefon}</a>
                                   : "05** *** ** **" }
                                    </span>
                                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                                { this.props?.stk?.bilgiDurum == 1 ? 
                                <a href={"tel:"+this?.props?.stk?.telefon2}>{this.props?.stk?.telefon2}</a>
                                : "05** *** ** **" }
                                    </span>
                            </h4>
                </div>

                <div className="card-body d-flex pt-0">
                    <i className="feather-map-pin text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-0">Adres 
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                    {this.props?.stk?.adres}
                    </span></h4>
                </div>
                <div className="card-body d-flex pt-0">
                    <i className="feather-user text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">Başkan Bilgileri 
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">

{ this.props?.stk?.bilgiDurum == 1 ? 
    this.props?.stk?.baskanAdi
    : this.props?.stk?.baskanAdi ? this.props?.stk?.baskanAdi[0]+"***** ****" :"" }
    </span>
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                    { this.props?.stk?.bilgiDurum == 1 ? 
                     <a href={"tel:"+this?.props?.stk?.baskanTelefon}>{this.props?.stk?.baskanTelefon}</a>
                      : "05** *** ** **" }
                    </span>
                    </h4>
                </div>
               
               
            </div>

                    <Events etkinlikler={this.props?.etkinlikler.length>0 ? this.props?.etkinlikler : []} />
                    <Profilephoto stkid={this.props?.stk?.id}  />

                </div>
                <div className="col-xl-8 col-xxl-9 col-lg-8">

                <>
          

                { ( this.state.haberler?.length<=0) ?
                        <div style={{ width: "100%", textAlign: "center", marginTop: 20 }} >
                            <img src='/assets/images/haberYok.svg' style={{ height: 90 }} />

                            <p style={{ color: "#aaa", marginTop: 10, fontWeight: 500, fontSize: 17 }} >
                                Haber Bulunamadı</p>

                        </div> : null }

             
            {this.state?.haberler && this.state?.haberler.map((item) => {
                                        return (
                                            <Postview
                                            veriyenile={() => { this.stkhaberler(this.state?.id); }}
                                                key={item.id}
                                                id={item.id}
                                                resimler={item.resimler}
                                                stk={item.stk}
                                                time={item.time}
                                                des={item.description}
                                                url={item.url}
                                            />
                                        )
                                    })}
                                    
                                    {this.state.haberler.length>=10 &&
                                    <>
                                    {this.state.loading==false ? 
                                    
                                    <div style={{ width: "100%", textAlign: "-webkit-center" }} >
                                    <div
                                        onClick={() => this.setState({ page: this.state.page + 1 }, () => { this.haberdevam() })}
                                        style={{
                                            cursor: "pointer", color: "#404040",textAlign:"center",
                                            textDecorationLine:"underline",
                                             padding: 5, width: 100
                                        }} >daha fazla</div>
                                </div>

                                    :
                                    <div style={{width:"100%",textAlign:"center"}} >
                                    <Spinner animation="border" variant="primary" />
                                        </div>
                                     }
                                      </>
                                    }


            </>
            
                </div></>

        )
    }
}


export default Kesfet;