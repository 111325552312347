import axios from "axios";
import React, { Component } from "react";

import {  Button, Grid,  TextField, Typography } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { tr } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class RezervasyonEkle extends Component {

  constructor() {
    super();
    this.onDrop = (files) => {
      this.setState({ files }, () => { this.resimDuzenle() })
    };
    this.state = {

      kontrol: false,
      loading: true,
      date: "",
      odalar: [],
      zamanlar: [],
      guncellemezamanlar: [],
      ekleDialog: false,
      guncelleDialog: false,


      
      seciliZaman: 0,
      seciliOda: [],
      stkTur: 1,
      saatler: [
        { value: 1, label: '09:00-10:00' },
        { value: 2, label: '10:00-11:00' },
        { value: 3, label: '11:00-12:00' },
        { value: 4, label: '12:00-13:00' },
        { value: 5, label: '13:00-14:00' },
        { value: 6, label: '14:00-15:00' },
        { value: 7, label: '15:00-16:00' },
        { value: 8, label: '16:00-17:00' },
        { value: 9, label: '17:00-18:00' },
        { value: 10, label: '18:00-19:00' },
        { value: 11, label: '19:00-20:00' },
        { value: 12, label: '20:00-21:00' },
        { value: 13, label: '21:00-22:00' },
        { value: 14, label: '22:00-23:00' },
      ],

      turler: [{ id: 1, name: "Dernek" },
      { id: 2, name: "Vakıf" },
      { id: 3, name: "Meslek Odası" },
      { id: 4, name: "Sendika" },
      { id: 5, name: "Platform" },
      { id: 6, name: "Öğrenci Kulübü" },
      { id: 7, name: "İl Temsilciliği" },
      { id: 8, name: "Kamu Kurumu" },
      ],

      seciliTarih:format(new Date(),'yyyy-MM-dd'),
      searchDatas: [],

      seciliSaatler: [],
      ihtiyaclar:[],
      seciliIhtiyaclar:[],
      randevuNotu: "",
      ciktiNotu: "",
      hamilAd: "",
      hamilTelefon: "",
      rezervasyonId: 0,

      seciliSalon:0,
      selectSalon:[],
      salonlar:[],


      merkezler:[{id:1,name:"İzmit"},{id:2,name:"Gebze"},{id:5,name:"Seka"},{id:6,name:"Fuar"}],
      merkez:{id:1,name:"İzmit"},
      katilimci:0,

      toplantiType:{id:1,title:"Genel Toplantı"},

      onayDurum:false,

      stk:[],
      token:"",
      



    };
  }


  salonlar() {
    
    axios.get('https://api.sivildunyakocaeli.com/rezervasyonlar/salonlar/'+this.state.merkez.id).then((item) => {

      this.setState({
        salonlar: item.data.data,
      },() => {

        if(this.state.salonlar.length>0) {
          this.setState({

        selectSalon: this.state.salonlar[0],
        seciliSalon: this.state.salonlar[0].id,
        seciliOda: { odaName: this.state.salonlar[0]?.name, link: this.state.salonlar[0]?.link , odaId: this.state.salonlar[0]?.id , count: this.state.salonlar[0]?.count},

          })
        }

      })

    });

  }


  ihtiyacListesi() {

    axios.get('https://api.sivildunyakocaeli.com/Rezervasyonihtiyac/').then((item) => {

      this.setState({
        ihtiyaclar: item.data.data,
        seciliIhtiyaclar: [],
      })

    });

  }



  componentDidMount() {

    this.setState({
      loading: false,
      stk: JSON.parse(localStorage.getItem("stk")),
      token: JSON.parse(localStorage.getItem("access_token")),
    }, () => {
      this.ihtiyacListesi();
      this.salonlar();
    })

  }



  kayitolustur() {

    this.setState({
      kontrol: true
    })

    if ( this.state.seciliOda!=[]  && this.state.seciliSaatler.length > 0 && this.state.onayDurum==true) {

      const formData = new FormData();
      formData.append("tarih", this.state.seciliTarih);
      formData.append("odaId", this.state.seciliOda.odaId);
      formData.append("stkId", this.state.stk?.id);

      formData.append("katilimci", this.state.katilimci);
      formData.append("randevuNotu", this.state.randevuNotu);
      formData.append("seciliSaatler", JSON.stringify(this.state.seciliSaatler));
      formData.append("toplantiType", this.state.toplantiType?.id);

      if(this.state?.toplantiType?.id==2) {
      formData.append("seciliIhtiyaclar", JSON.stringify(this.state.seciliIhtiyaclar));
      } else {
        formData.append("seciliIhtiyaclar", JSON.stringify([]));
      }

      axios.post('https://api.sivildunyakocaeli.com/mobil/rezervasyonlar/yeniRezervasyon', formData, {
        headers: {
          "Accept": "*/*",
          "Authorization": "Bearer " + this.state.token,
          "x-requested-with": "XMLHttpRequest",
        },
      }).then((response) => {

        this.props?.onaylandi();

      });

    }


  }

  

  filtrele(item) {

    var durum = true;

    var saatler = this.state.zamanlar;
    saatler.filter((s) => s.id == item.value ? true : false).forEach(element => {

      element.odalar.filter((e) => (e.durum == 1 && e.odaId == this.state.seciliOda.odaId) ? true : false).
        forEach(ll => {
          durum = false;

        })

    });

    return durum;

  }

  

  

    render() {
        return (
       
       
          <>
                
          <Grid container columns={{ xs: 12, sm: 12, md: 12, }}
          style={{
            backgroundColor:"#fff",
            padding:25,
            borderRadius:5,
          }}>

<Grid item xs={12} sm={12} md={12} style={{ marginTop:-20,padding: 10, textAlign: "initial" }}  >
<Typography variant="h6" className="font-medium" style={{ marginLeft: 10 }} >
1. Randevu Bilgileri
</Typography>
</Grid>



<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >

<Autocomplete
  id="combo-box-demo"
  options={this.state.merkezler}
  getOptionLabel={(option) => option?.name}
  value={this.state.merkez}
  defaultValue={{id:1,name:"İzmit"}}
  renderInput={(params) => <TextField  {...params} 


  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Merkez *</div>}
  variant="outlined"

    error={(this.state.kontrol == true &&  (this.state.merkez <= 0 || this.state.merkez == null)  ) ? true : false}
    helperText={(this.state.kontrol == true &&  (this.state.merkez <= 0 || this.state.merkez == null) ) ? "Bu alan boş geçilemez" : ""}
  />}



  onChange={(event, newValue) => {
    this.setState({ merkez: { id: newValue?.id,  name: newValue?.name},
}, () => {
        
      this.setState({
        seciliSaatler: [],
        selectSalon:[],
      },() => {
        this.salonlar();
      })
    })
  }}
/>

</Grid>


<Grid item xs={11} sm={11} md={11} style={{ padding: 10 }}  >

<Autocomplete
  id="combo-box-demo"
  options={this.state.salonlar.filter((e) => e.merkezId == this.state.merkez?.id )}
  getOptionLabel={(option) => option?.name + " ( " + option?.count +" Kişilik )"}
  value={this.state.selectSalon}
  defaultValue={this.state.selectSalon}
  renderInput={(params) => <TextField  {...params} 


  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Seçili Salon *</div>}
  variant="outlined"

    error={(this.state.kontrol == true &&  (this.state.seciliSalon <= 0 || this.state.seciliSalon == null)  ) ? true : false}
    helperText={(this.state.kontrol == true &&  (this.state.seciliSalon <= 0 || this.state.seciliSalon == null) ) ? "Bu alan boş geçilemez" : ""}
  />}



  onChange={(event, newValue) => {
    this.setState({ seciliSalon: newValue?.id,
seciliOda: { odaName: newValue?.name, link:newValue?.link , odaId: newValue?.id , count: newValue?.count},
}, () => {
        
      this.setState({
        selectSalon: this.state.salonlar.filter((e) => e.id == this.state.seciliSalon)[0],
        seciliSaatler: [],
      },() => {

      })
    })
  }}
/>

</Grid>
<Grid item xs={1} sm={1} md={1} style={{ padding: 10,display:"flex",justifyContent:"center",alignItems:"center", }}  >
<a href={""+this.state.seciliOda?.link} target="_blank"  >
<Typography variant="body1"  >
Önizle
</Typography>
</a>
</Grid>


<Grid item xs={12} sm={12} md={12} style={{ padding: 10, textAlign: "initial" }}  >
<Typography variant="h6" className="font-medium" style={{ marginLeft: 10 }} >
2. Randevu Detayı
</Typography>
</Grid>



<Grid item xs={12} sm={12} md={12} style={{ padding: 10, textAlign: "initial" }}  >
  
                <MuiPickersUtilsProvider locale={tr} utils={DateFnsUtils}>

                <TextField
        id="date"
        type="date"
        defaultValue={format(new Date(),'yyyy-MM-dd')}
        error={(this.state.kontrol == true && this.state.seciliTarih == "") ? true : false}
        helperText={(this.state.kontrol == true && this.state.seciliTarih == "") ? "Bu alan boş geçilemez" : ""}
       
        label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Randevu Tarihi</div>}
        value={this.state.seciliTarih}
        
        onChange={(date) => { this.setState({seciliTarih:format(date, 'yyyy-MM-dd'),
        seciliSaatler: []}) }}

        style={{ width: "100%" }}
        InputProps={{}}
        variant="outlined"
      />
      </MuiPickersUtilsProvider>
        </Grid>



<Grid item xs={12} sm={12} md={12} style={{ borderWidth: 1, margin: 10, borderRadius: 5,
borderColor: (this.state.kontrol == true && !this.state.seciliSaatler) ? "red" : "transparent", borderStyle: "solid"
}}  >

<Autocomplete
multiple
id="tags-filled"
options={this.state.saatler.filter((item) => this.filtrele(item))}
getOptionLabel={(option) => option.label}
defaultValue={this.state.seciliSaatler}
onChange={(e, seciliSaatler) => { this.setState({ seciliSaatler }) }}
filterSelectedOptions
renderInput={(params) => (
<TextField
  {...params}
  variant="outlined"
  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Müsait Saatler</div>}
  placeholder="Müsait Saatler"
  error={(this.state.kontrol == true && this.state.seciliSaatler.length == 0) ? true : false}
  helperText={(this.state.kontrol == true && this.state.seciliSaatler.length == 0) ? "Bu alanı boş bırakmayınız" : ""}
/>
)}
/>



</Grid>



<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >

<TextField

type="number"
error={false}
helperText={""}

label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Katılımcı Sayısı</div>}
value={this.state.katilimci}
onChange={(e) => this.setState({ katilimci: e.target.value })}
style={{ width: "100%" }}
InputProps={{}}
variant="outlined"
/>


</Grid>





<Grid item xs={12} sm={12} md={12} style={{
  margin: 10, borderRadius: 5,
}}  >

<Autocomplete
id="tags-filled"
options={[{id:1,title:"Genel Toplantı"},{id:2,title:"Kongre"}]}
getOptionLabel={(option) => option.title}
defaultValue={this.state.toplantiType}
onChange={(e, secilitoplantiType) => { this.setState({ toplantiType:secilitoplantiType }) }}
filterSelectedOptions
renderInput={(params) => (
<TextField
  {...params}
  variant="outlined"
  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Toplantı Türü</div>}
  placeholder="Toplantı Türü"
/>
)}
/>

</Grid>

{this.state.toplantiType?.id==2 &&

<Grid item xs={12} sm={12} md={12} style={{
  margin: 10, borderRadius: 5,
}}  >

<Autocomplete
multiple
id="tags-filled"
options={this.state.ihtiyaclar}
getOptionLabel={(option) => option.title}
defaultValue={this.state.seciliIhtiyaclar}
onChange={(e, seciliIhtiyaclar) => { this.setState({ seciliIhtiyaclar }) }}
filterSelectedOptions
renderInput={(params) => (
<TextField
  {...params}
  variant="outlined"
  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >İhtiyaç Listesi</div>}
  placeholder="İhtiyaç Listesi"
/>
)}
/>

</Grid>
    }


<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >

<TextField

multiline
rows={3}
type="text"
error={false}
helperText={""}

label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Randevu Notu</div>}
value={this.state.randevuNotu}
onChange={(e) => this.setState({ randevuNotu: e.target.value })}
style={{ width: "100%" }}
InputProps={{}}
variant="outlined"
/>


</Grid>


<Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}  >



<FormControlLabel
        control={
          <Checkbox color="blue" type="checkbox" checked={this.state.onayDurum} onChange={(e,value) => this.setState({onayDurum:value})
        } />
        }
        label={
          <p style={{paddingTop:13,marginTop:3}} >  
  Salon teslim <a target={"_blank"} style={{color:"blue"}} href="https://api.sivildunyakocaeli.com/uploads/tutanak.docx">tutanağını</a> kabul ediyorum.
</p>
        }
      />

      {this.state.onayDurum==false && this.state.kontrol==true &&
<div style={{color:"red"}}  >Tutanağı kabul etmeniz gerekiyor.</div>
    }


</Grid>

<div style={{ width: "100%", textAlign: "right" }} >

<Button onClick={() => this.kayitolustur()}  className=" z-index-1 
                        rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12
                        ,fontWeight:600,height:40,lineHeight:0,display:"inline",justifyContent:"center",
                        alignItems:"center",padding:15
                        }} >
                          Oluştur</Button>


</div>
</Grid>
          
          
          </>


        )
    }
}


export default RezervasyonEkle;