import axios from "axios";
import React, { Component, createRef, Fragment } from "react";
import { Spinner } from "react-bootstrap";
import Load from "../components/Load";
import { Button, Card, CardContent, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';

import InputMask from 'react-input-mask';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from "react-router-dom";



class Onay extends Component {

    constructor() {
        super();

        this.state = {
            
    kutukNo: "",
    kurumAdi: "",
    kisaAdi: "",
    email: "",
    ilce: "",
    adres: "",
    stkTur: 1,
    baskanAdi: "",
    baskanSoyadi: "",
    baskanTelefon: "",
    baskanEmail: "",
    baskanAdres: "",
    stkInputs: [],

    kutukHata: false,
    kutukHataMesaj: "",

    kontrol: false,
    loading: false,

        }
    }


  componentDidMount() {

    if(localStorage.getItem("user") && localStorage.getItem("stk")  ) {
        this.props.history.push("/")
    }

    this.stkTurInputs();

  }

  stkTurInputs() {

    if(this.state.stkTur!=1) {

      this.setState({
      kutukNo:"KBB-"+parseInt(999999999 + (Math.random() * (999999999999) ) )
      })

    }

    axios.get('https://api.sivildunyakocaeli.com/stklar/stkTurInputs/' + this.state.stkTur).then((item) => {

      this.setState({
        stkInputs: item.data.data
      })

    });

  }

  kayitolustur() {

    this.setState({
      kontrol: true,
      loading: true,
    })

    if (this.state.kutukNo != "" && this.state.kurumAdi != "" && this.state.email != "" && this.state.baskanAdi != ""
      && this.state.baskanSoyadi != "" && this.state.baskanTelefon != "") {

      const formData = new FormData();
      formData.append("kutukNo", this.state.kutukNo);
      formData.append("kurumAdi", this.state.kurumAdi);
      formData.append("kisaAdi", this.state.kisaAdi);
      formData.append("email", this.state.email);
      formData.append("ilce", this.state.ilce);
      formData.append("adres", this.state.adres);
      formData.append("stkTur", this.state.stkTur);
      formData.append("baskanAdi", this.state.baskanAdi);
      formData.append("baskanSoyadi", this.state.baskanSoyadi);
      formData.append("baskanTelefon", this.state.baskanTelefon);
      formData.append("baskanEmail", this.state.baskanEmail);
      formData.append("baskanAdres", this.state.baskanAdres);
      formData.append("stkInputs", JSON.stringify(this.state.stkInputs));

      axios.post('https://api.sivildunyakocaeli.com/mobil/stklar/yeniStk', formData, {
        headers: {
          "Accept": "*/*",
          "x-requested-with": "XMLHttpRequest",
        },
      }).then((response) => {

        this.setState({

          loading: false,
        })

        if (response.data.success == true) {

            alert("işlem başarılı")

        } else {
          if (response.data.data == "kutukNo") {

            document.getElementById("scrool").scrollTo(0, 0);
            
            this.setState({
              kutukHata: true,
              kutukHataMesaj: response.data.message,
            })
          }
        }


      });



    } else {
      this.setState({

        loading: false,
      })
      document.getElementById("scrool").scrollTo(0, 0);
    }

    

  }

  inputGuncelle(id, value) {

    const inputlar = this.state.stkInputs;

    inputlar.forEach((item, index) => {

      if (inputlar[index].id == id) {
        inputlar[index].value = value;

      }
    });

    this.setState({
      stkInputs: inputlar
    })

  }

    render() {
        return (
            <Fragment>
                <div className="main-wrap">


                    <div className="row">
                        <div className="col-xl-6 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                            style={{ backgroundImage: `url("https://api.sivildunyakocaeli.com/uploads/girisBanner.jpg")` }}></div>
                        <div className="col-xl-6 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                            
                                
                         
                         
                                <div className="card-body rounded-0 " style={{paddingTop:0,textAlign:"center"}} >
                                    
                                 <img src="assets/images/onay.svg" style={{height:250,marginBottom:40}}  />
        
        <h2 className="fw-700  display2-md-size mb-3" style={{ fontSize: 22 }} >
            STK Başvurunuz Alınmıştır.</h2>
        <div style={{ marginBottom: 40, lineHeight: 1.5, marginTop: 20 }} >
            Başvurunuz sonuçlandırıldığında sizinle iletişime geçilecektir.
        </div>
        
       
        <Button component={Link} to="/" className=" z-index-1 
                                                rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12
                                                ,fontWeight:600,height:40,width:200,lineHeight:0,display:"inline"
                                                ,justifyContent:"center",
                                                alignItems:"center",padding:15
                                                }} >
                                                    Anasayfaya Dön</Button>

       

       













                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Onay;