import React,{Component} from 'react';
import { Link } from 'react-router-dom';

class Appfooter extends Component {

    state = {
        stk:[],
        token:"",
    }

    componentDidMount() {

    this.setState({
        stk: JSON.parse(localStorage.getItem("stk")),
        token: JSON.parse(localStorage.getItem("access_token")),
    })

    }

    render() {
        return (
            <>
            {0 ?
            <div className="app-footer border-0 shadow-lg bg-primary-gradiant">
                <Link to="/" className="nav-content-bttn nav-center"><i className="feather-home"></i></Link>
                <Link to="/defaultvideo" className="nav-content-bttn"><i className="feather-package"></i></Link>
                <Link to="/defaultlive" className="nav-content-bttn" data-tab="chats"><i className="feather-layout"></i></Link>           
                <Link to="/shop2" className="nav-content-bttn"><i className="feather-layers"></i></Link>
                <Link to="/profil" className="nav-content-bttn">
                    <img src={"https://api.sivildunyakocaeli.com/" + this.state.stk?.profileImage}
                     alt="user" className="w30 shadow-xss" style={{borderRadius:400}} /></Link>
            </div> : null }
            </>

        );
    }
}

export default Appfooter;