import React, { Component, Fragment } from "react";
import  EtkinlikEkle  from "./EtkinlikEkle";
import iziToast from 'izitoast';
import axios from "axios";

import { Button } from '@material-ui/core';
import { Link } from "react-router-dom";

class Etkinlikler extends Component {

    state = {
        aktifMenu: 1,
        durum:false,
        onayEtkinlikler:[],
    }

    onaylandi() {
        
        iziToast.show({
            theme:"light",
            color:"green",
            title: 'Tebrikler...',
            message: 'Etkinliğiniz Onay İçin Gönderildi.'
        });

        this.setState({
            durum:false,
        })


        this.onaybekleyenetkinlikler(this.state?.id);
    }

    componentDidMount () {

        if(this.props?.direktSayfa==true) {
            this.setState({
                durum:true
            })
        }

        this.setState({
            id:this.props?.stkid,
            token:this.props?.token
        },() => {

        this.onaybekleyenetkinlikler(this.props?.stkid);
        })

    }
    

    onaybekleyenetkinlikler = async (id) => {

        const formData = new FormData();
      formData.append("stkId", id);
      formData.append("page", 1);

        await axios.post("https://api.sivildunyakocaeli.com/mobil/etkinlikler/onaybekleyenler" , formData, {
            headers: {
                "Accept": "*/*",
                "Authorization": "Bearer " + this.state.token,
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
             //    console.log("res",response.data);
                if(response.data.success==true) {
                this.setState({
                    onayEtkinlikler: response.data.data,
                })
            }

            });   

    }

    render() {
        return (
            <div className="col-xl-12 col-xxl-12 col-lg-12">
                           
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/izitoast/1.4.0/css/iziToast.css"/>

                                      <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                    <div className="card-body d-flex align-items-center p-0">
                        <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900">Etkinlik Yönetimi</h2>
                        <div className="search-form-2 ms-auto">
                        </div>

                        {this.state.durum==false ?
                        <Button onClick={() => this.setState({durum:true})}  className=" z-index-1 
                        rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12
                        ,fontWeight:600,height:40,lineHeight:0,display:"flex",justifyContent:"center",
                        alignItems:"center",padding:15
                        }} >
                            Yeni Etkinlik</Button>

                      
                            : 
                            <Button onClick={() => this.setState({durum:false})}  className=" z-index-1 
                        rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12
                        ,fontWeight:600,height:40,lineHeight:0,display:"flex",justifyContent:"center",
                        alignItems:"center",padding:15
                        }} >
                            Kapat</Button>
                             }
                    </div>
                </div>

{(this.state.durum==false) ?
                                      <>



{ (this.state.onayEtkinlikler?.length<=0 && this.props?.etkinlikler?.length<=0) ?
                        <div style={{ width: "100%", textAlign: "center", marginTop: 20 }} >
                            <img src='/assets/images/etkinlikYok.svg' style={{ height: 90 }} />

                            <p style={{ color: "#aaa", marginTop: 10, fontWeight: 500, fontSize: 17 }} >
                                Etkinlik Bulunamadı</p>

                        </div> : 

<>


<div className="row" style={{padding:10}} >
                                      {this.state.onayEtkinlikler && this.state?.onayEtkinlikler.map((value , index) => (
                                          
                                <div  className="col-lg-6 col-md-6 pe-2 ps-2">
                                    <a key={index} href={"/etkinlikdetay/"+value.url}>
                                    <div className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                                        <div className="card-image w-100">
                                            {value.coverImage!="" ?
                                            <img src={"https://api.sivildunyakocaeli.com/"+value.coverImage} 
                                            style={{height:300,objectFit:"cover"}}
                                               alt="event" className="w-100 rounded-3" /> : null }
                                        </div>
                                        <div className="card-body d-flex ps-0 pe-0 pb-0" style={{position:"relative"}} >
                                            <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg"><h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0"><span className="ls-3 d-block font-xsss text-grey-500 fw-500">{value.ay}</span>{value.gun}</h4></div>
                                            <h2 className="fw-700 lh-3 font-xss" style={{marginRight:75}}>{value.title}
                                                <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500"> <i className="ti-location-pin me-1"></i>{value.location} </span>
                                            </h2>

                                            <div style={{borderRadius:10,height:30,
                                            position:"absolute",right:10,top:20
                                            ,lineHeight:0,display:"inline",padding:10,paddingTop:15,
                                            paddingRight:10,paddingLeft:10,
                           justifyContent:"center",alignItems:"center"
                        }}
                           className="mt-4 btn-warning 
                          font-xsssss fw-700 ls-lg text-white"
                          
                          >Onay bekliyor</div>


                                        </div>
                                        
                                    </div></a>
                                </div>
                                ))}


                                      {this.props?.etkinlikler && this.props?.etkinlikler.map((value , index) => (
                                          
                                <div  className="col-lg-6 col-md-6 pe-2 ps-2">
                                    <a key={index} href={"/etkinlikdetay/"+value.url}>
                                    <div className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                                        <div className="card-image w-100">
                                            <img src={"https://api.sivildunyakocaeli.com/"+value.coverImage} 
                                            style={{height:300,objectFit:"cover"}}   alt="event" className="w-100 rounded-3" />
                                        </div>
                                        <div className="card-body d-flex ps-0 pe-0 pb-0" style={{position:"relative"}} >
                                            <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg"><h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0"><span className="ls-3 d-block font-xsss text-grey-500 fw-500">{value.ay}</span>{value.gun}</h4></div>
                                            <h2 className="fw-700 lh-3 font-xss" style={{marginRight:75}} >{value.title}
                                                <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                                                     <i className="ti-location-pin me-1"></i>{value.location} </span>
                                            </h2>
                                            
                                            <div style={{borderRadius:10,height:30,
                                            position:"absolute",right:10,top:20
                                            ,lineHeight:0,display:"inline",padding:10,paddingTop:15,
                                            paddingRight:10,paddingLeft:10,
                           justifyContent:"center",alignItems:"center"
                        }}
                           className="mt-4 btn-success 
                          font-xsssss fw-700 ls-lg text-white"
                          
                          >Onaylandı</div>
                                            
                                            <div>
                                           
                                            </div>

                                            


                                        </div>

                                        
                                        
                                    </div></a>
                                </div>
                                ))}
                                      </div></> }
                                      
                                      
                                      
                                      
                                      </> : 
                                      
                                      
                                      

                                      <EtkinlikEkle onaylandi={() => this.onaylandi()}  />
                                      
                                      
                                      
                                      
                                      }

                                    </div>

        )
    }
}


export default Etkinlikler;