import React, { Component , Fragment } from "react";

import Header from '../components/Header';

import Appfooter from '../components/Appfooter';



import Postview from '../components/Postview';
import axios from "axios";



class HaberDetay extends Component {

    constructor() {
        super();
        this.state = {
            id:0,
            haber:[],
        }
    }


    componentDidMount() {
        let data = this.props.match.params;
        this.setState({id:data.haberid})
        this.haberdetay(data.haberid);

    }

    haberdetay = async (id) => {

        await axios.get("https://api.sivildunyakocaeli.com/mobil/haberler/detayurl/"+id, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
        //         console.log("haber",response.data.data);
                if(response.data.success==true) {
                this.setState({
                    haber: response.data.data,
                })
            }else {
               window.location.href="/";
            }

            }).catch((e) => {
        //         console.log("e",e);
            });

    }


    render() {
        return (
            <Fragment> 
                <Header />
                

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row feed-body">
                                <div className="col-xl-12 col-xxl-12 col-lg-12">
                                
                                {this.state.haber != [] &&
                                <Postview
                                veriyenile={() => { window.location.href="/profil" }}
                                detay={true}
                                                id={this.state.haber?.id}
                                                resimler={this.state.haber?.resimler}
                                                stk={this.state.haber?.stk}
                                                time={this.state.haber?.time}
                                                des={this.state.haber?.description}
                                                url={this.state.haber?.url}
                                            /> }

                               
                                
                                
                                </div>
                       
                       
                            </div>
                        </div>
                    </div>
                </div>
              
              
                <Appfooter /> 
            </Fragment>
        );
    }
}

export default HaberDetay;