import React, { Component, Fragment } from "react";
import Header from '../../components/Header';
import Rightchat from '../../components/Rightchat';
import Appfooter from '../../components/Appfooter';
import Popupchat from '../../components/Popupchat';

import Profiledetail from '../../components/Profiledetail';
import ProfilecardThree from '../../components/ProfilecardThree';
import Createpost from '../../components/Createpost';
import Events from '../../components/Events';
import Postview from '../../components/Postview';
import Load from '../../components/Load';
import Kesfet from "./Kesfet";
import Haberler from "./Haberler";
import Etkinlikler from "./Etkinlikler";
import Talepler from "./Talepler";
import Rezervasyonlar from "./Rezervasyonlar";
import axios from "axios";
import ProfilGuncelle from "./ProfilGuncelle";
import iziToast from 'izitoast';

import { Button } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

class StkProfilGuncelle extends Component {

    constructor() {
        super();
        this.state = {
            id:0,
            aktifMenu:1,
            page: 1,
            haberler: [],
            etkinlikler:[],
            stk:[],
            loading:false,

            token:"",
            stklocal:[],
            user:[],
            rand:parseInt(0 + (Math.random() * (6) ) ),

            banners:["https://api.sivildunyakocaeli.com/uploads/banner/kocaeli1.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli2.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli3.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli4.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli5.jpg",
            "https://api.sivildunyakocaeli.com/uploads/banner/kocaeli6.jpg",],

        }
    }

    onaylandi() {
        
        iziToast.show({
            theme:"light",
            color:"green",
            title: 'Tebrikler...',
            message: 'Profiliniz Güncellendi.'
        });

        this.setState({
            durum:false,
        })

    }

    componentDidMount() {

        if (localStorage.getItem("user")!=null && localStorage.getItem("user")!=""  ) {
            this.setState({
                stklocal: JSON.parse(localStorage.getItem("stk")),
                user: JSON.parse(localStorage.getItem("user")),
                token: JSON.parse(localStorage.getItem("access_token")),
            },() => {

                this.setState({
                    id:this.state.stklocal.id
                })

                this.stkdetay(this.state.stklocal.id);

            })
        } else {
            window.location.href="/";
        }
    
    }

    
    stkdetay = async (id) => {

        this.setState({
            loading:true
        })

        await axios.get("https://api.sivildunyakocaeli.com/mobil/stklar/detay/"+id, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
            //     console.log("stk",response.data.data);
                this.setState({
                    etkinlikler: response.data.data?.etkinlikler,
                    haberler: response.data.data?.haberler,
                    stk: response.data.data,
                    loading:false,
                })

            });

    }

    render() {
        return (
            <Fragment>
                <Header />

                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/izitoast/1.4.0/css/iziToast.css"/>

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-xl-12 mb-3">

                                    <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                                        <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3"><img 
                                        style={{width:"100%",objectFit:"cover",maxHeight:275}}
                                        src={
                                            this.state?.stk.coverImage!= "" ? "https://api.sivildunyakocaeli.com/"+this.state?.stk?.coverImage : this.state.banners[this.state.rand]
                                        } alt="avater" /></div>
                                        <div className="card-body p-0 position-relative">
                                            <figure className="avatar position-absolute w100 z-index-1" style={{ top: '-40px', left: '30px' }}>
                                                <img src={"https://api.sivildunyakocaeli.com/"+this.state?.stk?.profileImage} alt="avater" className="float-right p-1 bg-white rounded-circle w-100" /></figure>
                                            <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15 marginyok" style={{marginRight:100}} >{this.state?.stk.name} 
                                            <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">{this.state?.stk.baskanEmail}</span></h4>
                                           
                                          
                                            <div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2">
                                              
                                              

                                                <Button component={Link} to="/profil" className=" z-index-1 
                                                rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12
                                                ,fontWeight:600,height:40,lineHeight:0,display:"flex",justifyContent:"center",
                                                alignItems:"center",padding:15
                                                }} >
                                                    Profile Dön</Button>

                                            </div>

                                        </div>


                                    </div>

                                </div>
{this.state.loading==false ?
<>


                                    <ProfilGuncelle
                                    stkid={this.state.id}
                                    stk={this.state.stk}
                                    token={this.state.token}
                                    onaylandi={() =>  this.onaylandi()}
                                     />
                                
                                
                                </> : <Spinner animation="border" variant="primary" />
                                 }


                            </div>
                        </div>
                    </div>
                </div>


                <Appfooter />

            </Fragment>
        );
    }
}

export default StkProfilGuncelle;