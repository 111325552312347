import React, { Component, Fragment } from "react";
import Header from '../components/Header';

import Appfooter from '../components/Appfooter';

import axios from "axios";

import parse from 'html-react-parser';
import Events from "../components/Events";
import { Spinner } from "react-bootstrap";

class Sozlesmeler extends Component {

    constructor() {
        super();
        this.state = {
            id:0,
            aktifMenu:1,
            loading:false,
            metin:[],
        }
    }

    componentDidMount() {
        
        let data = this.props.match.params;

        this.setState({
            id:data.id
        },() => {

          this.sozlesmegetir(this.state.id);

        })
        
    
    
    }

    
    sozlesmegetir = async (id) => {

        this.setState({
            loading:true
        })

        await axios.get("https://api.sivildunyakocaeli.com/mobil/sozlesmeler/getir/"+id, {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {

                this.setState({
                    metin: response.data.data,
                    loading:false,
                })

            });

    }

  


   

    render() {
        return (
            <Fragment>
                <Header match={this.props?.match} />


                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
          
          
                                
                <div className="col-xl-12 col-xxl-12 col-lg-12">
                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-block p-4">
                    {this.state.loading==false ?
                    <>
            
                    <h2 className="fw-700 font-lg " style={{textAlign:"center"}}  >
                                        {this.state.metin?.title}
                                        </h2>
                                       
                                       
                                        <p className="font-xsss fw-500 text-grey-500 lh-30  mt-3">
                                        {parse(''+this.state.metin?.description)}
                                        </p>
</> : 

<div style={{width:"100%",textAlign:"center"}} >
<Spinner animation="border" variant="primary" />
    </div>

}
                </div>
                </div>

              
            
                </div>
                
                              


                            </div>
                        </div>
                    </div>
                </div>


                <Appfooter />

            </Fragment>
        );
    }
}

export default Sozlesmeler;