import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import parse from 'html-react-parser';

const TanitimPopup = ({ show, onClose }) => {

    const [popup, setPopup] = useState([]); // etkinlik detayı için state


    useEffect(() => {
        popupCek();
    }, []);

    
    const getYouTubeID = (url) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        const match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    };


    const popupCek = async () => {
        try {
            const response = await axios.get(`https://api.sivildunyakocaeli.com/popup/popupgetir`, {
                headers: {
                    "Accept": "*/*",
                    "x-requested-with": "XMLHttpRequest",
                },
            });

            if (response.data.success) {
                setPopup(response.data.data);
            } else {
                setPopup([]);
            }
        } catch (error) {
            console.error("Detay çekme hatası:", error);
            // Hata yönetimi için kodlarınızı buraya ekleyebilirsiniz.
        }
    };

const videoID = popup?.videoUrl ? getYouTubeID(popup.videoUrl) : null;
const iframeSrc = `https://www.youtube.com/embed/${videoID}?controls=0&rel=0&autoplay=0&modestbranding=1`;

    return (
        <>
        {popup?.title &&
        <Modal show={show} onHide={onClose} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{popup?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='popupContent'  >

            {popup?.icerik==2 && iframeSrc && (
                            <div style={{ marginBottom: '20px' }}>
                              <iframe className='iframeStyle'
    style={{ width: '100%', height: '315px', display:"inline" }} // Örnek olarak yüzde 100 genişlik
    src={iframeSrc} 
    frameBorder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowFullScreen>
</iframe>
                            </div>
                        )}


{popup?.icerik==1 && (
                            <div style={{ marginBottom: '20px' }}>

<img src={"https://api.sivildunyakocaeli.com/" + popup?.imgUrl}
style={{ width: '100%',height:300,objectFit:"contain" }} />


                                </div>

)}

                {/* Buraya tanıtım içeriğinizi ekleyin */}
                {parse(popup?.description ? popup?.description : "")}
            </Modal.Body>
           
        </Modal>
        }
        </>
    );
};

export default TanitimPopup;
