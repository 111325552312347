import React, { Component, Fragment } from "react";
import Header from '../components/Header';

import Appfooter from '../components/Appfooter';

import axios from "axios";

import parse from 'html-react-parser';
import Events from "../components/Events";
import { Link } from "react-router-dom";
import { FormSelect, Pagination, Spinner, Table } from "react-bootstrap";

class StkArama extends Component {

    constructor() {
        super();
        this.state = {
            id: 0,
            aktifMenu: 1,
            loading: false,
            page: 1,
            rowsPerPage: 6,
            arama: "",

            il: 0,
            stktur: 0,

            stklar: [],
        }
    }

    componentDidMount() {

        this.stklistesi();
    }





    stklistesi = async () => {

        this.setState({
            loading: true,
        })

        await axios.get("https://api.sivildunyakocaeli.com/mobil/stklar/liste", {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {

          //       console.log("stk", response.data.data);

                this.setState({
                    stklar: response.data.data,
                    loading: false
                })

            });

    }

    metinduzenle(metin) {
        return metin.replace(/Ğ/gim, "g")
            .replace(/Ü/gim, "u")
            .replace(/Ş/gim, "s")
            .replace(/I/gim, "i")
            .replace(/İ/gim, "i")
            .replace(/Ö/gim, "o")
            .replace(/Ç/gim, "c")
            .replace(/ğ/gim, "g")
            .replace(/ü/gim, "u")
            .replace(/ş/gim, "s")
            .replace(/ı/gim, "i")
            .replace(/ö/gim, "o")
            .replace(/ç/gim, "c").toLowerCase();
    }


    render() {



        var maxpage = parseInt((
            this.state.stklar.filter((item) =>
                (item.name.replace(/[ıİiI]/g, '[ıİiI]'))?.toLowerCase().includes(
                    (this.state.arama.replace(/[ıİiI]/g, '[ıİiI]'))?.toLowerCase()) &&

                (this.state.il == 0 ? true : item.ilce == this.state.il ? true : false)


            )
                .length / this.state.rowsPerPage) - 1);

        return (
            <Fragment>
                <Header match={this.props?.match}/>
                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0" style={{ maxWidth: 1200 }} >
                            <div className="row">
                                <div className="col-xl-12 col-xxl-12 col-lg-12">

                                    <div className="card shadow-xss w-100 d-block 
                                    d-flex border-0 p-4 mb-3" style={{borderRadius:15}} >
                                        <div className="row">
                                            <div className="col-md-3 col-sm-12" style={{ margin: 10 }} >
                                                <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center" style={{ paddingTop: 10 }}  >
                                                    STK Arama Robotu
                                                </h2>
                                            </div>
                                            <div className="col-md-4 col-sm-12" style={{ margin: 10 }} >

                                                <div className="search-form-2">
                                                    <i className="ti-search font-xss"></i>
                                                    <input value={this.state.arama}
                                                        onChange={(e) => this.setState({ arama: e.target.value, page: 1 })}
                                                        type="text" className="form-control text-grey-500 
                                                    mb-0 bg-greylight theme-dark-bg border-0"

                                                        placeholder="STK Ara" />
                                                </div>

                                            </div>

                                            <div className="col-md-2 col-sm-12" style={{ margin: 10 }}>

                                                <FormSelect value={this.state.il} onChange={(e) => this.setState({ il: e.target.value, page: 1 })} aria-label="İlçelere Göre Filtrele" style={{ backgroundColor: "#f5f5f5", borderWidth: 0, padding: 10 }}   >
                                                    <option value={0}  >Tüm İlçeler</option>
                                                    <option value={"BAŞİSKELE"}>BAŞİSKELE</option>
                                                    <option value={"ÇAYIROVA"}>ÇAYIROVA</option>
                                                    <option value={"DARICA"}>DARICA</option>
                                                    <option value={"DERİNCE"}>DERİNCE</option>
                                                    <option value={"GEBZE"}>GEBZE</option>
                                                    <option value={"GÖLCÜK"}>GÖLCÜK</option>
                                                    <option value={"İZMİT"}>İZMİT</option>
                                                    <option value={"KANDIRA"}>KANDIRA</option>
                                                    <option value={"KARAMÜRSEL"}>KARAMÜRSEL</option>
                                                    <option value={"KARTEPE"}>KARTEPE</option>
                                                    <option value={"KÖRFEZ"}>KÖRFEZ</option>
                                                </FormSelect>
                                            </div>

                                            <div className="col-md-2 col-sm-12" style={{ margin: 10 }}>

                                                <FormSelect value={this.state.stktur} onChange={(e) => this.setState({ stktur: e.target.value, page: 1 })} aria-label="STKlara göre filtrele" style={{ backgroundColor: "#f5f5f5", borderWidth: 0, padding: 10 }}   >
                                                    <option value={0}  >STK Türleri</option>
                                                    <option value={1}>Dernek</option>
                                                    <option value={2}>Vakıf</option>
                                                    <option value={3}>Meslek Odası</option>
                                                    <option value={4}>Sendika</option>
                                                    <option value={5}>Platform</option>
                                                    <option value={6}>Öğrenci Kulübü</option>
                                                    <option value={7}>İl Temsilciliği</option>
                                                    <option value={8}>Kamu Kurumu</option>
                                                </FormSelect>
                                            </div>


                                        </div>






                                    </div>

                                    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                                        <div className="card-body d-block p-4">

                {this.state.loading==false ?
                                            <div className="chat-wrapper p-3 w-100 position-relative scroll-bar bg-white theme-dark-bg">


                                            {this.state.stklar.filter((item) =>
                                                            this.metinduzenle(item.name).includes(
                                                                this.metinduzenle(this.state.arama)) &&

                                                            (this.state.il == 0 ? true : item.ilce == this.state.il ? true : false)

                                                            &&

                                                            (this.state.stktur == 0 ? true : item.stkType == this.state.stktur ? true : false)


                                                        ).length<=0 ?
                                            <div style={{width:"100%",textAlign:"center",marginTop:20}} >
                <img src='/assets/images/stkYok.svg' style={{height:100}} />

                <p style={{color:"#aaa",marginTop:10,fontWeight:500,fontSize:18}} >Aradığınız Filtrelerde STK Bulunamadı</p>

                </div>
    :


<>
                                                <Table striped bordered hover>
                                                    <thead>
                                                        <tr style={{ color: "#000" }} >
                                                            <th>Resim</th>
                                                            <th>Kurum Adı</th>
                                                            <th>İlçesi</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.stklar.filter((item) =>
                                                            this.metinduzenle(item.name).includes(
                                                                this.metinduzenle(this.state.arama)) &&

                                                            (this.state.il == 0 ? true : item.ilce == this.state.il ? true : false)

                                                            &&

                                                            (this.state.stktur == 0 ? true : item.stkType == this.state.stktur ? true : false)


                                                        )
                                                            .slice((this.state.page - 1) * this.state.rowsPerPage, (this.state.page - 1)
                                                                * this.state.rowsPerPage + this.state.rowsPerPage)
                                                            .map((item) => {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ textAlign: "center" }} >
                                                                            <a href={"stk/" + item.url}>
                                                                                <img
                                                                                    style={{ width: 75, height: 75, borderRadius: 400 }}
                                                                                    src={`https://api.sivildunyakocaeli.com/${item.profileImage}`}
                                                                                />
                                                                            </a>
                                                                        </td>
                                                                        <td style={{ color: "#000" }} >
                                                                            <a href={"stk/" + item.url}>
                                                                                <div style={{ color: "#000" }} >{item.name} </div>

                                                                                <span style={{ color: "#777", fontSize: 12 }} >({item.faaliyetAlani})</span>
                                                                            </a>
                                                                        </td>

                                                                        <td style={{ color: "#000" }} >{item.ilce}</td>
                                                                    </tr>
                                                                )
                                                            })}



                                                    </tbody>
                                                </Table>
                                                
                                                <Pagination style={{ justifyContent: "center", display: "flex" }} >
                                                {(this.state.page > 1) && <>
                                                    <Pagination.First onClick={() => this.setState({ page: 1 })} />
                                                    <Pagination.Prev onClick={() => this.setState({ page: this.state.page <= 1 ? 1 : this.state.page - 1 })} />
                                                </>
                                                }
                                                {this.state.page > 2 &&
                                                    <Pagination.Ellipsis />
                                                }

                                                {this.state.page > 1 &&
                                                    <Pagination.Item onClick={() => this.setState({ page: this.state.page - 1 })}  >{this.state.page - 1}</Pagination.Item>
                                                }
                                                <Pagination.Item active>{this.state.page}</Pagination.Item>

                                                {this.state.page < maxpage &&
                                                    <Pagination.Item onClick={() => this.setState({ page: this.state.page + 1 })}  >{this.state.page + 1}</Pagination.Item>
                                                }

                                                {this.state.page < maxpage - 1 &&
                                                    <Pagination.Ellipsis />
                                                }


                                                {(this.state.page < maxpage) && <>
                                                    <Pagination.Next onClick={() => this.setState({ page: this.state.page >= maxpage ? maxpage : this.state.page + 1 })} />
                                                    <Pagination.Last onClick={() => this.setState({ page: maxpage })} />
                                                </>
                                                }
                                            </Pagination>
                                            
                                            </>
                                            
                                            }



                                               




                                            </div>

: <div style={{width:"100%",textAlign:"center"}} >
<Spinner animation="border" variant="primary" />
    </div> }



                                        </div>
                                    </div>



                                </div>




                            </div>
                        </div>
                    </div>
                </div>


                <Appfooter />

            </Fragment>
        );
    }
}

export default StkArama;