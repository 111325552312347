import axios from "axios";
import React, { Component, Fragment } from "react";
import { Spinner } from "react-bootstrap";
import Load from "../components/Load";



class StkDegistir extends Component {

    constructor() {
        super();
        this.state = {
            kademe:1,
            telefon:"",
            loading:false,
            telefonHata:false,
            kod:"",
            kodhata:false,
            stklar:[],
            stk:[],
            user:[],
            token:"",
        }
    }

    componentDidMount() {

        if(localStorage.getItem("user") && localStorage.getItem("stk")  ) {

            this.setState({
                stklar:JSON.parse(localStorage.getItem("stklar")),
                stk:JSON.parse(localStorage.getItem("stk")),
            })
           
        }else {
            this.props.history.push("/")
        }


     //   this.kodkontrol();
    }

   
    giris = async () => {

        if(this.state.stk!=[]) {

        localStorage.setItem("user", JSON.stringify(this.state.user));
      //  localStorage.setItem("access_token", JSON.stringify(this.state.token));
        localStorage.setItem("stklar", JSON.stringify(this.state.stklar));
        localStorage.setItem("stk", JSON.stringify(this.state.stk));
        window.location.href="/";
        }

    }

   

    render() {
        return (
            <Fragment>
                <div className="main-wrap">
                   
                
                

                   
                    <div className="row">
                        <div className="col-xl-6 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                            style={{ backgroundImage: `url("https://api.sivildunyakocaeli.com/uploads/girisBanner.jpg")` }}></div>
                        <div className="col-xl-6 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                            <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                
                          
                            <div style={{ marginTop: 10, marginBottom: 45, marginLeft: 20 }} >
                                    <a href="/">
                                        <img src="assets/images/stklogo.svg" style={{ height: 50 }} />
                                    </a>
                                </div>
                                
                               
                                <div className="card-body rounded-0 text-left" >
                                    
                                    
                                <h2 className="fw-700  display2-md-size mb-3" style={{ fontSize: 22 }} >
                                            Giriş yapmak istediğiniz STK'yı seçiniz</h2>
                                        <div style={{ marginBottom: 20, lineHeight: 1.5, marginTop: 20 }} >
                                            Birden fazla STK'da başkan olduğunuz gözükmektedir.
                                        </div>

                                 
                                        <div className="form-group icon-input mb-3" style={{height:300,overflow:"auto"}} >
                                           
                   {this.state.stklar.map((item) => {
                       return (

                        <div onClick={() => this.setState({stk:item})}   className="card-body d-flex  overflow-hidden"
                        style={{borderWidth:1,borderColor:"#ddd",borderStyle:"solid" ,marginBottom:20,cursor:"pointer"
                        ,borderRadius:10,backgroundColor:this.state.stk?.id==item.id ? "#f4f4f4" : "#fff" }}
                         >
                            <div style={{}} className={`me-2 rounded-xxl `}>
                              <img style={{height:55,width:55,borderRadius:400}} src={"https://api.sivildunyakocaeli.com/" + item.profileImage} />
                              </div>
                            <h4 className="fw-700 text-grey-900 font-xssss mt-2">
                               {item.name} 
                                 <span className="d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500">
                            {item.faaliyetAlani}
          </span> </h4>
                        </div>

                       )
                   })}                 
               
              

                                        </div>


                                        {(this.state.loading == false ) ?
                                            <div className="col-sm-12 p-0 text-left">
                                                <div className="form-group mb-1"><button
                                                    style={{
                                                        backgroundColor: "#005F9B", height: 40, padding: 0, lineHeight: 0, marginTop: 20,
                                                    }}
                                                    onClick={() => { this.giris() }}
                                                    className="form-control text-center style2-input text-white 
                                         fw-600 border-0  ">Giriş Yap</button></div>
                                              
                                            </div> :
                                            <div style={{ width: "100%", textAlign: "center" }} >
                                                <Spinner animation="border" variant="primary" />
                                            </div>
                                        }


                                 
                                  
                                  
                                    

                                </div> 



                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }
}

export default StkDegistir;