import axios from "axios";
import React, { Component, Fragment } from "react";

import {  TextField } from '@material-ui/core';
import { Spinner } from "react-bootstrap";

import Autocomplete from '@material-ui/lab/Autocomplete';

import iziToast from 'izitoast';


import { Button } from '@material-ui/core';
import { Link } from "react-router-dom";

class Talepler extends Component {


    state = {
        id: 0,
        page: 1,
        talepler: [],
        loading: false,
        token: "",
        stk:[],
        talepdurum:false,

        talep:"",
        kontrol:false,
        talepload:false,
        tipler:[],
        secilitalep:{id:"1",name:"İstek Talebi"},

    }


    componentDidMount() {

        if(this.props?.direktSayfa==true) {
            this.setState({
                talepdurum:true
            })
        }

        this.setState({
            id: this.props?.stkid,
            token: this.props?.token,
            stk:this.props?.stk,
        }, () => {

            this.talepturleri();
            this.talepler(this.props?.stkid);
        })

    }

    talepgonder = async () => {

        if(this.state.talep!="") {

            const formData = new FormData();
            formData.append("stkId", this.state?.stk?.id );
            formData.append("talep", this.state?.talep );
            formData.append("typeId", this.state?.secilitalep?.id );
              await axios.post("https://api.sivildunyakocaeli.com/mobil/talepler/talep_gonder",formData, {
                  headers: {
                      "Accept": "*/*",
                      "Authorization": "Bearer " + this.state.token,
                      "x-requested-with": "XMLHttpRequest",
      
                  },
              })
                  .then((response) => {
               //        console.log("gonder",response.data.data);

                      this.setState({
                          talep:"",
                      })
                     
                      iziToast.show({
                        theme:"light",
                        color:"green",
                        title: 'Tebrikler...',
                        message: 'Talep Gönderildi.'
                    });

                      this.talepler(this.state.id);
      
                  });

        }else {
            this.setState({
                kontrol:true,
            })
        }

    }

    talepler = async (id) => {

        this.setState({
            loading:true,
        })
        

        const formData = new FormData();
      formData.append("stkId", id );
        await axios.post("https://api.sivildunyakocaeli.com/mobil/talepler/liste/",formData, {
            headers: {
                "Accept": "*/*",
                "Authorization": "Bearer " + this.state.token,
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
              //   console.log("talepler",response.data.data);
                this.setState({
                    talepler: response.data.data,
                    loading:false,
                })

            });

    }


    talepturleri = async () => {

    
        await axios.get("https://api.sivildunyakocaeli.com/mobil/talepler/talep_tipleri", {
            headers: {
                "Accept": "*/*",
                "x-requested-with": "XMLHttpRequest",

            },
        })
            .then((response) => {
                this.setState({
                    tipler: response.data.data,
                })

            });

    }

    render() {
        return (
            <div className="col-xl-12 col-xxl-12 col-lg-12">

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/izitoast/1.4.0/css/iziToast.css"/>

                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                    <div className="card-body d-flex align-items-center p-0">
                        <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900">Talep Yönetimi</h2>
                        <div className="search-form-2 ms-auto">


                        </div>

                        {this.state.talepdurum==false ?
                        <Button onClick={() => this.setState({talepdurum:true})}  className=" z-index-1 
                        rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12
                        ,fontWeight:600,height:40,lineHeight:0,display:"flex",justifyContent:"center",
                        alignItems:"center",padding:15
                        }} >
                            Yeni Talep</Button>

                       
                            : 

                            <Button onClick={() => this.setState({talepdurum:false})}  className=" z-index-1 
                            rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12
                            ,fontWeight:600,height:40,lineHeight:0,display:"flex",justifyContent:"center",
                            alignItems:"center",padding:15
                            }} >
                                Kapat</Button>
                            
                             }
                    </div>
                </div>

                {this.state.talepdurum==true ?
                <div className="card d-block w-100 border-0 mb-3 shadow-xss bg-white rounded-3 p-4"
                    style={{ paddingLeft: 120 }} >

                    <h4 className="font-xss fw-700 text-grey-900  pe-4" style={{marginBottom:25}} >Talebinizi İletiniz</h4>
                   
<div style={{marginBottom:20}} >
                    <Autocomplete
                  id="combo-box-demo"
                  options={this.state.tipler}

                  value={{id:this.state.secilitalep.id,name:this.state.secilitalep.name}}
                  defaultValue={{ id: "1", name: "İstek Talebi" }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} 
                  
                  label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Talep Türü</div>}
                  variant="outlined" />}
                  onChange={(event, value) => {
                    this.setState({ secilitalep: value!=null ? value : this.state.secilitalep  })
                  }}
                />
</div>
                  
                    <TextField

multiline
rows={5}
type="text"
error={(this.state.kontrol == true && this.state.talep == "") ? true : false}
helperText={(this.state.kontrol == true && this.state.talep == "") ? "Bu alan boş geçilemez" : ""}

label={<div style={{backgroundColor:"#fff",paddingLeft:10,paddingRight:10,marginTop:-5}} >Talebiniz</div>}
value={this.state.talep}
onChange={(e) => this.setState({ talep: e.target.value })}
style={{ width: "100%" }}
InputProps={{}}
variant="outlined"

/>

<div style={{marginTop:10,width:"100%",textAlign:"right"}} >
    {this.state.talepload==false ?

<Button onClick={() => this.talepgonder()}  className=" z-index-1 
rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12
,fontWeight:600,height:40,lineHeight:0,display:"inline",justifyContent:"center",
alignItems:"center",padding:15
}} >
    Gönder</Button>
 : <Spinner style={{marginRight:10}}  animation="border" variant="primary" />}
</div>
                 
                 
                    <a href="#" className="position-absolute bottom-15 mb-3 right-15"></a>
                </div> : null }


                {this.state.talepler.length <=0 ?

                <div style={{ width: "100%", textAlign: "center", marginTop: 20 }} >
                            <img src='/assets/images/talebinizYok.svg' style={{ height: 90 }} />

                            <p style={{ color: "#aaa", marginTop: 10, fontWeight: 500, fontSize: 17 }} >
                                Talep Bulunamadı</p>

                        </div> : null }


{this.state.talepler.map((item) => {
    return (
        
                <div className="card d-block w-100 border-0 mb-3 shadow-xss bg-white rounded-3 p-4"
                    style={{ paddingLeft: 120, }} >

                    <h4 className="font-xss fw-700 text-grey-900 mb-3 pe-4" style={{display:"flex"}} >{item.name}
                        <div className="font-xssss fw-500 text-grey-500 ms-2" style={{marginTop:3}}  >({item.time})</div> </h4>
                    <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span
                        className="text-grey-900 font-xssss">Dönüş Yapılacak Kişi :
                    </span> {this.state.stk?.baskanAdi}</h5>
                    <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span
                        className="text-grey-900 font-xssss">Talebiniz : </span><br /><br />
                        <span style={{ fontWeight: 400 }}>
                            {item.description}
                        </span>


                    </h5><br />

                    {item.status==1 ? <>
                        <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span
                        className="text-grey-900 font-xssss">Cevap : </span><br /><br />
                        <span style={{ fontWeight: 400 }}>
                            {item.answer}
                        </span>


                    </h5>   <br />
                    </> : null }

                  

                    {item.status==1 ?
                    <h6
                        className="d-inline-block p-2 text-success alert-success fw-600 font-xssss rounded-3 me-2">
                        CEVAPLANDI</h6>  :
                    <h6
                        className="d-inline-block p-2 text-warning alert-warning fw-600 font-xssss rounded-3 me-2">
                        İLETİLDİ</h6> }
                 
                 
                    <a href="#" className="position-absolute bottom-15 mb-3 right-15"></a>
                </div>


    )
})}



            </div>

        )
    }
}


export default Talepler;