import axios from 'axios';
import React,{Component} from 'react';
import { Spinner } from 'react-bootstrap';
import Load from './Load';

import { Button } from '@material-ui/core';
import { Link } from "react-router-dom";

class Createpost extends Component {
    state = {
        isOpen: false,
        stk:[],

        yazi:"",
        resimler:[],
        loading:false,
        token:"",

        resimyok:false,
        
    };

    componentDidMount() {
        if (localStorage.getItem("stk")!=null && localStorage.getItem("stk")!=""  ) {
            this.setState({
                stk: JSON.parse(localStorage.getItem("stk")),
                token: JSON.parse(localStorage.getItem("access_token")),
            })
        }
    }


    resimyok = async () => {

        this.setState({
            resimyok:true
        });

    }

    paylas = async () => {

        const formData = new FormData();
      formData.append("haber", this.state.yazi );
      formData.append("stkId", this.state.stk?.id );
      formData.append("filesBase64", JSON.stringify(this.state.resimler) );

        await axios.post("https://api.sivildunyakocaeli.com/mobil/haberler/yenihaber/",formData, {
            headers: {
                "Accept": "*/*",
                "Authorization": "Bearer " + this.state.token,
                "x-requested-with": "XMLHttpRequest",
            },
        })
            .then((response) => {

         //        console.log("token",this.state.token);

               this.setState({
                   resimler:[],
                   resimyok:false,
                   yazi:"",
               })
            
               this.props.yenile();
//               this.props.history.push("/");


             //  window.location.href="/";

            });

    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
       //      console.log('Error: ', error);
        };
    }
    

    getBase64ReducedQuality(file, callback) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = document.createElement("img");
            img.onload = () => {
                const canvas = document.createElement("canvas");
    
                // Yeniden boyutlandırma işlemi için istenilen maksimum genişlik ve yükseklik
                const maxWidth = 1600; // Örnek genişlik
                const maxHeight = 1200; // Örnek yükseklik
                let width = img.width;
                let height = img.height;
    
                // Resmin oranını koruyarak yeniden boyutlandırma
                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }
    
                canvas.width = width;
                canvas.height = height;
    
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
    
                // Kaliteyi düşürerek base64 olarak almak
                const quality = 0.7; // Kalite (0 ile 1 arasında)
                const newDataUrl = canvas.toDataURL("image/jpeg", quality);
    
                callback(newDataUrl); // İşlenmiş resmi callback ile döndür
            };
            img.src = e.target.result;
        };
        reader.readAsDataURL(file);
    }

    fileSelectedHandler = (e) => {

        var gallery = [];

        var files = e.target.files;

        for (var i = 0; i < files.length; i++) 
        {
   
            this.getBase64ReducedQuality(files[i], (result) => {
                gallery = [...gallery, result];
                this.setState({
                    resimler: gallery
                });
            });
      
      
    }

      
      }


      upload() {
        document.getElementById("resimekle").click()
      }


    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

    render() {
        const menuClass = `${this.state.isOpen ? " show" : ""}`;

        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                <div className="card-body p-0">
                    <div className="font-xssss fw-600 text-grey-500 card-body p-0 d-flex align-items-center">
                        <i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>Gönderi Oluştur</div>
                </div>
                <div className="card-body p-0 mt-3 position-relative">
                    <figure className="avatar position-absolute ms-2 mt-1 top-5">
                        <img src={"https://api.sivildunyakocaeli.com/" + this.state.stk?.profileImage} alt="icon"
                     className="shadow-sm rounded-circle w30" /></figure>
                    <textarea name="message" value={this.state.yazi} onChange={(e) => this.setState({yazi:e.target.value}) }
                    className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" cols="30" rows="10" placeholder="Haberinizi yazmaya başlayın..."></textarea>
                </div>
                <div className="card-body d-flex p-0 mt-0" style={{position:"relative",height:this.state.resimler.length>0 ? 85 : 35}} >
                   
                   {this.state.resimler.length==0 ?
                    <div style={{cursor:"pointer"}} onClick={() => { this.upload() }} 
                     className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4">
                         <i className="font-md text-success feather-image me-2"></i>
                         <div>
                         <span className="d-none-xs">Fotoğraf</span>
                         {this.state.resimyok && 
                         <div style={{color:"red",marginTop:-8,fontSize:11}} >Lütfen Fotoğraf Seçiniz</div> }
                         </div>
                         </div> : 
                         
                         <div style={{cursor:"pointer"}} onClick={() => { this.setState({resimler:[]}) }} 
                     className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4">
                         <i className="font-md text-danger feather-x-circle me-2"></i>
                         </div>
                         
                         }
                    
                    <input hidden id="resimekle" accept="image/*" type="file" multiple onChange={this.fileSelectedHandler.bind(this)} />
                    
                         
                    {this.state.loading==false ?
                    <>
                    {this.state.resimler=="" ?
                    <Button   onClick={() => this.resimyok()}  className=" z-index-1 
                    rounded-3 text-white" style={{fontSize:12
                    ,fontWeight:600,height:30,lineHeight:0,display:"inline",justifyContent:"center",
                   
                    position:"absolute",backgroundColor:"#ccc",color:"#fff",
                    right:5,top:this.state.resimler.length>0 ? 30 : 5,

                    alignItems:"center",padding:15
                    }} >
                      Paylaş
                        </Button>

                    :
                    <Button  onClick={() => this.paylas()}  className=" z-index-1 
                    rounded-3 text-white" style={{backgroundColor:"#1478FB",fontSize:12
                    ,fontWeight:600,height:30,lineHeight:0,display:"inline",justifyContent:"center",
                   
                    position:"absolute",color:"#fff",
                    right:5,top:this.state.resimler.length>0 ? 30 : 5,

                    alignItems:"center",padding:15
                    }} >
                      Paylaş
                        </Button>
                         }
                    </>
                     : 
                    <div style={{position:"absolute",right:5,top:this.state.resimler.length>0 ? 30 : 5}}>
                    <Spinner animation="border" variant="primary" />
                    </div>
                     }

                    {this.state.resimler.map((item) => {
                        return (
                            <img src={item} style={{height:70,margin:10,borderRadius:10}} />
                        )
                    })}

                </div>
            </div>
        );
    }
}

export default Createpost;