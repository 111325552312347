import React, { Component } from 'react';
import ReactDOM from 'react-dom';


import './main.scss';


import Demo from './demo/Demo';

import Anasayfa from './pages/Anasayfa';
import HaberDetay from './pages/HaberDetay';

import StkProfil from './pages/StkDetay/StkProfil';
import Profil from './pages/Profil/StkProfil';

import Notfound from './pages/Notfound';






import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import EtkinlikDetay from './pages/EtkinlikDetay';
import HikayeDetay from './pages/HikayeDetay';
import Kurumsal from './pages/Kurumsal';
import Resmiislemler from './pages/Resmiislemler';
import HibeDuyurular from './pages/HibeDuyurular';
import Sozlesmeler from './pages/Sozlesmeler';
import StkArama from './pages/StkArama';
import StkTakvim from './pages/StkTakvim';
import GirisYap from './pages/GirisYap';
import StkDegistir from './pages/StkDegistir';
import StkProfilGuncelle from './pages/Profil/StkProfilGuncelle';
import Basvuru from './pages/Basvuru';
import Onay from './pages/Onay';

class Root extends Component{
  render(){
      return(
          <BrowserRouter basename={'/'}>
              <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Anasayfa}/>
                    <Route  path={`/haberdetay/:haberid`} component={HaberDetay}/>
                    
                    <Route  path={`/hikayedetay/:hikayeid`} component={HikayeDetay}/>
                    <Route  path={`/etkinlikdetay/:etkinlikid`} component={EtkinlikDetay}/>
                    <Route  path={`/stk/:stkid`} component={StkProfil}/>
                    <Route  path={`/kurumsal`} component={Kurumsal}/>
                    <Route  path={`/stk-arama`} component={StkArama}/>
                    <Route  path={`/stk-takvim`} component={StkTakvim}/>
                    <Route  path={`/resmi-islemler`} component={Resmiislemler}/>
                    <Route  path={`/hibe-duyurular`} component={HibeDuyurular}/>

                    <Route  path={`/girisyap/:id`} component={GirisYap}/>
                    <Route  path={`/girisyap`} component={GirisYap}/>
                    <Route  path={`/basvuru`} component={Basvuru}/>
                    <Route  path={`/stkdegistir`} component={StkDegistir}/>
                    
                    <Route  path={`/onay`} component={Onay}/>

                    <Route  path={`/sozlesmeler/:id`} component={Sozlesmeler}/>
                    
                    <Route exact path={`/profil`} component={Profil}/>


                    <Route exact path={`/profil/:islemId`} component={Profil}/>


                    <Route exact path={`/profil-duzenle`} component={StkProfilGuncelle}/>
                  
                    
                    <Route exact path={`${process.env.PUBLIC_URL}/demo`} component={Demo}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/notfound`} component={Notfound}/>
                    

                    
              </Switch>
          </BrowserRouter>
      )
  }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();